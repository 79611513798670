import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
// import mobile from "../../Assets/HomeRewamp/HeaderPhone.png";
import mobile from "../../Assets/HomeRewamp/HeaderPhone_1.png";
import benefits1 from "../../Assets/HomeRewamp/benefits1.png";
import improve from "../../Assets/HomeRewamp/imporveskill.png";
import IITBombay from "../../Assets/institute/IITBombay.png";
import supp1 from "../../Assets/HomeRewamp/sir.jpeg";
import supp2 from "../../Assets/HomeRewamp/supportBy2.png";
import features from "../../Assets/HomeRewamp/Features.png";
import userF from "../../Assets/HomeRewamp/user1.png";
import insterst_form_img from "../../Assets/insterst_form.png";
import diff from "../../Assets/HomeRewamp/different.png";
import calender from "../../Assets/HomeRewamp/calender.svg";
import counselling from "../../Assets/HomeRewamp/counselling.png";
import humanpoint from "../../Assets/HomeRewamp/humanpoint.png";
import buildfor from "../../Assets/HomeRewamp/buildfor.png";
import testimonial1 from "../../Assets/HomeRewamp/testimonial1.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import bookcarrer from "../../Assets/HomeRewamp/bookcarrer.png";
import Rating from "@material-ui/lab/Rating";
import Rectangle from "../../Assets/HomeRewamp/Rectangle.png";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { api_call, api_call_token } from "../../Utils/Network";
import ExploreDesk from "../../Components/Explore_desk/ExploreDesk";
import Popovr from "../../Components/Popover/Popovr";
import Brains from "../../Assets/ViewVideo/brainpuzzle.mp4";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";
import Modal from "@material-ui/core/Modal";
import AlertMessageComponent from "../../Components/Alert/AlertMessage.component";

function getModalStyle() {
  const top = 45;
  const left = 45;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function HomeRewamp() {
  const history = useHistory();
  const {
    setDisplayType,
    setOpenModel,
    setFilter,
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);
  const [testType, setTestType] = useState(null);
  const [value, setValue] = React.useState(3);
  const [benefits, setBenefits] = useState({});
  const [feature, setFeature] = useState([]);
  const [testimonials, setTestimonials] = useState({});
  const [testimonialData, setTestimonialData] = useState([]);
  const [joins, setJoin] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);

  const [interestUserData, setInterestUserData] = useState({
    name: "",
    mobile_number: null,
    city: "",
  });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDatas();
    getJoinDetails();
  }, []);

  const getDatas = () => {
    api_call.get(`webhome/homescreen/`).then((res) => {
      setBenefits(res.data.data[0].data[0]);
      setFeature(res.data.data[1].data);
      setTestimonials(res.data.data[2].data[0]);
      setTestimonialData(res.data.data[2].data[0].testimonial_elements);
    });
  };

  const basic_detail = (vals) => {
    if (vals === 1) {
      if (user.user_type === 1) {
        history.push("/ask-to-counselor");
      } else {
        setDisplayType(0);
        setOpenModel(true);
      }
    } else if (vals === 4) {
      setOpen(true);
    } else if (vals === 3) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.lcarro",
        "_blank"
      );
    } else if (vals === 2) {
      if (user.user_type === 1) {
        // history.push('/taketest')
        alert("already logged in");
      } else {
        setDisplayType(0);
        setOpenModel(true);
      }
    }
  };

  const getJoinDetails = () => {
    api_call
      .get(`webhome/basic_details/`)
      .then((res) => {
        setJoin(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const handleChangeData = (event) => {
    let temp = {};
    const { name, value } = event.target;
    console.log(name, value, "ddds");

    let returnValue = true;
    let nameTest = /^[a-zA-Z ]{2,30}$/;

    temp.name = nameTest.test(interestUserData.name)
      ? ""
      : "Please enter valid name.";
    temp.city = nameTest.test(interestUserData.city)
      ? ""
      : "Please enter valid name.";

    temp.mobile_number =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
        interestUserData?.mobile_number
      )
        ? interestUserData?.mobile_number.length > 10 ||
          interestUserData?.mobile_number.length < 10
          ? "Please Enter valid phone number"
          : ""
        : "Please Enter valid phone number";
    let new_data = { ...interestUserData, [name]: value };
    console.log(new_data, "new Data");
    setInterestUserData(new_data);
    setErrors({
      ...temp,
    });
    const found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("Please");
    });

    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const SubmitData = () => {
    // if (!handleChangeData()) {
    //   return errors;
    // }
    let returnValue = true;
    const found = Object.keys(errors).filter(function (key) {
      return errors[key].includes("required") || errors[key].includes("Please");
    });

    if (found.length) {
      returnValue = false;
    }

    api_call_token
      .post("/test/Interest_brainMapping/", interestUserData)
      .then((res) => {
        console.log(res, "res res");
        // <Alert variant="outlined" severity="success">
        //   Form success Submitted!
        // </Alert>;
        // setOpen(false);
        // history.push("/testseries");
      })
      .catch((err) => {
        // <Alert color="error" closeText="close" variant="outlined" />;

        console.log(err);
      });
  };
  // const {user} = useContext(UserCredsContext);
  // console.log(benefits, "benefits");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className={styles.main_width} style={{ position: "relative" }}>
        <div
          className={styles.head_home}
          style={{ maxHeight: "700px", position: "relative" }}
        >
          <div
            className={`${styles.main_width} ${styles.headingConten} ${styles.pt100} ${styles.ptbt}`}
          >
            <div className={`${styles.box_content}`}>
              <div>
                <h1
                  className={`${styles.bluiesh} ${styles.mx_fnt}`}
                  style={{ marginBottom: "5px" }}
                >
                  Connecting&nbsp;
                  <span className={styles.organge}>
                    your brain and {/*your*/} career
                  </span>
                </h1>
                <p
                  className={`${styles.greyesh} ${styles.mid_font}`}
                  style={{ marginTop: "0px", fontSize: "19px" }}
                >
                  Empowering young individuals to achieve excellence with
                  Neurotechnology.
                </p>
              </div>
              <div>
                <h1 className={`${styles.bluiesh} ${styles.m_font}`}>
                  In collaboration with
                  <br />
                  <span className={`${styles.organge} ${styles.mx_fnt}`}>
                    IIT Bombay
                    <img
                      src={IITBombay}
                      alt="img"
                      style={{ verticalAlign: "middle", paddingInline: "10px" }}
                    />
                  </span>
                </h1>
              </div>
              <button
                className={`${styles.btn_struct} ${styles.bluiesh_back}`}
                onClick={() => {
                  redirects();
                }}
              >
                Take Test
              </button>
            </div>
            <div
              className={`${styles.box_content}`}
              style={{ textAlign: "end" }}
            >
              {/* width="420" height="340" */}
              <video className={styles.videos} autoPlay muted loop>
                <source src={Brains} type="video/mp4" />
              </video>
              {/* <img src={mobile} alt="mobile" className={styles.mobile} /> */}
            </div>
          </div>
        </div>
        <div className={`${styles.main_width}  ${styles.mobile_container}`}>
          <div style={{ textAlign: "center", marginBottom: "80px" }}>
            <h1
              className={`${styles.bluiesh} ${styles.max_font} text-capitalize`}
              style={{ marginTop: "35px", marginBottom: "5px" }}
            >
              {benefits?.title}
            </h1>
            <p
              className={`${styles.greyesh} ${styles.min_font} ${styles.line_he}`}
              style={{ marginTop: "5px" }}
            >
              {benefits?.description}
            </p>
          </div>
          <div className={styles.careermapping}>
            {benefits?.benefits &&
              benefits?.benefits.map((val, indx) => (
                <div key={indx} className={`${styles.benefContent}`}>
                  <div
                    className={styles.benImage}
                    style={{ background: val.colorcode }}
                  >
                    <img src={val.icon2} alt="image" />
                  </div>
                  <div>
                    <h3 className={`${styles.bluiesh} ${styles.btm_marg}`}>
                      {val?.title}
                    </h3>
                    <p className={`${styles.bluiesh} ${styles.line_he}`}>
                      {val?.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <div style={{ textAlign: "center", marginTop: "5rem" }}>
            <button
              className={`${styles.btn_struct} ${styles.organge_back}`}
              onClick={() => {
                redirects();
              }}
            >
              Take Test
            </button>
          </div>
        </div>
        <div
          className={`${styles.main_width} ${styles.improve_main} ${styles.mobile_container}`}
        >
          <div className={`${styles.flex_content} ${styles.box_content}`}>
            <div className={styles.improve_Container}>
              <div className={styles.improve_title}>
                {/* <div className={styles.improve_back}></div> */}
                <h3 className={styles.bluiesh}>Skill based Education</h3>
              </div>
              {/* <p className={`${styles.greyesh} ${styles.improve_para}`}>
                A pool of 10,000+ questions and multiple parameters specifically created for Indian Students
                to measure their behavioural aspects and provide career counselling and guidance for course corrections.
              </p> */}

              <p className={`${styles.greyesh} ${styles.improve_para}`}>
                Get a customized tests from the pool of 10,000+ questions having
                multiple parameters specifically created for Indian students
                targeting different age groups to measure their behavioural
                aspects and provide career counselling and guidance for course
                corrections.
              </p>
            </div>
            <div
              className={styles.improve_Container}
              style={{ alignSelf: "center" }}
            >
              <img src={improve} alt="improve" style={{ width: "100%" }} />
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "2em" }}>
            <button
              className={`${styles.btn_struct} ${styles.bluiesh_back}`}
              onClick={() => {
                redirects();
              }}
            >
              Take Test
            </button>
          </div>
        </div>
        <div
          className={`${styles.main_width} ${styles.support_main} ${styles.mobile_container}`}
        >
          <div style={{ textAlign: "center", fontSize: "28px" }}>
            <h3 className={styles.bluiesh}>
              Supported By <span className={styles.organge}>Research</span>
            </h3>
          </div>
          <div>
            <p
              className={`${styles.greyesh} ${styles.supportPara}`}
              style={{ textAlign: "center" }}
            >
              Brainhap tests are created under the supervision of IIT Bombay. Dr.
              Azizuddin Khan (Scientific Advisor and Mentor) with more than 20
              years of experience in Cognitive Psychology and Psychophysiology.
              {/* We at Lcarro, devised a unique method of assisting students in
              selecting an appropriate career based on their interests,
              aptitudes, and academics, all of which are certified and under the
              supervision of Dr. Azizuddin Khan, IIT Bombay professor. */}
            </p>
            {/* <p
              className={`${styles.greyesh} ${styles.supportPara}`}
              style={{ textAlign: "center" }}
            >
              We've tested bright students from across the country. The only
              career mapping test that can assess 23+ skills, according to your
              aptitude, interest and academics, effectively.
            </p> */}
          </div>
          <div
            className={`${styles.flex_content} ${styles.box_content} ${styles.research_content}`}
          >
            <img
              src={supp1}
              alt="support"
              className={styles.improve_Container}
              style={{ margin: "20px", borderRadius: "23px" }}
            />
            <img
              src={supp2}
              alt="support"
              className={styles.improve_Container}
            />
          </div>

          <div style={{ textAlign: "center", marginTop: "2em" }}>
            <button
              className={`${styles.btn_struct} ${styles.organge_back}`}
              onClick={() => {
                redirects();
              }}
            >
              Take Test
            </button>
          </div>
        </div>
        <div
          className={`${styles.main_width} ${styles.support_main}  ${styles.mobile_container}`}
        >
          <div
            className={`${styles.textCenter}`}
            // style={{
            //   textAlign: "center",
            //   fontSize: "28px",
            //   marginBottom: "40px",
            // }}
          >
            <h3
              className={styles.bluiesh}
              style={{ fontSize: "40px", marginBottom: "60px" }}
            >
              Features
            </h3>
          </div>
          <div className={`${styles.flex_content} ${styles.box_content}`}>
            <div className={styles.improve_Container}>
              <img src={features} alt="image" style={{ width: "100%" }} />
            </div>
            <div className={styles.improve_Container}>
              {feature !== null &&
                feature.map((v, i) => (
                  <div className="col" key={i} style={{ marginBottom: "20px" }}>
                    {v?.icon ? (
                      <img src={v?.icon} alt="img" />
                    ) : (
                      <img src={userF} alt="img" />
                    )}

                    <div>
                      <p
                        className={`${styles.black} ${styles.improve_para}`}
                        style={{ margin: "0" }}
                      >
                        {v.title}
                      </p>
                      <p
                        className={`${styles.greyesh} ${styles.improve_para}`}
                        style={{ margin: "0" }}
                      >
                        {v.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "2em" }}>
            <button
              className={`${styles.btn_struct} ${styles.bluiesh_back}`}
              onClick={() => {
                redirects();
              }}
            >
              Take Test
            </button>
          </div>
        </div>
        <>
          <div className={`${styles.support_main}`}>
            {joins &&
              joins.map((data, idx) => (
                <div className={`${styles.join_main} `}>
                  <div className={`${styles.basic_dtl} ${styles.how_to_join}`}>
                    {data.title}
                  </div>
                  {data?.basic_detail.map((val, i) => (
                    <div className={styles.basic_dtl}>
                      <img
                        src={val.image}
                        alt="icon"
                        className={styles.icons}
                      />
                      <h3>{val.title}</h3>
                      <p className={styles.desc_join}>{val.description}</p>

                      <button
                        className={styles.btn_join}
                        onClick={() => {
                          basic_detail(val.index);
                        }}
                      >
                        {val.button_text}
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            <div style={{ textAlign: "center", marginTop: "2em" }}>
              <button
                className={`${styles.btn_struct} ${styles.organge_back}`}
                onClick={() => {
                  redirects();
                }}
              >
                Take Test
              </button>
            </div>
          </div>
        </>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "100vh",
          }}
        >
          <div className={"styles"}>
            <div className={styles.interest_form}>
              <img
                src={insterst_form_img}
                alt="Inserts Form"
                style={{ width: "45%", height: "auto" }}
              />

              <div>
                <div>
                  <p
                    style={{
                      fontFamily: "Segoe UI",
                      fontWeight: "700",
                      fontSize: "3.5vw",
                      lineHeight: "69px",
                      letterSpacing: " 0.05em",
                      textTransform: "capitalize",
                      color: "#3F51B5",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    love to hear from you
                  </p>
                  <p
                    style={{
                      fontFamily: "Segoe UI",
                      fontWeight: "400",
                      fontSize: "2vw",
                      lineHeight: "53px",
                      letterSpacing: " 0.05em",
                      textTransform: "capitalize",
                      color: "#00000050",
                      margin: "0 0 60px 0",
                      padding: 0,
                    }}
                  >
                    get in touch
                  </p>
                  {/* <AlertMessageComponent open={open} /> */}
                  <TextField
                    className={styles.textWidth}
                    value={interestUserData.name}
                    label="Full Name"
                    onChange={(e) => handleChangeData(e)}
                    placeholder="Full Name"
                    variant="outlined"
                    id="outlined-full-width"
                    name="name"
                    required
                    style={{ width: "27em" }}
                  ></TextField>
                  <p className="error-message-text error-marginbottom">
                    {errors.name}
                  </p>
                </div>
                <div className="">
                  <TextField
                    name="mobile_number"
                    value={interestUserData.mobile_number}
                    // onChange={handleChangeData}
                    onChange={(e) => handleChangeData(e)}
                    placeholder="Phone No."
                    label="Phone No."
                    // className={classes.textWidth}
                    className={styles.textWidth}
                    variant="outlined"
                    id="outlined-full-width"
                    type="number"
                    required
                    style={{ width: "27em" }}
                  ></TextField>
                  <p className="error-message-text error-marginbottom">
                    {errors.mobile_number}
                  </p>
                </div>
                <div>
                  <TextField
                    style={{ width: "27em" }}
                    className={styles.textWidth}
                    name="city"
                    value={interestUserData.city}
                    onChange={(e) => handleChangeData(e)}
                    placeholder="City"
                    label="City"
                    variant="outlined"
                    id="outlined-full-width"
                  ></TextField>
                  <p className="error-message-text error-marginbottom">
                    {errors.city}
                  </p>
                </div>

                <Button
                  variant="contained"
                  className="btn_model"
                  onClick={() => {
                    SubmitData();
                    console.log(interestUserData, "interestUserData");
                  }}
                  color="primary"
                  style={{
                    borderRadius: "12px",
                    width: "auto",
                    fontSize: "18px",
                    fontWeight: "550",
                  }}
                  // disabled={enable}
                >
                  Submit
                  {/* {guardianExists !== 0 ? "Update Profile" : "Submit"} */}
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        {/* interest form */}
        <div
          className={`${styles.main_width} ${styles.support_main}  ${styles.mobile_container}`}
        >
          {/* <div style={{ position: 'relative' }}>
              <p className={`${styles.bluiesh} ${styles.testimonial_test}`}>
                TESTIMONIAL
              </p>
            </div> */}
          <div style={{ textAlign: "center" }}>
            <h1 className={`${styles.bluiesh} ${styles.max_norm_font}`}>
              Reviews from Our Partner Schools
            </h1>
          </div>

          <Carousel responsive={responsive}>
            {testimonialData.map((v, i) => {
              return (
                <div key={i} style={{ width: "100%", padding: "10px" }}>
                  <div
                    className={`${styles.flex_content} ${styles.ccr_content}`}
                  >
                    <div className={styles.rev_bx}>
                      <div></div>
                      <div>
                        <p className={`${styles.greyesh} ${styles.testi_para}`}>
                          {v.text}
                        </p>
                      </div>
                      <div className={styles.tes_below_content}>
                        <div className={styles.testi_border}></div>
                        <div style={{ width: "inherit" }}>
                          <div
                            className={styles.flex_content}
                            style={{ alignItems: "center" }}
                          >
                            <div className={styles.greyesh}>
                              <p className={styles.mbZTitle}>{v.name}</p>
                              <p className={styles.mbZ}>{v.institute_name}</p>
                              <p className={styles.mbZ}>{v.position}</p>
                            </div>

                            <Box component="fieldset" borderColor="transparent">
                              {/* {console.log(v.rating, " VVVV")} */}
                              <Rating
                                name="half-rating-read"
                                defaultValue={v.rating}
                                precision={0.5}
                                readOnly
                              />
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${styles.testimonial_test_new} ${styles.t_brd}`}
                      >
                        {/* <button className={styles.assessment}>
                            Write your assessment
                            <div className={styles.testimonial_test_new}>
                              <ArrowForwardIcon />
                          </div>
                        </button> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.test_caro_right} ${styles.rev_bx}`}
                    >
                      <video
                        width="80%"
                        className={styles.video_height}
                        controls
                      >
                        <source src={v.video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>

          <div
            style={{
              textAlign: "center",
              marginTop: "2em",
              marginBottom: "2em",
            }}
          >
            <button
              className={`${styles.btn_struct} ${styles.bluiesh_back}`}
              onClick={() => {
                redirects();
              }}
            >
              Take Test
            </button>
          </div>
        </div>
        {/* <div className={styles.banner_mobile}>
          <img src={Rectangle} alt="rect" className={styles.image_blue} />
          <div className={styles.flex_content}>
            <div className={`${styles.data_book} ${styles.banner_img}`}>
              <img src={bookcarrer} alt="images" />
            </div>
            <div className={`${styles.data_book} ${styles.banner_description}`}>
              <p className={styles.book_carrer}>
                BOOK A CAREER COUNSELLING SESSION
              </p>
              <button
                className={`${styles.btn_struct} ${styles.organge_back} ${styles.org_width}`}
              >
                Book
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className={styles.banner_desktop} style={{ position: "relative" }}>
          <img src={Rectangle} alt="rect" className={styles.image_blue} />
          <div className={styles.flex_content}>
            <div style={{ width: "50%" }}>
              <img src={bookcarrer} alt="images" style={{ width: "100%" }} />
            </div>
            <div className={styles.data_book}>
              <div>
                <p className={styles.book_carrer}>
                  BOOK A CAREER COUNSELLING SESSION
                </p>
                <button
                  className={`${styles.btn_struct} ${styles.organge_back} ${styles.org_width}`}
                  onClick={() => history.push("/ask-to-counselor")}
                >
                  Book now
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={`${styles.main_width} ${styles.support_main} ${styles.btm_none}`}
        >
          <div style={{ display: "flex" }}>
            {window.innerWidth > 992 ? (
              ""
            ) : (
              <div style={{ margin: "0 auto -42px", zIndex: "1" }}>
                <Popovr />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeRewamp;
