import React from "react";
import styles from "./index.module.css";
import { DraftsOutlinedIcon } from "@material-ui/icons/DraftsOutlined";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { api_call_token, api_token } from "../../Utils/Network";
import { api_call, setApiTken } from "../../Utils/Network";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Feeds() {
  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const [msg, setMsg] = React.useState("");
  const [feedback, setFeedback] = React.useState({
    rating: value,
    comments: msg,
  });

  const handle = (event) => {
    console.log(event, "event event");
    const { name, value } = event.target;
    setFeedback({ ...feedback, [name]: value });
    setValue(feedback.rating);
    console.log(feedback, "feedback");
  };

  const submitFeedback = () => {
    // console.log(event, "feedback response");
    // console.log(event);
    // const { name, value } = event.target;
    // setFeedback({ ...feedback, [name]: value });
    let slots = localStorage.getItem("slotsID");

    var payload = {
      slot: slots,
      rating: feedback.rating,
      comment: feedback.comments,
    };
    console.log(" payload", feedback.rating);
    api_call_token
      .post(`/counseling/review_session/`, payload)
      .then((response) => {
        if (response.data.data) {
          localStorage.removeItem("slotsID");
          alert("Feedback Submitted...");
          history.push("/my-space");
        }
      })
      .catch((error) => {
        console.log("Error", error.message);
      });
    console.log("asas");
  };

  // React.useEffect(() => {
  //   submitFeedback();
  // }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className={styles.mainBox}>
        <div className={styles.session_title}>
          We hope you had a great session. We hope this will help you strive
          towards your desired career goal.
        </div>
        <div className={styles.mainData}>
          <div className={styles.centerBx}>
            <Typography component="legend" textAlign="center">
              We would love to know your experience. Please take a minute and
              share your feedback. Thank you!
              </Typography>
              <Rating name="rating" value={value} onChange={(e) => handle(e)} />
            <div style={{ margin: "10px 0" }}>
              <TextField
                id="outlined-multiline-static"
                label="Give Feedback"
                inputProps={{ style: { textAlign: "center" } }}
                multiline
                rows={4}
                variant="outlined"
                onChange={handle}
                style={{ width: "100%",textAlign: "center" }}
                name="comments"
              />
              <Button
                variant="contained"
                className="btn_model"
                onClick={() => submitFeedback()}
                color="primary"
                style={{
                  borderRadius: "8px",
                  width: "82%",
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "550",
                  backgroundColor: "rgb(23, 43, 105);",
                  color: "#FFF",
                }}
                // disabled={enable}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feeds;
