import React, { useState, useEffect, useContext } from "react";
import "./TeachersRegistration.css";
import bg1 from "../Forms/formassets/Asset 4.png";
import bg2 from "../Forms/formassets/Asset 5.png";
import { login_api } from "../../Utils/Network";
import Autocomplete from "@material-ui/lab/Autocomplete";
import bgmobile from "../Forms/formassets/Asset 5 (1).png";
import SingleButton from "../SingleButton/SingleButton";
import TextField from "@material-ui/core/TextField";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function TeachersRegistration(props) {
  const { userType, loginData, updateLoginData, setToken, setUser } = useContext(UserCredsContext);
  const [_institute, setInstitute] = useState([]);
  const [inst, setInst] = useState(null);
  const [_data, setData] = useState([]);
  const [_board, setBoard] = useState(null);
  const [errors, setErrors] = useState({});
  const [gradeUpd, setGrade] = useState(null);
  const [name, setName] = useState({ name: null });
  const [stateId, setStateId] = useState([]);
  const [userData, setUserData] = useState({
    f_name: "",
    lname: "",
    pass: "",
    renter: "",
  });

  const checkId = (e, type) => {
    const { name, value } = e.target;
    if (errors.hasOwnProperty(name) && errors[name].length !== 0) {
      setErrors({ ...errors, [name]: "" });
    }
    if (name == "name") {
      return;
    }
    setName({
      ...name,
      [name]: value,
    });
  };

  const handle = (event) => {
    const { name, value } = event.target;

    setUserData({ ...userData, [name]: value });
  };
  const handleChange = (event, val) => {
    const op = val !== null ? setBoard(val.id) : "Please select Board";
    setErrors({ ...errors, op });
  };

  const handleChanged = (event, values) => {
    const outpt = values !== null ? setGrade(values.id) : "Please select Grade";
    setErrors({ ...errors, outpt });
  };

  const handleChanges = (event, values) => {
    const out = values !== null ? setInst(values.id) : "Please select Language";
    setErrors({ ...errors, out });
  };

  const getBoard = () => {
    login_api
      .get(`/base/board/`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getGrade = () => {
    login_api
      .get(`/base/grade/`)
      .then((response) => {
        setStateId(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const institute = () => {
    login_api
      .get("/base/language/")
      .then((response) => {
        setInstitute(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getBoard();
    getGrade();
    institute();
  }, []);

  const validate = () => {
    let temp = {};
    let returnValue = true;
    temp.names = /^[a-zA-Z_\- ]{2,30}/.test(name.name)
      ? ""
      : "Please enter valid name.";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("valid");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const SubmitData = () => {
    if (!validate) {
      return null;
    }

    var datas = {
      tutor_profile: {
        board_id: [_board],
        grade_id: [gradeUpd],
        language_id: [inst],
        institute: 1,
        first_name: userData.f_name,
        last_name: userData.lname,
      },
      user_type: userType,
      password: userData.pass,
      first_name: userData.f_name,
      last_name: userData.lname,
      phone: loginData.phone,
    };

    

    login_api
      .post(`auth/lcarro/${loginData.id}/student_register/`, datas)
      .then((response) => {
        const { token, user } = response.data.data;
        setToken(token);
        setUser(user);
        props.history.push('/')
      });
  };

  return (
    <>
      <div className="teach_main_reg">
        <div className="teach_content">
          <div className="wrapper_container">
            <div className="reg_section">
              <div className="head_main_reg">
                <h1 className="reg_head">Teachers Registration</h1>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">Name</label>
                  <div>
                    <input
                      type="text"
                      className="tr_input"
                      placeholder="First name"
                      onChange={handle}
                      name="f_name"
                      //value={userData.fname}
                      // onChange={(e) => checkId(e, "input")}
                    ></input>
                    <p className="error-message-text">{errors.name}</p>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl"></label>
                  <div>
                    <input
                      type="text"
                      className="tr_input"
                      onChange={handle}
                      name="lname"
                      placeholder="Last name"
                    ></input>
                  </div>
                </div>
              </div>
              <div>
                <Autocomplete
                  id="combo-box-demo"
                  options={stateId}
                  onChange={handleChanged}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "80%", margin: "auto" }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Grade" />
                  )}
                />
                <p>{errors.outpt}</p>

                <Autocomplete
                  id="combo-box-demo3"
                  options={_data}
                  getOptionSelected = {(option, value) => option === value}
                  onChange={handleChange}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "80%", margin: "auto" }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Board" />
                  )}
                />
                <p>{errors.op}</p>

                <Autocomplete
                  id="combo-box-demo3"
                  options={_institute}
                  onChange={handleChanges}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "80%", margin: "auto" }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Language" />
                  )}
                />
                <p>{errors.op}</p>
              </div>

              {/* <div className="contain_reg">
                <label className="lbl">Email ID</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Phone Number</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">State</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl">City</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Pincode</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Degree</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div> */}

              <div className="">
                <input
                  name="pass"
                  value={userData.pass}
                  onChange={handle}
                  placeholder="Password"
                  className="tr_input"
                  type="password"
                ></input>
                <p className="err_pass">
                  {errors.password} &nbsp;
                </p>
              </div>

              <div className="">
                <input
                  name="renter"
                  onChange={handle}
                  value={userData.renter}
                  placeholder="Re-Enter Password"
                  className="tr_input"
                  type="password"
                ></input>
                <p className="err_pass">
                  {errors.checkpass} &nbsp;
                </p>
              </div>
            </div>
            <div className="btn_reg_class">
              <button className="sig_button" onClick={SubmitData}>
                Submit
              </button>
            </div>
          </div>
          <div className="Teach_images">
            <h1 className="heads_teach ht_m">Teachers Registration</h1>
            <img src={bg1} alt="bg1" className="teach_reg_img trI"></img>
            <img src={bgmobile} alt="bg1" className="teach_reg_img btM"></img>
          </div>
        </div>
        <img src={bg2} alt="bg1" className="bg_teach_img btI"></img>
      </div>
    </>
  );
}

export default TeachersRegistration;
