import React, { useEffect, useContext, useState } from 'react'
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { api_call, base_url } from "../../Utils/Network";
import { useHistory } from "react-router-dom";

function RedirectCookie(props) {
    let history = useHistory();
    const { setDisplayType, setOpenModel, setToken, setFilter, setUser, user } = useContext(UserCredsContext);
    const [loading, setLoading] = useState(true);
    const [messageData, setMessageData] = useState("");

    useEffect(() => {
        var url_token = props.history.location.pathname.split("/").pop();
        LoginUser(url_token);
    }, []);


    const LoginUser = async (url_token) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${url_token}`);
    
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(
          `${base_url}/counseling/redirect/auth/`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
              console.log(result, "result")
            var response = JSON.parse(result);
            console.log(response, "response_data")
            const { data2, access_token } = response;
            console.log(data2, access_token, "TOKEN")
            setLoading(false);
            // setToken(access_token);
            localStorage.setItem("network_access_token", access_token);
            setUser(data2);
            setLoading(false);
            history.push("/testseries");
          })
          .catch((error) => {
            console.error('Error', error)
            setLoading(false);
            setMessageData('Something went wrong');
          });
      };

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #161654 , #787cb9)",
          height: "100px",
        }}
      ></div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          <CircularProgress />
          <br />
          <br />
          Loading please wait ...
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          {messageData}
        </div>
      )}
    </div>
  )
}

export default RedirectCookie