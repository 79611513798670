import React, { useEffect, useState } from 'react';
import { Box, Avatar, Paper } from "@material-ui/core"
import Rating from '@material-ui/lab/Rating';
import './RatingAndReview.css';
import { Slide, Dialog, DialogContent, DialogActions, TextField, Button } from '@material-ui/core'
import styled from 'styled-components';
import { api_call } from '../../Utils/Network';
import { falseChecker } from '../../Utils/Utils';
import { MainDataContextApi } from '../../ContextApis/MainDataContextApi/MainDataContextApi';
import BUTTON3 from '../../Assets/homepage/BUTTON3.png';
import Skeleton from '@material-ui/lab/Skeleton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




// const reviewLists = rvww.slice().reverse().map((rev, id) => {
//     const initial = rev.name;




// }
// )




const ReviewListItem = ({ user = {}, rating, review, course_review_like, course_review_dislike, likeurl, rev_id, dislikeurl, ...props }) => {
    const { likes_count, reply_count, dislikes_count } = props._props;
    const [like, setLike] = useState(0);
    const [unlike, setUnlike] = useState(0);
    const inclike = () => {
        api_call.post(likeurl)

            .then(response => {
                setLike(response.data.data);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 403) {
                        let error_message = error.response.data.error.message;
                    }
                }
            })
    }

    const incunlike = () => {
        api_call.post(dislikeurl)
            .then(response => {
                setUnlike(response.data.data);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 403) {
                        let error_message = error.response.data.error.message;
                    }
                }
            })
    }

    const handlechange1 = () => {
        inclike();
    }

    const handleChange2 = () => {
        incunlike();
    }


    return (

        <div className="user-ratings-crd">

            <div className="user-profile-c">
                <Avatar alt="profile pic" src={user.avatar} style={{ background: 'red', margin: '10px 0px', textTransform: 'uppercase' }}>{falseChecker(user.first_name) ? user.first_name[0] : "A"}</Avatar>
            </div>

            <div className="user-details-rating-comment">
                <div className="user-details-top-c">
                    <p className="user-name-c-r" style={{ marginTop: '12px', marginBottom: '0px', fontWeight: 'bold' }}> {user.first_name + " " + user.last_name}</p>
                    <div style={{ marginTop: '12px' }}>
                        <Rating
                            name="simple-controlled"
                            value={rating}
                            name="read-only"
                            readOnly
                            size='small'
                        />
                    </div>
                </div>
                <div className="user-review-c">
                    <p style={{ marginTop: '2px', marginBottom: '10px' }}>{review}</p>
                </div>
                <div className="user-reveiew-reply">
                    {/* <div className="like-dislike-c">
                        <Button onClick={inclike}>{course_review_like} Like </Button>
                        <Button onClick={incunlike}>{course_review_dislike} Dislike</Button>
                    </div> */}
                    {/* <div className="reply-button-review">
                        <p>Reply</p>
                    </div> */}
                </div>
                {/* <div className="view-reply-c">
                    {reply_count !== 0 && <p>View {reply_count} replies</p>}
                </div> */}
            </div>
        </div>

    )
}





const RateAndReview = ({ value, likeurl, url, detail, x1, x2, x3, x4, x5, v1, v2, v3, v4, v5, canReview = false, totalRating, onChange = () => { }, reviewList = [], onSubmit = () => { }, ...props }) => {
    const [writeReview, setWriteReview] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alerts, setAlerts] = React.useState({
        review: ""
    })

    const [details, setDetails] = React.useState({
        reviewers: '',
        average_rating: ''
    });

    useEffect(() => {
        if (detail !== undefined) {
            const { reviewers, average_rating } = detail;
            setDetails({ reviewers, average_rating });
        }
    }, [detail])


    const handleReview = event => {

        const { value } = event.target;
        // setWriteReview(ps => ({ ...ps, content: value }));
        onChange("user_review", value)
    }


    const { context_token, toggleLoginCard } = React.useContext(MainDataContextApi);
    const handleSubmit = () => {
        const _alerts = { ...alerts };

        _alerts.review = falseChecker(value.user_review) ? "" : "Review can't be blank.";
        setAlerts({ ..._alerts })

        if (falseChecker(value.user_review)) {
            api_call.post(url, { rating: value.user_rating, comment: value.user_review, ...props.postData })
                .then(response => {
                    if (response.status === 200 || response.status === 201) {
                        resetData();
                        onSubmit(response);


                    }
                })
                .catch(error => {
                    console.error(error);
                })

        }
    }

    const resetData = (bool = false) => {
        onChange("user_review", "");
        onChange("user_rating", 0);
        setWriteReview(false)
    }



    const handleLike = () => {

        api_call.get(`/offline/rating/39`)
            .then(response => {
                console.log("Rviews response for like ", response.data.data);
            })
            .catch(error => {
                console.error(error);
            })

        // var data = {
        //     "review_id": 39,
        //     "comment": "ty"
        // }
        // api_call.get(`/offline/review_reply/`,data)
        //     .then(response => {
        //         console.log("Rviews response for like ", response.data.data);
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     })
    }

    useEffect(() => {
        if (value.hasOwnProperty('title')) {
            setLoading(false);
        }
    }, [value])

    return (

        <>
            <div className='review-rate-cont' >
                <div className="kRUZUm">
                    {loading ? (<div style={{ background: 'white' }}>
                        <Skeleton variant='rect' width={570} height={142} />
                    </div>) :
                        <Paper className="" style={{ boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px' }}>
                            <h2 className="crs-info-head" style={{ marginTop: '10px' }}>Reviews </h2>
                            {/* <div style={{ marginBottom: '10px' }}> Tap to rate your experience </div>
                <Rating
                    name="simple-controlled"
                    value={value.user_rating}
                    onChange={(event, new_rating) => {
                        console.log("The rating given ", event, new_rating)
                        // setUserRating(new_rating);
                        onChange("user_rating", new_rating)

                    }}
                    size="medium" /> */}


                            <React.Fragment>
                                {
                                    falseChecker(context_token.access)
                                        ? (<React.Fragment>{canReview && <div style={{ padding: '0px 20px 20px' }}>
                                            <Button style={{ cursor: 'pointer', backgroundImage: `url(${BUTTON3})`, color: 'white', backgroundSize: '100% 100%' }} onClick={e => setWriteReview(true)}>Rate and review </Button>
                                        </div>}</React.Fragment>)
                                        :
                                        (<span></span>)
                                }
                                {/* (<div style={{ padding: '0px 20px 20px' }}>
                                            <Button onClick={(e) => toggleLoginCard(true)} style={{ cursor: 'pointer', backgroundImage: `url(${BUTTON3})`, color: 'white', backgroundSize: '100% 100%' }}>Rate and review </Button>
                                        </div>) */}
                                <div style={{ padding: '0px 20px 20px' }}>
                                    {reviewList.map(review => <ReviewListItem hitLike={handleLike} rev_id={review.id} user={review.user_info} rating={review.rating} likeurl={`offline/review/${review.id}/like/`} dislikeurl={`/offline/review/${review.id}/dislike/`} course_review_like={review.likes_count} course_review_dislike={review.dislikes_count} review={review.comment} _props={review} />)}
                               {reviewList.length === 0 && <p style={{marginLeft:'5px'}}>  No Reviews</p>}
                                </div>
                            </React.Fragment>

                        </Paper>}
                </div>
                <div className="kRUZUm">
                    {loading ? (<div style={{ background: 'white' }}>
                        <Skeleton variant='rect' width={232} height={346} />
                    </div>) :
                        <Paper className="rate-ppr" style={{ paddingTop: '0px',boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px'  }}>

                            <h2 className="crs-info-head" style={{ marginTop: '10px' }}>Ratings</h2>
                            <div className="rating-profile-review">
                                <p>{details.average_rating}</p>
                            </div>
                            <Rating
                                name="simple-controlled"
                                value={details.average_rating}
                                size="medium"
                                name="read-only"
                                readOnly
                                precision={0.5}
                                style={{ margin: '10px' }}
                            />
                            <p style={{ margin: '7px' }}>{details.reviewers}</p>
                            <p style={{ margin: '5px' }}>Total ratings</p>
                            <div style={{ marginTop: '20px' }}>


                                <RatingProgressBar value={x5} forRating={5} />
                                <RatingProgressBar value={x4} forRating={4} />
                                <RatingProgressBar value={x3} forRating={3} />
                                <RatingProgressBar value={x2} forRating={2} />
                                <RatingProgressBar value={x1} forRating={1} />



                            </div>
                        </Paper>}
                </div>
            </div>
            <Dialog
                open={writeReview}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setWriteReview(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                style={{ width: '70%', margin: 'auto' }}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <p className="write-a-review-text">Write A Review</p>
                <div className="write-a-review-text-sub">
                    <p >Tap to rate your experience</p>

                    <Box component="fieldset" mb={3} borderColor="transparent">
                        <Rating
                            name="simple-controlled"
                            value={value.user_rating}
                            onChange={(event, new_rating) => {
                                // setUserRating(new_rating);
                                onChange("user_rating", new_rating)
                            }}
                            size="large"
                        />
                    </Box>
                </div>
                <DialogContent>
                    <TextField
                        required
                        id="outlined-required"
                        label="Review"
                        variant="outlined"
                        name="text"
                        onChange={handleReview}
                        value={value.user_review}
                        multiline
                        rows={5}
                        error={falseChecker(alerts.review)}
                        helperText={alerts.review}
                        style={{ width: '100%', margin: 'auto' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setWriteReview(false)} color="primary">Cancel</Button>
                    <Button color="primary" onClick={handleSubmit}> Submit  </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

RateAndReview.propTypes = {

};

RateAndReview.defaultProps = {

    value: {
        rating: 3.5,
        five_star_rating: 60,
        four_star_rating: 20,
        three_star_rating: 10,
        two_star_rating: 5,
        one_star_rating: 2,
        total_ratings: 2300,
        user_review: '',
        user_rating: '',
        reviews: []

    },
    postData: {}
}

const RatingBarCtn = styled.div`
display: grid;
    grid-template-columns: 10% 80% 10%;
    margin-top: 10px;
    height: 30px;
    place-content: center;
    place-items: center;
    margin: 0px;
    padding: 0px;
`

const RatingProgressBar = ({ value, forRating, ...props }) => {

    const calculateColour = () => {

        switch (forRating) {
            case 5: return "#57bb8a";
            case 4: return "#9ace6a";
            case 3: return "#ffcf02";
            case 2: return "#ff9f02";
            case 1: return "#ff6f31";
        }
    }

    return (
        <RatingBarCtn>
            <div className="star-count-profile-review">
                <p>{forRating}</p>
            </div>
            <div className="progress">
                <div className="progress-bar" style={{ width: `${value}%`, backgroundColor: calculateColour() }}></div>
            </div>
            {/* <div>
                <p>{value}</p>
            </div> */}
        </RatingBarCtn>

    )
}
export default RateAndReview;