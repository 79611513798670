import React from "react";
import { api_call, setApiToken } from "../../Utils/Network";
import { createMuiTheme } from "@material-ui/core";
import { logo_colors, falseChecker } from "../../Utils/Utils";
import { getItems } from "../../Utils/GeneralApiCalls";

export const MainDataContextApi = React.createContext();

// const secondary_color = "#FD0D59";
// const primary_color = '#FDC701';
const grey_color = "#848484";
const secondary_color = "#133268";
const primary_color = "#EF9022";

const localstorage_state = localStorage.getItem("myava_context_data_n");

function getLocalData() {
  if (localstorage_state) {
    let local_data = JSON.parse(localstorage_state);
    return {
      user: local_data.user,
      token: local_data.token,
      navigation: local_data.navigation,
      navItemActive: local_data.navItemActive, // setting state with localdata
      local_loaded: true,
      checkCourseType: local_data.checkCourseType,
      checkPanelType: local_data.checkPanelType,
    };
  }
  return {
    user: {},
    token: {},
    navigation: undefined,
    navItemActive: undefined, // setting state with localdata
    local_loaded: false,
    checkCourseType: "",
    checkPanelType: "",
  };
}

class MainDataContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...getLocalData(),

      colors: {
        primary: primary_color,
        secondary: secondary_color,
        ternary: "",
        grey: grey_color,
        others: [],
      },
      navigation: {
        open: true,
        witheffect: true,
      },

      typeCategory: [],
      styles: {
        learnMoreButton: {
          backgroundColor: "grey",
          color: "#fff",
        },
        SubmitButton: {
          // backgroundColor: primary_color,
          color: "#fff",
        },
      },

      conditional_styles: {
        tracker: {
          active: "#ee9000",
          unactive: "#182d67",
        },
      },

      footer_intersecting: false,

      theme: createMuiTheme({
        palette: {
          primary: { 500: primary_color },
          secondary: { A400: secondary_color },
        },
      }),

      navItemActive: -1,

      logo_colors,
      snack_bar: { open: false, message: "", severity: "" },

      base_items: {
        board: [],
        grade: [],
        subject: [],
      },
      cart: [],
      compareCourse: [],
      myspace: {
        online: {
          data: [],
          isLoaded: false,
        },
        offline: {
          data: [],
          isLoaded: false,
        },
        live: {
          data: [],
          isLoaded: false,
        },
      },
      my_course_content: {
        id: undefined,
        chapters: [],
        grade: {},
        board: {},
      },
      loginCard: false,
      purchase_id: null,
      checkCourseType: "",
      checkPanelType: "",
    };

    this.setFooterIntesection = this.setFooterIntesection.bind(this);
    this.setTestData = this.setTestData.bind(this);
    this.setSnackbar = this.setSnackbar.bind(this);
    this.getBaseItems = this.getBaseItems.bind(this);
    this.addCourseToCart = this.addCourseToCart.bind(this);
    this.updateCartItem = this.updateCartItem.bind(this);
    this.getMyOnlineCourses = this.getMyOnlineCourses.bind(this);
    this.getMyCourseDetails = this.getMyCourseDetails.bind(this);
    this.toggleLoginCard = this.toggleLoginCard.bind(this);
    this.setCompareCourse = this.setCompareCourse.bind(this);
    this.setPurchaseId = this.setPurchaseId.bind(this);
    this.setCheckCourseType = this.setCheckCourseType.bind(this);
    this.setCheckPanelType = this.setCheckPanelType.bind(this);
  }

  componentDidMount() {
    // let user = localStorage.getItem('myava_context_data')
    // if(user){
    //     if(user.verified !==  null){
    //     user = JSON.parse(user);

    //     this.setState({
    //         user
    //     })
    // }else{
    //     this.setState({
    //         ...this.state.user,verified:false,
    //     })
    // }
    // }

    // console.log(user,'context fired')

    let local_data = localStorage.getItem("myava_context_data_n"); // Reading the localstorage to setState
    if (local_data) {
      // local_data !== null
      local_data = JSON.parse(local_data); //Parsing the string to object

      this.setState({
        user: local_data.user,
        token: local_data.token,
        navigation: local_data.navigation,
        checkCourseType: local_data.checkCourseType,
        checkPanelType: local_data.checkPanelType,
        navItemActive: local_data.navItemActive, // setting state with localdata
      });

      // console.log("local_data.token.access", local_data.token.access);

      // setApiToken(local_data.token.access);              //Setting api instance with access key

      localStorage.removeItem("myava_context_data_n"); // removing the data from localstorage
    }

    window.addEventListener("beforeunload", () => {
      // adding event listner to run function befor window is unloaded
      localStorage.setItem("myava_context_data_n", JSON.stringify(this.state)); // storing data to localstorage
    });

    this.getBaseItems();
  }

  componentWillUnmount() {}

  async getMyCourseDetails(id) {
    console.log("getMyCourseDetails called");

    const { my_course_content } = this.state;
    if (parseInt(my_course_content.id) === parseInt(id)) {
      return new Promise((resolve, reject) => {
        resolve(my_course_content);
      });
    } else {
      console.log("getMyCourseDetails ids", my_course_content.id, id);
      const courseResponse = await api_call
        .get(`/content/course/${id}/`)
        .catch((error) => {
          console.error(error);
        });

      if (!falseChecker(courseResponse)) throw new Error("There was an error.");
      if (courseResponse.status === 200) {
        var urlParam;
        if (this.state.checkCourseType == "Edunguru") {
          urlParam = "chapter";
        } else {
          urlParam = "chapter_id";
        }
        const data = courseResponse.data.data;
        const chps = data.chapters.map((item) => ({
          ...item,
          notes: [],
          concepts: item.concepts.map((_concept) => ({
            ..._concept,
            color:
              "#" + Math.floor(Math.random() * 16777215).toString(16) + "dd",
          })),
        }));

        for (let i = 0; i < chps.length; i++) {
          await api_call
            .get(`/content/notes/?${urlParam}=${chps[i]["id"]}`)
            .then((chp_res) => {
              chps[i]["notes"] = chp_res.data.data;
            });
        }

        data.chapters = chps;

        console.log("getMyCourseDetails ", data, chps);
        this.setState({
          my_course_content: data,
        });

        return data;
      } else {
        throw new Error("There was an error.");
      }
    }
  }

  async getMyOnlineCourses() {
    const { myspace } = this.state;
    if (myspace.online.isLoaded) {
      return new Promise((reslove, reject) => {
        reslove(this.state.myspace.online.data);
      });
    } else {
      const response = await api_call
        .get(`/purchase/mycourses/?course__entity_type=1`)
        .catch((error) => {
          console.log("The mycourse error is ", error);
        });
      console.log("Got response getMyOnlineCourses ", response);
      if (!falseChecker(response)) throw new Error("There was an error.");

      if (response.status === 200) {
        let data = response.data.data;
        let online = {
          data,
          isLoaded: true,
        };

        myspace.online = online;
        this.setState({
          myspace,
        });
        return data;
      } else {
        throw new Error("There was an error.");
      }
    }
  }

  getBaseItems() {
    const config = {
      grade: { url: "/base/grade/", name: "grade" },
      board: { url: "/base/board/", name: "board" },
      subject: { url: "/base/subject/", name: "subject" },
    };
    getItems(config)
      .then((data) => {
        console.log("get items ", data);
        if (data.status) {
          const { grade, board, subject } = data.data;
          const { base_items } = this.state;
          base_items.grade = grade;
          base_items.board = board;
          base_items.subject = subject;

          this.setState({
            base_items,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setSnackbar = (open, message, severity) => {
    console.log(open, message, severity, "mayos");
    if (
      (open === true || open === false) &&
      ["error", "warning", "info", "success"].includes(severity)
    ) {
      console.log(
        `The open is ${open} message is ${message} and severity is ${severity}`
      );
      this.setState({
        snack_bar: {
          open,
          message,
          severity,
        },
      });
    } else if (severity === undefined) {
      this.setState({ snack_bar: { open: false } });
    } else {
      console.error(
        `The open is ${open} message is ${message} and severity is ${severity}`
      );
      throw new Error("Improper fields provided to snackbar.");
    }
  };

  setFooterIntesection(value) {
    this.setState({
      footer_intersecting: value,
    });
  }

  setToken = (args_token) => {
    // setting token function

    if (args_token) {
      const { refresh, access } = args_token; // destructing refresh and access token

      let token = this.state.token;

      token["refresh"] = refresh;
      token["access"] = access;

      this.setState({
        // settting token in state
        token,
      });
    } else {
      console.error("Token is undefined");
    }
  };

  setUser = (user_data) => {
    // Setting user data in state

    let user = { ...this.state.user, ...user_data };
    this.setState({
      user,
    });
  };

  setNavigationBar = (nav_object) => {
    //setting navigation bar config settings
    let navigation = { ...this.state.navigation, ...nav_object };
    // this.setState({
    //     navigation
    // })
  };

  setNavItemActive = (active) => {
    let navItemActive = active;
    this.setState({
      navItemActive,
    });
  };

  setTypeCategory = (cat_data) => {
    console.log(cat_data, "hello data");
    this.setState({
      typeCategory: cat_data,
    });
  };

  addCourseToCart(course, callback) {
    console.log(this.state.cart, this.state.compareCourse, "_cart");
    let _cart = [...this.state.cart];
    let checkifpresent = _cart.find((item) => item.id === course.id);
    console.log("Enrolling course", course, checkifpresent);

    if (!falseChecker(checkifpresent) || checkifpresent === -1) {
      _cart.push(course);

      this.setState(
        {
          cart: _cart,
        },
        () => {
          if (typeof callback === "function") {
            callback();
          }
        }
      );
    } else {
      if (typeof callback === "function") {
        callback();
      }
    }
  }

  updateCartItem(cart_item) {
    const { cart } = this.state;

    const index = cart.findIndex((item) => item.id === cart_item.id);

    console.log("updateCartItem", index);
    if (index !== -1) {
      cart[index] = { ...cart[index], ...cart_item };
      this.setState({
        cart,
      });
    }
  }

  setCheckCourseType(course_type) {
    console.log(course_type, "course_type");
    this.setState({ checkCourseType: course_type });
  }

  setCheckPanelType(panel_type) {
    this.setState({ checkPanelType: panel_type });
  }

  // logout = (page) => {

  //     this.setState({
  //         user: {

  //             first_name: '',
  //             last_name: '',
  //             username: '',
  //             id: '',
  //             email: '',
  //             phone: '',
  //             gender: '',
  //             avatar: '',
  //             verified: false

  //         },
  //         token: {
  //             access: undefined,
  //             refresh: undefined
  //         },

  //         colors: {
  //             primary: '',
  //             secondary: '',
  //             ternary: '',
  //             others: []
  //         },
  //         navigation: {
  //             open: false,
  //             witheffect: true,

  //         },
  //         navItemActive: -1,
  //         cart: [],
  //         myspace: {
  //             online: {
  //                 data: [],
  //                 isLoaded: false
  //             },
  //             offline: {
  //                 data: [],
  //                 isLoaded: false
  //             },
  //             live: {
  //                 data: [],
  //                 isLoaded: false
  //             }
  //         }

  //     }, () => {
  //         if (page == 'cart') {
  //             this.toggleLoginCard(true)
  //             return null;
  //         }
  //         window.location.href = "/";
  //     })
  // }

  setTestData(data) {
    this.setState({
      test_data: data,
    });
  }

  toggleLoginCard(bool, callback) {
    console.log("toggleLoginCard ", bool);

    this.setState(
      {
        loginCard: bool,
      },
      () => {
        if (typeof callback === "function") {
          callback();
        }
      }
    );
  }
  setCompareCourse(data) {
    let courseList = [...this.state.compareCourse];
    courseList.push(data);
    this.setState({
      compareCourse: courseList,
    });
  }

  setPurchaseId(id) {
    this.setState({ purchase_id: id });
  }

  render() {
    return (
      <MainDataContextApi.Provider
        value={{
          main_theme: this.state.theme,
          component_styles: this.state.styles,
          conditional_styles: this.state.conditional_styles,
          // logout: this.logout,
          context_navigationbar: this.state.navigation,
          setNavigationBar: this.setNavigationBar,
          setUser: this.setUser,
          setToken: this.setToken,
          context_user: this.state.user,
          context_phone: this.state.user.phone,
          context_token: this.state.token,
          context_color: this.state.colors,
          setNavItemActive: this.setNavItemActive,
          context_navItemActive: this.state.navItemActive,
          logo_colors: this.state.logo_colors,
          footer_intersecting: this.state.footer_intersecting,
          setFooterIntesection: this.setFooterIntesection,
          test_data: this.state.test_data,
          setTestData: this.setTestData,
          snack_bar_state: this.state.snack_bar,
          setSnackbarState: this.setSnackbar,
          context_base_list: this.state.base_items,
          cartItems: this.state.cart,
          addCourseToCart: this.addCourseToCart,
          updateCartItem: this.updateCartItem,
          categoryList: this.state.typeCategory,
          setCategoryList: this.setTypeCategory,
          getMyOnlineCourses: this.getMyOnlineCourses,
          getMyCourseDetails: this.getMyCourseDetails,
          toggleLoginCard: this.toggleLoginCard,
          login_open: this.state.loginCard,
          compareCourseItems: this.state.compareCourse,
          setCompareCourseItems: this.setCompareCourse,
          context_purchase_id: this.state.purchase_id,
          setPurchaseId: this.setPurchaseId,
          check_course_type: this.state.checkCourseType,
          check_panel_type: this.state.checkPanelType,
          setCheckCourseType: this.setCheckCourseType,
          setCheckPanelType: this.setCheckPanelType,
          course_data: this.state.my_course_content,
        }}
      >
        {this.props.children}
      </MainDataContextApi.Provider>
    );
  }
}

export default MainDataContextProvider;
