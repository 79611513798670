import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";

export default function PlacesAutocomplete({ locationHandler }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value) {
      geocodeByAddress(value.value.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => locationHandler(lat, lng));
    }
  }, [value]);

  return (
    <div style={{ margin: "15px 20px 0" }}>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyD0Wr0DoO1rKVCFsN2FlBqNYWQ9PQxCX6o"
        autocompletionRequest={{
          bounds: [
            { lat: 50, lng: 50 },
            { lat: 100, lng: 100 },
          ],
          componentRestrictions: {
            country: "in",
          },
        }}
        minLengthAutocomplete={3}
        selectProps={{
          styles: {
            valueContainer: (provided) => ({
              ...provided,
              height: 54,
            })         
          },
          placeholder: 'Location',
          value,
          onChange: setValue,
        }}
      />
    </div>
  );
}
