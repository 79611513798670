import React, { useState } from 'react';
// import SetPasswordCard from '../../Components/SetPasswordCard/SetPasswordCard.component';
// import SetPasswordCard from '../../Components/SetPasswordCard/SetPasswordCard.component';
import './Login.css';
import { api_without_token, setApiToken } from '../../Utils/Network';
import { MainDataContextApi } from '../../ContextApis/MainDataContextApi/MainDataContextApi';
import { withRouter, Link } from 'react-router-dom';
// import AppTextField from '../../Components/AppTextField/AppTextField.component';
import AppTextField from '../../Components/AppTextField/AppTextField.component';
import { TFProps } from '../../Utils/RegisterCommomProps/RegisterCommonProps';
// import SubmitButton from '../../Components/SubmitButton/SubmitButton';
import SubmitButton from '../../Components/SubmitButton/SubmitButton';
import { Dialog, DialogContent } from '@material-ui/core';
import logo from '../../Assets/lcarrologo.png'
import { toggleFullscreen } from 'video-react/lib/actions/player';
import {useLocation} from 'react-router-dom';

const Login = ({ open, onClose = () => { }, ...props }) => {
    const [login, setLogin] = useState({ password: '', username: '' });   // login content state

    const [alerts, setAlerts] = useState({ password: '', username: '' }); // alert content state

    const { setToken, setUser, context_color, login_open, toggleLoginCard } = React.useContext(MainDataContextApi);  //using state and functions of contextapi
    const location = useLocation();

    const handleLogin = event => {
        event.preventDefault();
        const { password, username } = login;  // object destructuring

        if (password !== "" && username !== "") {   // Checking if password and username is not null
            api_without_token.post(`/auth/user/login/`, { password, username })  //posting data
                .then(response => {
                    if(location.pathname == '/forgetpassword' || location.pathname == '/register')
                    {
                        props.history.push('/home');
                    }
                    let { token, user } = response.data.data;
                    const { first_name, last_name, gender_str, avatar, username, id, phone, email, verified } = user
                    setUser({ first_name, last_name, gender_str, avatar, username, id, phone, email, verified });  //setting user in context api
                    setToken(token);
                    localStorage.setItem("myava_context_data", JSON.stringify({ first_name, last_name, gender_str, avatar, username, id, phone, email, verified }))                                                                      //setting token in context api

                    setApiToken(token.access)                                                                      // setting token in api function
                        .then(res => {

                            if (response.status === 200) {                                      //if response statis is 200 close the login and push to "/"
                                toggleLoginCard(false);
                                if (props.location.pathname == '/home/') {
                                    props.history.push('/user/online/myspace');
                                }                                        // pushing to "/" location
                            }

                        })
                        .catch(err => {
                            console.error(err)
                        })


                })
                .catch(error => {
                    console.error("The login error ", error.response.status)

                    if (error.response.status == 403) {                                                        // if error in api call show incorrect username and password
                        setAlerts(prev_alerts => ({
                            password: "Username or password incorrect."
                        }))
                    }
                })


        }
        //set Alerts for password and username
        setAlerts(prev_alerts => ({

            password: password !== "" ? "" : "Enter password",
            username: username !== "" ? "" : "Username required"
        }))

    }
    //handling state changes of inputs
    const handleChange = (value, name) => {

        setLogin(prev_login => ({  //setState for login
            ...prev_login,
            [name]: value
        }))
    }

    return (

        <React.Fragment>
            <Dialog open={login_open} onClose={() => toggleLoginCard(false)}>
                <DialogContent>
                    <div className="login-1">
                        <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', height: '100px', fontWeight: '900', fontSize: '35px', fontStyle: 'italic' }}>
                            <img src={logo} width="200px" />
                        </div>
                        <form onSubmit={e => handleLogin(e)}>
                            <AppTextField alert={alerts.username} value={login.username} onChange={e => handleChange(e.target.value, "username")} {...TFProps({ width: "100%" })} type="number" label="Phone number"></AppTextField>
                            <AppTextField alert={alerts.password} value={login.password} onChange={e => handleChange(e.target.value, "password")} {...TFProps({ width: "100%" })} type="password" label="Password"></AppTextField>
                            <div onClick={e => {
                                toggleLoginCard(false, () => {
                                    props.history.push('/forgetpassword')
                                })
                            }} style={{ padding: '5px 5px 10px 0px ', textAlign: 'right', cursor: 'pointer' }}>Forget password</div>
                            <SubmitButton style={{ boarderRadius: '0px' }} label="Login" />
                        </form>
                        <div style={{ padding: "15px 0px 0px 0px", textAlign: 'center' }}>
                            <Link style={{ color: context_color.secondary }} className="crete-account-lbl" onClick={() => toggleLoginCard(false)} to="/register">Create an account</Link>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

        </React.Fragment>

    )
}

export default withRouter(Login);