import React from "react";
import styles from "./index.module.css";
import LeftRightcomponent from "../../Components/Newdesigncomponets/LeftRightComponent/LeftRightcomponent";
import about1 from "../../Assets/newevopage/aboutus-1.png"
import about2 from "../../Assets/newevopage/aboutus-2.png"
import about3 from "../../Assets/newevopage/aboutus-3.png"
import about4 from "../../Assets/newevopage/aboutus-4.png"
import workIcon6 from "../../Assets/NewHomepageImg/workicon-6.png";
import gicon from "../../Assets/NewHomepageImg/galleryicon1.png";
import psicon1 from "../../Assets/NewHomepageImg/ps-1.png";
import psicon2 from "../../Assets/NewHomepageImg/ps-2.png";

function NewAboutus() {
  return (
    <section className={styles.AboutUs}>
      <h2 className={`${styles.heading} ${styles.alingcenter}`}>About Us</h2>
      <p className={`${styles.newpara} ${styles.alingcenter}`}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </p>
      <LeftRightcomponent  Data ={dummydata} Color="#35648C"/>

      <div className={`${styles.postions} ${styles.bg1}`}>
          <img src={workIcon6} />
        </div>
        <div className={`${styles.postions} ${styles.bg2}`}>
          <img src={gicon} />
        </div>
        <div className={`${styles.postions} ${styles.bg3}`}>
            <img src={psicon1} />
          </div>
          <div className={`${styles.postions} ${styles.bg4}`}>
            <img src={psicon2} />
          </div>
    </section>
  );
}

export default NewAboutus;

const dummydata = [
  {
    id: 1,
    img: about1,
    heading: "Lorem Ipsum",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    id: 2,
    img: about2,
    heading: "Lorem Ipsum",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    id: 3,
    img: about3,
    heading: "Lorem Ipsum",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    id: 4,
    img: about4,
    heading: "Lorem Ipsum",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
];
