import React, {useContext} from 'react'
import './Button.css'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';

import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import DialogBox from '../DialogBox/DialogBox';


 function Button() {
  const {  updateUserType, setDisplayType, setOpenModel} =
  useContext(UserCredsContext);
    let history = useHistory();

    const redirect = (_user, _display) => {
      updateUserType(_user)
      setDisplayType(_display)
      setOpenModel(true)
    }
      
    return (
        <>
            <button className="btn btn-prim" onClick={()=>redirect(8,2)}>Educator <ArrowForwardIosIcon style={{padding: '0px 1px 0px 10px', marginTop: '-3px', width: '18px'}}/></button>
            <button className="btn btn-stud" onClick={()=>redirect(1,2)}>Student <ArrowForwardIosIcon style={{padding: '0px 1px 0px 10px', marginTop: '-3px', width: '18px'}}/></button>
        </>
    )
}
export default Button