import { AirplayOutlined, ArrowBack, ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PopoverPassage from "../../Components/Popover/PopoverPassage";
import styles from "./TestSeries.module.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

function Passage({ setNums, nums, quests,getBack,setGetBack, questlength, passageRedirect, number, setAnswer = [], getNewQuestion = null, setNumber = null, answer, ...props }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let char = ["A", "B", "C", "D", "E"];
  // const [nums, setNums] = useState(0);

  

  useEffect(() => {
    setNums(passageRedirect);
  }, [passageRedirect]);
  
  useEffect(() => {
    if(getBack){
      setNums(quests.questions.length - 1)
    }else{
      setNums(0);
    }
  }, [number]);
  
  useEffect(() => {
    setOpen(true);
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      setOpen(true);
    };
    //   return () => {
    //     if (window.Tawk_API) {
    //       window.Tawk_API.showWidget();
    //     }
    //   };
  }, []);

  const setAns = (ansid, quid) => {
    var index = answer.findIndex((x) => x.question === quid);
    if (index === -1) {
      setAnswer([
        ...answer,
        { question: quid, answer_data: [{ answer_choice: ansid }] },
      ]);
    } else {
      answer[index].question = quid;
      answer[index].answer_data[0].answer_choice = ansid;
      setAnswer([...answer]);
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  console.log(quests.questions.length - 1, "quest")
  // console.log(quests, questlength, number, passageRedirect, "FFFFFFff")
  return (
    <div className={styles.test_section}>
{/* 
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "#c3c3c3",
            borderRadius: "8px",
          }}
        >
          Q {quests?.questions[nums]?.question_no}

        </div>
      </div> */}

      <div
        dangerouslySetInnerHTML={{ __html: quests?.passage?.title }}
        className={styles.quest}
      ></div>
      <div
        dangerouslySetInnerHTML={{ __html: quests?.passage?.passage }}
        className={styles.quest}
      ></div>
      {quests?.passage?.image ? (
        <img
          src={quests?.passage?.image}
          alt="image"
          className={styles.images_test}
        />
      ) : (
        <> </>
      )}
      {/* <div className={styles.questList}>
        {`${nums + 1}/${quests?.questions?.length}`}
      </div> */}

      {quests?.questions.map((val, i) =>
        nums === i ? (
          <div className={styles.test_section}>
            <div style={{ display: "flex" }}>
              {/* <div style={{ padding: '10px', backgroundColor: '#c3c3c3', borderRadius: '8px' }}>Q {number + 1}</div> */}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: val?.title }}
              className={styles.quest}
            ></div>
            <div className={styles.answer_wrapper}>
              {val?.choices?.map((x, idx) => {
                return x.title ? (
                  <>
                    <button
                      onClick={() => setAns(x.id, val.id)}
                      className={`${styles.btn_answers} ${answer.some(
                        (e) =>
                          e.question === val.id &&
                          e.answer_data[0].answer_choice === x.id
                      )
                        ? styles.btn_succes
                        : null
                        }`}
                    >
                      <span className={styles.chars}>{char[idx]}</span>
                      <div
                        dangerouslySetInnerHTML={{ __html: x?.title }}
                        style={{ textAlign: "left" }}
                      ></div>
                    </button>
                  </>
                ) : (
                  <></>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )
      )}

      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        {nums === 0 ? <ArrowBack onClick={() => setNumber(number - 1)} /> : <ArrowBack onClick={() => setNums(nums - 1)} />}

        {quests?.questions?.length - 1 === nums ? (
          <ArrowForward onClick={() => setNumber(number + 1)} />
        ) : (
          <ArrowForward onClick={() => setNums(nums + 1)} />
        )}
      </div> */}
      <div className={styles.test_section}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
          }}
        >
          {nums === 0 ? (
            <button
              onClick={() => {setNumber(number - 1);setGetBack(true)}}
              className={styles.btn_next}
            >
              Back
            </button>
          ) : (
            <button
              onClick={() => setNums(nums - 1)}
              className={styles.btn_next}
            >
              Back
            </button>
          )}

          {(quests?.questions?.length - 1 === nums) ? (
            <>

              {((questlength - 1 === number))

                ? <></> :
                <button
                  onClick={() => {getNewQuestion(number); setGetBack(false)}}
                  className={`${styles.btn_next} ${styles.next_btns}`}
                  style={{ marginRight: "0px" }}
                >
                  Next
                </button>}</>
          ) : (
            <button
              onClick={() => {setNums(nums + 1); setGetBack(false)}}
              className={`${styles.btn_next} ${styles.next_btns}`}
              style={{ marginRight: "0px" }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Passage;
