export const getCourseLowestPriceObj = (validity = []) => {
  let priceObj = {
    discount: "0%",
    price: 0,
    final_price: 0,
  };
  if (validity.length) {
    if (validity.length > 1) {
      validity.sort((a, b) => {
        if (a.final_price && b.final_price) {
          return a.final_price - b.final_price;
        }
        return a.price - b.price;
      });
    }
    if (validity[0].price || validity[0].final_price) {
      priceObj = {
        discount:
          validity[0].discount_type === 1
            ? validity[0].discount_value + "%"
            : "₹" + validity[0].discount_value,
        final_price: validity[0].final_price ? validity[0].final_price : validity[0].price,
        price:
          !validity[0].final_price || validity[0].price === validity[0].final_price
            ? 0
            : validity[0].price,
      };
    }
  }
  return priceObj;
};
