import React, { useCallback, useState } from "react";
import { debounce } from "lodash";

import "./index.css";
import { Slider } from "@material-ui/core";

const RangeSlider = ({ onChange, filterObj }) => {
  const [minVal, setMinVal] = useState(0)
  const [maxVal, setMaxVal] = useState(50000)

  const priceRangeHandler = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setMinVal(Math.min(newValue[0], newValue[1] - 100))
    setMaxVal(Math.max(newValue[1], newValue[0] + 100))
    handler(minVal, maxVal, filterObj)
  };

  const handler = useCallback(debounce((mn, mx, fltrObj) =>
    onChange({ min: mn, max: mx, obj: fltrObj }), 2000), []);

  return (
    <div className='price-slider'>
      <span style={{ fontSize: 20, fontWeight: "normal" }}>Price</span>
      <div style={{ margin: "5px 10px" }}>
        <Slider
          value={[minVal, maxVal]}
          onChange={priceRangeHandler}
          min={0}
          max={50000}
          style={{ color: "#133268" }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="min-value">{minVal}</div>
        <div className="max-value">{maxVal}</div>
      </div>
    </div>
  );
};

export default RangeSlider;
