import React, { useState, useEffect } from "react";
import headImg from "../../Assets/1x/Asset 1.png";
import backImg from "../../Assets/1x/Asset 3 (1).svg";
import floatImg from "../../Assets/1x/Asset 2.png";
import logo1 from "../../Assets/1x/Asset 4.png";
import logo2 from "../../Assets/1x/Asset 5.png";
import logo3 from "../../Assets/1x/Asset 6.png";
import logo4 from "../../Assets/1x/Asset 7.png";
import image8 from "../../Assets/1x/Asset 8.png";
import last from "../../Assets/1x/Asset 12.png";
import download from "../../Assets/1x/Asset 13.png";
import line from "../../Assets/1x/Lighyt Line.png";
import green from "../../Assets/1x/Asset green.png";
import down from "../../Assets/1x/Down.png";
import applestore from "../../Assets/hOME pAGE/ASSETS/1x/Asset 27.png";
import googlestore from "../../Assets/hOME pAGE/ASSETS/1x/Asset 28.png";

import Accordion from "@material-ui/core/Accordion";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../Components/Button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { api_call, base_url } from "../../Utils/Network";
import "./About.css";

const useStyles = makeStyles({
  quest: {
    color: "#ee9435",
    fontSize: "23px",
    // fontWeight: "600",
  },
  quesDetail: {
    color: "#ee9435",
    fontSize: "16px",
    fontWeight: "500",
  },
  answerDetail: {
    color: "#6E7797",
    fontSize: "16px",
    fontWeight: "500",
  },
  typo: {
    borderLeft: "2px solid #F2AF68",
    backgroundColor: "#F7FAFF",
  },
  margins: {
    marginBottom: "10px",
  },
  answer: {
    borderLeft: "2px solid #6E7797",
  },
  ansTag: {
    color: "#6E7797",
    fontSize: "22px",
    marginRight: "10px",
  },
});
function About() {
  const [AboutUs, setAboutUsData] = useState([]);
  const [abutBanner, setAbutBanner] = useState([]);
  const [findout, setFindOut] = useState([]);
  const [faq, setFAQ] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDatas();
  }, []);

  const getDatas = () => {
    api_call
      .get("/online/about_us/")
      .then((response) => {
        console.log(response.data.data, "About Us");
        const [AbutBanners, Findout, FAQ] = response.data.data;
        setAboutUsData(response.data.data);
        setAbutBanner(AbutBanners);
        setFindOut(Findout);
        setFAQ(FAQ);
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };
  console.log("about", findout);
  const classes = useStyles();
  return (
    <div className="mains">
      <div className="title-content">
        <div className="head-main">
          <img src={headImg} alt="Head" className="head-img"></img>

          {abutBanner &&
            abutBanner.length !== 0 &&
            abutBanner.data.map((item, k) => {
              return (
                <div className="disp_head">
                  <h1 className="head-text">
                    {item.title.replace(/<[^>]+>/g, "")}
                  </h1>
                  <p
                    className="title-para"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></p>
                </div>
              );
            })}
        </div>
        <div className="zik-zak">
          <div className="btn-clss">
            <Button />
            {/* <button className="btn btn-prim">Educator &gt;</button>
                        <button className="btn btn-stud">Student &gt;</button> */}
          </div>
          <div className="floats">
            <img src={floatImg} alt="float" style={{ width: "100%" }}></img>
          </div>
          <img src={down} alt="down" className="downs"></img>
        </div>
        <div className="flex-cls">
          <div className="col-sm col-sm-one">
            <div>
              <h3 className="cont-head">
                Find out more about our Customised Learning Experiece
              </h3>
              <p className="cont-para">
                Our unparalleled content aggregation provides complete freedom
                at the fingertips of our learners, inducted across almost all
                Schools, Boards, Foundation courses, Entrance exams, Graduation
                and Post Graduation courses, Competitive Exams and Government
                Examinations.
              </p>
            </div>
            <div>
              <img src={image8} alt="logo3" className="img-col-first"></img>
            </div>
          </div>
          <div className="col-sm wraps">
            {findout &&
              findout.length !== 0 &&
              findout.data.map((ind, ix) => {
                return (
                  <div className="cards-abs">
                    <div className="small-image sm-image">
                      <img src={ind.file} alt="logo3" className="circles"></img>
                    </div>

                    <h4 className="cardhead">{ind.title}</h4>
                    <p className="cardpara">
                      {ind.description.replace(/<[^>]+>/g, "")}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="sub-footer">
          <div className="sub-foot-head">
            <h1>Our Values</h1>
            <p>Every Student benefits from our 3 step e-learning Approach</p>
          </div>
          <div className="lines"></div>
          <div className="sb-foot">
            <ul className="dashed">
              <li>Authenticity in Content & Quality checks</li>
              <li>Autonomy on the students fingertips</li>
              <li>Embracing differences</li>
            </ul>
          </div>
          <div className="sb-foot">
            <ul className="dashed">
              <li>Democracy in Education</li>
              <li>Lead with Optimism</li>
              <li>Accountability</li>
            </ul>
          </div>
          <div className="sb-foot">
            <ul className="dashed">
              <li>Commitment to students & partners</li>
              <li>Invigorating Passion in Educators</li>
              <li>Minimizing waste</li>
              <li>Seek Perpetual Growth</li>
            </ul>
          </div>
        </div>

        <div className="faq-container">
          <div className="faq-class">
            <h1>Frequently Asked Questions (FAQ)</h1>
          </div>
          <div className="faq-sub">
            {faq &&
              faq.length !== 0 &&
              faq.data.map((item, k) => {
                return (
                  <Accordion className={classes.margins}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={classes.typo}
                    >
                      <Typography>
                        <span className={classes.quest}>Q.</span>{" "}
                        <span className={classes.quesDetail}>
                          {item.question}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.answer}>
                      <Typography>
                        <span className={classes.ansTag}>A.</span>
                        <span className={classes.answerDetail}>
                          {item.answer}
                        </span>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
          {/* <button className="faq-btn">AND MANY MORE</button> */}
        </div>
        <div className="last-content">
          <div className="playstore-box">
            <img src={applestore} alt="Store-image"></img>
            <img src={googlestore} alt="Store-image"></img>
          </div>
          <img src={last} alt="last-img" className="last-image"></img>
        </div>

        {/* <div style={{ position: "absolute", top: "0", zIndex: "-1" }}> */}

        {/* </div> */}
      </div>
      {/* <img src={backImg} alt="Background" className="bg_image"></img> */}
    </div>
  );
}

export default About;
