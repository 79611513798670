import React, { useState, useEffect, useContext } from "react";
import Carousel from "react-multi-carousel";
// import TopBackground from "../../Assets/hOME pAGE/ASSETS/1x/BG/Asset 20.png";
import TopBackground from "../../Assets/hOME pAGE/ASSETS/1x/Asset 1.png";
import Girl from "../../Assets/hOME pAGE/ASSETS/1x/Asset 5.png";
import Store from "../../Assets/hOME pAGE/ASSETS/1x/Asset 26.png";
import applestore from "../../Assets/hOME pAGE/ASSETS/1x/Asset 27.png";
import googlestore from "../../Assets/hOME pAGE/ASSETS/1x/Asset 28.png";

import learn from "../../Assets/hOME pAGE/ASSETS/1x/Asset 8.png";
import practice from "../../Assets/hOME pAGE/ASSETS/1x/Asset 9.png";
import analyse from "../../Assets/hOME pAGE/ASSETS/1x/Asset 10.png";
import teacher from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 33.svg";
import growth from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 30.svg";
import batch from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 31.svg";
import progress from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 29.svg";
import star from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 32.svg";
import introduce from "../../Assets/hOME pAGE/ASSETS/1x/Asset 6.png";
import edun from "../../Assets/hOME pAGE/ASSETS/1x/Asset 2.png";
import dimond from "../../Assets/hOME pAGE/ASSETS/1x/BG/Asset 21.png";
import mobile from "../../Assets/hOME pAGE/ASSETS/1x/Asset 11.png";
import phone from "../../Assets/hOME pAGE/ASSETS/1x/Asset 12.png";
import mblies from "../../Assets/hOME pAGE/ASSETS/1x/Asset 13.png";
import curve from "../../Assets/hOME pAGE/ASSETS/1x/BG/Asset 7.png";
import last from "../../Assets/hOME pAGE/ASSETS/1x/Asset 16.png";
import download from "../../Assets/hOME pAGE/ASSETS/1x/Asset 17.png";
import verticalline from "../../Assets/hOME pAGE/ASSETS/1x/Asset 3.png";
import InLine from "../../Assets/hOME pAGE/ASSETS/1x/Asset 4 1.png";
import round from "../../Assets/hOME pAGE/ASSETS/1x/Asset 29.png";
import IIT from "../../Assets/1x/IIT.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "react-multi-carousel/lib/styles.css";
import "./home-page.css";
import { Link } from "react-router-dom";
import { api_call, base_url } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import Button from "../../Components/Button/Button";
import Cards from "../../Components/Cards/Cards";
import Skeleton from "@material-ui/lab/Skeleton";
import TakeTest from "../TakeTest/TakeTest";
import { useHistory } from "react-router-dom";

function Home() {
  const history = useHistory();
  const { setDisplayType, setOpenModel, setFilter, user } =
    useContext(UserCredsContext);
  const [loading, setLoading] = useState(true);
  const [homeData, setHomeData] = useState([]);
  const [banners, setBanner] = useState([]);
  const [approach, setAproach] = useState([]);
  const [partners, setPartners] = useState([]);
  const [courses, setCourses] = useState([]);
  const [testType, setTestType] = useState(null);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.2,
    },
  };

  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [typeParams, setTypeParams] = useState(1);
  const setCourseParams = (_params) => {
    getData(_params.key);
    setTypeParams(_params.key);
    setFilter({
      type: _params.type,
      value: _params.value,
    });
  };
  const [coursesType, setCoursesType] = useState([
    {
      title: "All",
      id: 1,
      key: 0,
      value: 0,
      type: "all",
    },
    {
      title: "Live",
      id: 2,
      key: 1,
      value: 1,
      type: "session",
    },
    {
      title: "Courses",
      id: 3,
      key: 3,
      value: 6,
      type: "session",
    },
    {
      title: "In Person",
      id: 4,
      key: 2,
      value: 2,
      type: "session",
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();

    fundamental();
  }, [user.user_type === 1]);

  const fundamental = () => {
    if (user.user_type === 1 && testType === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    }
  };
  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const getData = (prms = 0) => {
    let api_params = "";
    if (prms) {
      api_params = `?session_type=${prms}`;
    }
    api_call
      .get(`/online/home/${api_params}`)
      .then((response) => {
        const [Banner, Approach, course, partner] = response.data.data;
        setHomeData(response.data.data);
        setLoading(false);
        setBanner(Banner);
        setAproach(Approach);
        setPartners(partner);
        setCourses(course);
      })
      .catch((error) => {
        console.log(error.response, "Error occurred");
      });
  };
  console.log(courses, "Courses");
  return (
    <div>
      <div className="showcontainer">
        {loading ? (
          <div className="Showcase" style={{ textAlign: "center" }}>
            <div style={{ width: "50%" }}>
              <Skeleton height={200} width={300} />
              <Skeleton height={50} width={"80%"} />
              <Skeleton height={50} width={"100%"} />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Skeleton height={50} width={"40%"} />
                <Skeleton height={50} width={"40%"} />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Skeleton height={50} width={"40%"} />
                <Skeleton height={50} width={"40%"} />
              </div>
            </div>
            <div style={{ width: "30%" }}>
              <Skeleton height={350} width={350} variant="circle" />
            </div>
          </div>
        ) : (
          <>
            {banners.length !== 0 &&
              banners.data.map((item, id) => {
                return (
                  <div className="Showcase">
                    <div className="showBox_one">
                      <h1 className="showBox_heading">
                        {item.title.replace(/<[^>]+>/g, "")}
                      </h1>
                      <div
                        className="showBox_content"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                      <div className="btn-class">
                        <Button />
                      </div>
                      <div className="store">
                        <a
                          href="https://apps.apple.com/in/app/lcarro/id1563402107"
                          target="_blank"
                        >
                          <img src={applestore} alt="Store-image"></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.lcarro"
                          target="_blank"
                        >
                          <img src={googlestore} alt="Store-image"></img>
                        </a>
                      </div>
                    </div>

                    <div className="girlBox">
                      <img
                        src={item.file}
                        alt="Showcase-image"
                        className="showgirl"
                      ></img>
                    </div>
                    <img
                      src={TopBackground}
                      alt="TopBackground"
                      className="topback"
                    ></img>
                  </div>
                );
              })}
          </>
        )}
      </div>
      <div className="IIT_main">
        <img src={IIT} alt="IIT" className="iit_img"></img>
      </div>
      <div className="Our-main">
        <div className="Our-appro">
          <div className="sub-appr">
            <h1 className="sub-appr-head">Our Approach</h1>
            <p className="sbox-join">
              Every Student benefits from our 3 step e-learning approach
            </p>
          </div>
          <div className="line">
            {/* <img src={verticalline} alt="vertical-line"></img> */}
            {/* data.sort(function (a,b){
                return a.id - b.id;
              }) */}
          </div>
          <div className={"sb_list_main " + (isActive ? "height_auto" : "")}>
            {loading ? (
              [1, 2, 3].map((v, i) => (
                <div key={i}>
                  <Skeleton variant="circle" width={70} height={70} />
                  <br></br>
                  <br></br>
                  <Skeleton variant="text" width={80} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                </div>
              ))
            ) : (
              <>
                {approach &&
                  approach.length !== 0 &&
                  approach.data.map((content, i) => {
                    return (
                      <div className="sb-appr ">
                        <img src={content.file} alt="learn"></img>
                        <p>{content.title}</p>

                        <ul className="dashs">
                          <li
                            dangerouslySetInnerHTML={{
                              __html: content.desc1.replace(/<[^>]+>/g, ""),
                            }}
                          ></li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html: content.desc2.replace(/<[^>]+>/g, ""),
                            }}
                          ></li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html: content.desc3.replace(/<[^>]+>/g, ""),
                            }}
                          ></li>

                          {/* <li> {content.desc2.replace(/<[^>]+>/g, "")}</li>
                      <li> {content.desc3.replace(/<[^>]+>/g, "")}</li> */}
                        </ul>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
        {/* <button
          className={
            "btn-addmore" +
            (approach.length !== 0 && approach.data.length > 3
              ? " show"
              : " hide")
          }
          onClick={toggleClass}
        >
          AND MANY MORE
          <ArrowForwardIosIcon style={{ padding: "0px 7px 2px" }} />
        </button> */}
      </div>

      <div className="cls-list">
        <img src={round} alt="round" className="rounded_cs"></img>
        <div className="sb-cls-head">
          <h1>Explore Courses</h1>
        </div>
        <div className="cls-btns">
          {coursesType.map((item, index) => {
            return (
              <button
                className="sb-cls-btn"
                onClick={() => setCourseParams(item)}
              >
                {item.title}
              </button>
            );
          })}

          <div className="views">
            <Link to="/explore" className="view-exp">
              View All
            </Link>
          </div>
        </div>
        <div className="card-class">
          {" "}
          {/*cls-card*/}
          <Carousel responsive={responsive}>
            {courses &&
              courses.length !== 0 &&
              courses.data.map((item, index) => {
                return <Cards name={item} />;
              })}
          </Carousel>
          {/* <div>
            <Carousel responsive={responsive}>
              <div className="ITEM">Item 1</div>
              <div className="ITEM">Item 2</div>
              <div className="ITEM">Item 3</div>
              <div className="ITEM">Item 4</div>
            </Carousel>
          </div> */}
        </div>
      </div>

      <div className="intro-container">
        <div className="intro-img">
          <img src={introduce} alt="introduce" className="img-intro"></img>
        </div>
        <div className="intro-line"></div>
        <div className="intro">
          <h1>DISCOVER YOURSELF</h1>
          <p>
            Your intelligence is an unique as you, to find out your strengths
            and areas of interests take our test today
          </p>
          <div>
            <button
              onClick={() => {
                redirects();
              }}
              style={{ cursor: "pointer" }}
            >
              TAKE THE TEST
            </button>
          </div>
        </div>
      </div>

      <div className="edun">
        <div className="edun-title">
          <h1>Our Partners</h1>
        </div>

        <div className="edun-sub">
          {partners.length !== 0 &&
            partners.data.map((item, id) => {
              return (
                <div className="edun-child">
                  <div className="edun_child_img">
                    <img
                      src={`${item.icon}`}
                      alt="edun"
                      className="edun-img"
                      key={id}
                    ></img>
                  </div>

                  <p className="edun-text">{item.title}</p>
                </div>
              );
            })}
        </div>
      </div>

      {/* <div className="lcr-temp">
        <div className="lcr-content">
          <h2>Get Started</h2>

          <div className="mbls-div">
            <div className="mobone mobss">
              <img src={mblies} alt="mobile1" className="mob"></img>
              <button className="mob-btn">Register</button>
            </div>
            <div className="mobtwo mobss">
              <img src={phone} alt="phone" className="mob"></img>
              <button className="mob-btn">Select a course</button>
            </div>
            <div className="mobthree mobss">
              <img src={mobile} alt="mobile2" className="mob"></img>
              <button className="mob-btn">Learn</button>
            </div>
          </div>
        </div>
        <img src={dimond} alt="dimond" className="mbl-background"></img>
        {/* <div className="">
          
        </div> 
        <div className="curve-line">
          <img src={curve} alt="curve" className="curve"></img>
        </div>
      </div> */}

      <div className="last-content">
        <div className="playstore-box">
          <img src={applestore} alt="Store-image"></img>
          <img src={googlestore} alt="Store-image"></img>
        </div>
        <img src={last} alt="last-img" className="last-image"></img>
      </div>
    </div>
  );
}

export default Home;
