import React, { useState, useEffect , useContext} from 'react';
import { MainDataContextApi } from '../../ContextApis/MainDataContextApi/MainDataContextApi';
// import HmNavBar from '../HomepageSlimNav/navComp'
import './PurchasePage.css';
import img1 from '../../Assets/courseImg.jpg';
import cancels from '../../Assets/cart/cancel.svg';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import { api_call } from '../../Utils/Network';
import mvayalogo from '../../Assets/mvaya-logo.png';
// import LoginCard from '../../Components/LoginCard/LoginCard.js';
import LoginCard from '../../Components/LoginCard/LoginCard';
import lcarro from '../../Assets/brainhap/logosmall.png';
import BUTTON3 from '../../Assets/homepage/BUTTON3.png';
import backArrow from '../../Assets/back.svg';

import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function loadScript(src) {
    return new Promise(resolve => {
        const script = document.createElement('script');
        console.log(script, 'script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'
        // script.onload = display.Razorpay;
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false);
        }
        document.body.appendChild(script)
    })
}


function PurchasePage(props) {
    const { courseDetail } = useContext(UserCredsContext);
    const { setNavigationBar, context_user, context_purchase_id, context_token, logout, cartItems, login_open, toggleLoginCard, addCourseToCart, user } = React.useContext(MainDataContextApi);
    console.log(user, 'useruseruser')
    const [coupon, setCoupon] = useState('');
    const [courseList, setCourseList] = useState();
    const [couresData, setCourseData] = useState();
    const [courseName, setCourseName] = useState('');
    const [coursePrice, setCoursePrice] = useState('');
    const [coursePriceFix, setCoursePriceFix] = useState('');
    const [mainCourseId, setMainCourseId] = useState();
    const [discount, setDiscount] = useState(0);
    const [courseId, setCourseId] = useState();
    const [counter, setCounter] = useState(true);
    const [success, setSuccess] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [changeUser, setChangeUser] = useState(false);

    const [orderId, setOrderId] = useState('');
    useEffect(() => {
        var name = localStorage.getItem('courseName');
        var price = localStorage.getItem('crousePrice');
        var id = localStorage.getItem('courseid');

        console.log(name, 'pricepriceprice')
        setCourseName(name);
        // setCoursePrice(price);
        // setCoursePriceFix(price);
        // setCourseList([{ coursename: name, price: price }])
        setCourseId(id);

    }, []);
    console.log(cartItems, 'cartItems')
    console.log(courseDetail,'mycoursedetailsfromcontext')
    const [showPurchase, setShowPurchase] = useState(false);
    useEffect(() => {
        if (cartItems.length !== 0) {
            setCourseList(cartItems);
            var id = 0;
            var data = []
            if (context_purchase_id !== null) {
                var course_data = cartItems.filter(x => x.id == context_purchase_id);
                data = course_data
                console.log(cartItems, data, 'courseitemcourseitem')
            } else {
                data = cartItems[0].validity;
            }
            setCourseData(data)
            console.log(cartItems[0].price, data, 'course item online')
            if (cartItems[0].validity.length === 0) {
                setCoursePrice(data[0].price);
                if (data[0].price == 0) {
                    setShowPurchase(false);
                } else {
                    setShowPurchase(true);
                }
            } else {
                setCoursePrice(data[0].validity[0].discounted_price);
                setCoursePriceFix(data[0].validity[0].final_price);
                if (data[0].validity[0].final_price == 0) {
                    setShowPurchase(false);
                } else {
                    setShowPurchase(true);
                }
            }
            setMainCourseId(cartItems[0].id)
        }
    }, [cartItems])

    useEffect(() => {
        // setIsLoggedIn(context_user.verified);
        console.log(context_user.verified,'context_user.verified')
        if(context_user.verified == false){
            setShowPurchase(true);
            setIsLoggedIn(true)
        }else{
            setShowPurchase(true);
            setIsLoggedIn(true)

        }
    }, [context_user])
console.log(showPurchase,'showPurchaseshowPurchase')

    async function displayRazorPay(dataId, amountData, apiCourseidData) {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        console.log(dataId, 'data id passed')
        if (!res) {
            alert('razorpay sdk failed to load. Are you online?')
            return
        }
        var courseprice = amountData;
        console.log(courseprice, 'coursepricecourseprice')
        const options = {
            "key": 'rzp_test_TW6h2zNAZqVdu1',
            "amount": courseprice,
            "currency": "INR",
            "name": "Brainhap",
            "description": "Course payment",
            "image": lcarro,
            "order_id": `${dataId}`,
            "handler": function (response) {
                console.log(response.razorpay_payment_id, 'hello');
                console.log(response.razorpay_order_id, 'hello1');
                console.log(response.razorpay_signature, 'hello2');
                setPaymentId(response.razorpay_payment_id);
                var intValue = parseInt(courseId)
                getpurchase(intValue, response.razorpay_payment_id, apiCourseidData);
            },
            "prefill": {
                "name": `${context_user.first_name}`,
                "email": `${context_user.email}`,
                "contact": `${context_user.phone}`
            },
        };

        var paymentObject = new window.Razorpay(options);
        paymentObject.open()
    }

    const getpurchase = (intValue, rpid, course_id) => {
        var data = {
            "transaction_id": rpid,
            "course": mainCourseId,
            "validity_id": couresData[0].validity[0].id,
            order_id: course_id,
        }
        console.log(data, intValue, 'value to pass in purchase')
        api_call.post(`/purchase/course/`, data)
            .then(response => {
                console.log("The response for purchase course is ", response.data.data);
                setSuccess(true);
            })

    }
    //rzp_test_TW6h2zNAZqVdu1 key old
    //live key rzp_live_lDScQvs1epmNca
    const applyDiscount = () => {
        if (coupon == '') {
            alert('please enter coupon code')
        } else if (coupon == 'FLAT20%') {
            if (counter == true) {
                var savePrice = coursePrice;
                var actualPrice = coursePrice;
                actualPrice = actualPrice * 20;
                actualPrice = actualPrice / 100;
                setDiscount(actualPrice);
                setCoursePrice(savePrice - actualPrice);
                setCounter(false)
            } else {
                alert('code already applied')
            }
        } else if (coupon !== 'FLAT20%') {
            alert('please enter valid coupon code');
        }

    }

    console.log(couresData, context_user, 'couresData')

    function showSummary() {
        return (
            <div className="p-c-l-w">
                {couresData !== undefined && couresData.map((courseitem, item) =>
                    <div className="purchase-o-l-c-c">
                        <div className="purchase-o-l-c">
                            <img src={img1} style={{ width: '100%' }} />
                            <div className="course-title-purchase">
                                <p>Course Name</p>
                                <p>{courseitem.title}</p>
                                {cartItems.length != 0 && <p style={{ color: 'grey' }}>Course Provider: {cartItems[item].institute.name}</p>}

                                <p>Price<span> : {courseitem.validity[0].discounted_price == null ? (courseitem.validity[0].final_price) : (courseitem.validity[0].discounted_price)}</span></p>
                            </div>
                            <div className="course-price-g">
                                {/* <p>Price<span> : {courseitem.price}</span></p> */}
                            </div>
                            <div className="remove-p-o">
                                {/* <img onClick={removeCourse} src={cancels} style={{ width: '20px' }} /> */}
                            </div>
                        </div>
                        <hr className="purchase-" />
                    </div>
                )}
                {couresData == undefined && <p>No Course Added</p>}
            </div>
        )
    }

    const removeCourse = () => {
        setCourseList([]);
        setCoursePrice(0);
        setDiscount(0);
    }
    console.log(coursePrice, 'coursePrice')

    const getOrderId = () => {
        var intValue = parseInt(courseId);
        // var data = {
        //     "amount": coursePrice + '00',
        // }
        // api_call.post(`/online/courses/${intValue}/create_order/`, data)
        //     .then(response => {
        //         console.log("order details", response.data.data);
        //         const { id } = response.data.data;
        //         setOrderId(id);
        //         displayRazorPay(id)
        //     })
        var data = {
            "validity_id": couresData[0].validity[0].id,
        }
        api_call.post(`/purchase/order/`, data)
            .then(response => {
                console.log("order details from api", response.data.data);
                const { id, order_id, amount } = response.data.data;
                setOrderId(id);
                displayRazorPay(order_id, amount, id);
                // setCoursePrice(amount);
            })
            .catch(error => {
                alert('Please login to purchase')
                console.error(error);
            })
    }

    return (
        <div>
            <div className="back-b-cart-c" style={{margin: '15vh 5vh 1vh 5vh' }}>
                <Link to='/explore/online/'> <img src={backArrow} style={{ width: '25px' }} /> </Link>
                <p>Back to Courses</p>
            </div>
            {success == false && <div className="purchase-container">
                <p className="purchase-heading">Purchase Course</p>
                <div className="flip-box-c">
                    <div className="cart-page-leftdiv" style={{ width: '70%' }}>
                        <div className="purchase-box">
                            <div className="cart-card-co-t">
                                <div>
                                    <p className="cart-card-co-t-01">1</p>
                                </div>
                                <div>
                                    <p className="cart-card-co-t-02">Login</p>
                                </div>
                                <div>
                                    <div style={{ float: 'right' }}>
                                        {isLoggedIn && !changeUser && <p className="cart-card-co-t-03" onClick={() => setChangeUser(!changeUser)}>Change</p>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                {!isLoggedIn && <LoginCard />}
                                {isLoggedIn && !changeUser && <p>{context_user.first_name} {context_user.last_name}</p>}
                                {isLoggedIn && changeUser &&
                                    <div>
                                        <p><span className="title-desc-cart">Name </span>{context_user.first_name} {context_user.last_name}</p>
                                        <p><span className="title-desc-cart">Phone </span>{context_user.phone}</p>
                                        <p className="cart-change-user" onClick={e => logout('cart')}>Logout & Sign in to another account</p>
                                        <p className="checkout-button-cart-b" onClick={() => setChangeUser(!changeUser)} style={{ backgroundImage: `url(${BUTTON3})`, backgroundSize: '100% 100%' }}>CONTINUE CHECKOUT</p>
                                    </div>
                                }
                                {/* toggleLoginCard(true) */}

                            </div>
                        </div>
                        <div className="purchase-box">
                            <div className="cart-card-co-t">
                                <div>
                                    <p className="cart-card-co-t-01">2</p>
                                </div>
                                <div>
                                    <p className="cart-card-co-t-02">Purchase Summary</p>
                                </div>
                                <div>
                                    <div style={{ float: 'right' }}>
                                        {/* <p className="cart-card-co-t-03">Change</p> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                {showSummary()}
                            </div>
                        </div>
                        <div className="purchase-box">
                            <div className="cart-card-co-t">
                                <div>
                                    <p className="cart-card-co-t-01">3</p>
                                </div>
                                <div>
                                    <p className="cart-card-co-t-02">Get Offers</p>
                                </div>
                                <div>
                                    <div style={{ float: 'right' }}>
                                        {/* <p className="cart-card-co-t-03">Change</p> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>

                            </div>
                        </div>
                    </div>
                    <div className="cart-page-rightdiv" style={{ width: '25%' }}>
                        <div className="purchase-amount-r">
                            <div className="purchase-amount-r-c">
                                <p style={{ color: 'grey', fontWeight: '900', borderBottom: '1px solid #d0d0d0', marginBottom: '5px', paddingBottom: '5px' }}>PRICE DETAILS</p>
                                <div className="amount-stack-c">
                                    <p className="price-stack-i">Sub Total</p>
                                    <p className="price-stack-s">{coursePriceFix}</p>

                                    <p className="price-stack-i">Discount</p>
                                    {/* <p className="price-stack-s">{coursePriceFix - coursePrice}</p> */}
                                    <p className="price-stack-s">0</p>

                                </div>
                                <div className="amount-stack-c-b">
                                    <p style={{ fontWeight: '900', fontSize: '20px' }}>Total</p>
                                    <p className="price-stack-s" style={{ fontSize: '20px' }}>{coursePrice}</p>
                                </div>
                                <div className="purchase-button-c">
                                    {couresData == undefined ?
                                        (<Button variant="contained" disabled={true} onClick={getOrderId}>Purchase</Button>) :
                                        (<Button variant="contained" disabled={!showPurchase} onClick={getOrderId}>Purchase</Button>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="purchase-amount">
                    <div className="coupon-input">

                    </div>
                    {/* <div className="back-button-p">
                        <Link to='/explore/online/'> <Button variant="contained">Get back to courses</Button></Link>
                    </div> */}
                </div>
                <div className="purchase-amount">
                    <div className="purchase-amount-l">
                        {/* <p style={{ display: 'inline-block', borderBottom: '2px solid #EF9022', fontSize: '20px', paddingBottom: '8px' }}>Apply Coupon</p>
                        <div>
                            <div className="coupon-list-purchase" style={{ marginBottom: '30px' }}>
                                <p>- Apply Code <span style={{ background: '#EF9022', color: 'white', padding: '4px' }}>FLAT20%</span> to get 20% Discount</p>
                            </div>
                            <TextField
                                required
                                id="outlined-required"
                                placeholder="Enter Code"
                                disabled={!isLoggedIn}
                                name="coupon"
                                style={{ width: 'auto%', background: 'white', marginBottom: '20px', marginRight: '10px' }}
                                value={coupon}
                                onChange={e => setCoupon(e.target.value)}
                            /> <Button variant="contained" size="medium" onClick={applyDiscount} disabled={!isLoggedIn}>Apply</Button>
                        </div> */}
                    </div>
                    {/* <div className="purchase-amount-r">
                        <div className="purchase-amount-r-c">
                            <div className="amount-stack-c">
                                <p className="price-stack-i">Sub Total</p>
                                <p className="price-stack-s">{coursePriceFix}</p>

                                <p className="price-stack-i">Discount</p>
                                <p className="price-stack-s">{discount}</p>
                            </div>
                            <div className="amount-stack-c-b">
                                <p style={{ fontWeight: '900', fontSize: '20px' }}>Total</p>
                                <p className="price-stack-s" style={{ fontSize: '20px' }}>{coursePrice}</p>
                            </div>
                            <div className="purchase-button-c">
                                <Button variant="contained" disabled={!isLoggedIn} onClick={getOrderId}>Purchase</Button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>}
            {success == true && <div className="success-display">
                <div className="success-div-c">
                    <h3> Payment Successfull</h3>
                    <p>Your payment id is ({paymentId})</p>
                    <Link to="/user/online/myspace"><p className="payment-success-button">Start Learning Now!</p></Link>
                </div>
            </div>}
        </div>
    )
}


export default PurchasePage;




const courseLists = [{
    coursename: 'CAT Concept Strengthening Video Pack',
    price: '200'
}]
