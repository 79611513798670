import React, { useEffect, useState } from "react";
import styles from "./test.module.css";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LzCircularProgress from "../../Components/Lzcomponent/LzCircularProgress";
import LzProgress from "../../Components/Lzcomponent/LzProgress";
import Unanswer from "../../Assets/unattm.png";
import Correct from "../../Assets/correct.png";
import Incorrect from "../../Assets/incorrect.png";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import backArrowBtn from '../../Assets/backArrow.png'
import LzQuestion from "../../Components/Lzcomponent/LzQuestion/LzQuestion";
import { event } from "react-ga";
import LzChapterwiseResult from "../../Components/Lzcomponent/LzChapterwiseResult/LzChapterwiseResult";
import { api_call_token } from "../../Utils/Network";
// import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    marginTop: "85px",
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: "#fff",
  },
  muiFles: {
    flexDirection: "column",
  },
  appBar: {
    backgroundColor: "#fff", // White background color
    color: "#000", // Black text color
    boxShadow:"none",
  },
  tab: {
    "&.Mui-selected": {
      color: "#EB8413", // Primary color when tab is active
    },
    ".PrivateTabIndicator-colorPrimary-24":{
      backgroundColor:"none !important",
    },
  },
}));

function TestResult() {
  const [value, setValue] = useState(0);
  const [chapertSelected, setChapterSelected] = useState(1);
  const [chapterData, setChapterData] = useState(1);
  const [resultData, setResultData] = useState();
  const [queResultData, setQueResultData] = useState();
  const [quewiseBreakdown, setQuewiseBreakdown] = useState();
  const classes = useStyles(); // Use the custom styles

  const { id,courseId } = useParams();

  const history = useHistory();
  const handleBackClick = () => {
    history.push(`/my-space/1/${courseId}/mcq`);
    // onTakeTest(v);
  };

  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("user_creds_context"));
    getResult(userdetails?.user?.id);
  }, []);

  // console.log(userDetail,"getuserDetails");
  // console.log(id, 'resultPagerbd');

  const getResult = (userId) => {
    api_call_token
      .get(`content/overoll/result/?user=${userId}&test_id=${id}`)
      .then((res) => {
        setResultData(res?.data?.data?.question_status[0]);
        setQueResultData(res?.data?.data?.aggregated_data[0]);
        console.log(res?.data?.data?.aggregated_data[0], "resultpageHero");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getQuewiseBreakdown = () => {
    api_call_token
      .get(`content/V1/question/breakdown/question?test_id=${id}`)
      .then((res) => {
        setQuewiseBreakdown(res?.data?.data?.response);
        console.log(res?.data?.data?.response, "getQuewiseBreakdown");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getQuewiseBreakdown();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChapterSelect = (id) => {
    setChapterSelected(id);
    const selectedChapter = chapterwiseData.find(
      (chapter) => chapter.id === id
    );
    setChapterData(selectedChapter);
    console.log(id, "tabvalues");
  };
  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  return (
    <div className={styles.mainContainer}>
      <div
        onClick={handleBackClick}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        {/* <ArrowBackIcon /> */}
        <img src={backArrowBtn} alt="backarrow" />
        <p className={styles.backarrowtext}>Back</p>
      </div>
      <div className={styles.overallPerformance}>
        <h2 className={styles.heading}>Overall Performance</h2>
        <div className={styles.performance}>
          <div className={styles.Sidecontainer}>
            <div className={styles.circleProgress}>
              <LzCircularProgress
                variant="determinate"
                value={resultData?.total_avg}
                style={{ fontSize: "40px" }}
              />
            </div>
            <div className={styles.lineProgress}>
              <div className={styles.DiffProgress}>
                <p className={styles.difficulty}>
                  Easy<span>{resultData?.percentage_easy}%</span>
                </p>
                <LzProgress
                  variant="determinate"
                  value={resultData?.percentage_easy}
                />
              </div>
              <div className={styles.DiffProgress}>
                <p className={styles.difficulty}>
                  Medium<span>{resultData?.percentage_medium}%</span>
                </p>
                <LzProgress
                  variant="determinate"
                  value={resultData?.percentage_medium}
                />
              </div>
              <div className={styles.DiffProgress}>
                <p className={styles.difficulty}>
                  Hard<span>{resultData?.percentage_hard}%</span>
                </p>
                <LzProgress
                  variant="determinate"
                  value={resultData?.percentage_hard}
                />
              </div>
            </div>
          </div>
          <div className={styles.Sidecontainer}>
            <div className={styles.testquestion}>
              <div className={styles.queInfo}>
                <div className={styles.queInfoLeft}>
                  <div className={styles.imgContainer}>
                    <img src={Correct} alt="" />
                  </div>
                  <h5>Correct</h5>
                </div>
                <div className={styles.queInfoRight}>
                  {queResultData?.correct_answer < 9
                    ? `0${queResultData?.correct_answer}`
                    : queResultData?.correct_answer}
                  {/* {queResultData?.correct_answer} */}
                </div>
              </div>
              <div className={styles.queInfo}>
                <div className={styles.queInfoLeft}>
                  <div className={styles.imgContainer}>
                    <img src={Incorrect} alt="" />
                  </div>
                  <h5>Incorrect</h5>
                </div>
                <div className={styles.queInfoRight}>
                {queResultData?.wrong_answer < 9
                    ? `0${queResultData?.wrong_answer}`
                    : queResultData?.wrong_answer}
                  {/* {queResultData?.wrong_answer} */}
                </div>
              </div>
              <div className={styles.queInfo}>
                <div className={styles.queInfoLeft}>
                  <div className={styles.imgContainer}>
                    <img src={Unanswer} alt="" />
                  </div>
                  <h5>Unanswered</h5>
                </div>
                <div className={styles.queInfoRight}>
                {queResultData?.questions_not_attempted < 9
                    ? `0${queResultData?.questions_not_attempted}`
                    : queResultData?.questions_not_attempted}
                  {/* {queResultData?.questions_not_attempted} */}
                </div>
              </div>
            </div>
            <div className={styles.allPreTime}>
              <div className={styles.infoBox}>
                <h4>{resultData?.total_avg}%</h4>
                <p>Percentile</p>
              </div>
              <div className={styles.infoBox}>
                <h4>{formatDuration(queResultData?.avg_time)} </h4>
                <p>Time taken</p>
              </div>
            </div>
          </div>
        </div>

        {/*   tabination overall chapertwise  */}
        {/* <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
          >
            <Tab
              label="Question Breakdown"
              {...a11yProps(0)}
              className={classes.tab}
            /> */}
            {/* <Tab
              label="Chapterwise Breakdiwn"
              {...a11yProps(1)}
              className={classes.tab}
            /> */}
            
          {/* </Tabs>
        </AppBar> */}
        {/* <TabPanel value={value} index={0}> */}
        <h2 className={styles.heading}>Question Breakdown</h2>
          {quewiseBreakdown?.map((v, index) => {
            return <LzQuestion value={v} index={index} key={index} />;
          })}

          {/* <div className={styles.queReviewBox} >
          <div className={styles.queBar}>
            <p>Q1. hduiqdhaskdnajkdhasldnas,dknsad</p>
            <div className={styles.timeingForQ}><div style={{width:"90%"}}>
            <LzProgress variant="determinate" value={50} />
            </div>
              <span >05 sec</span>
            </div>
          </div>
          <div className={styles.difficultyBarMarks}>
            <div className={styles.barImagContainer}>
              <img src={dLevel == 2 ? easy : dLevel == 3 ? medium : dLevel ? hard : ""} alt="" />
            </div>
            <div className={`${styles.marksBox} ${markGets == 1 ? styles.correctAns : markGets == 2 ? styles.incorrectAns : "" }`}>
              <h2>2</h2>
            </div>
          </div>
        </div> */}
        {/* </TabPanel> */}
        {/* <TabPanel value={value} index={1}>
          <div className={styles.chapterTab}>
            {chapterwiseData?.map((v, index) => {
              return (
                <div
                  className={`${styles.tabs} ${
                    chapertSelected == v?.id ? styles.activeTab : ""
                  }`}
                  onClick={() => handleChapterSelect(v?.id)}
                >
                  Chapter {v?.id}
                </div>
              );
            })}
          </div>
          <LzChapterwiseResult value={chapterData} />
        </TabPanel> */}
      </div>
    </div>
  );
}

export default TestResult;

const data = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
];

const chapterwiseData = [
  {
    id: 1,
    chapter: 1,
    avgScore: 30,
    que: [
      {
        id: 1,
        title: "que1",
      },
      {
        id: 2,
        title: "que1",
      },
      {
        id: 3,
        title: "que1",
      },
      {
        id: 4,
        title: "que1",
      },
    ],
  },
  {
    id: 2,
    chapter: 2,
    avgScore: 50,
    que: [
      {
        id: 1,
        title: "que1",
      },
      {
        id: 2,
        title: "que1",
      },
    ],
  },
  {
    id: 3,
    chapter: 3,
    avgScore: 80,
    que: [
      {
        id: 1,
        title: "que1",
      },
      {
        id: 2,
        title: "que1",
      },
      {
        id: 3,
        title: "que1",
      },
      {
        id: 4,
        title: "que1",
      },
    ],
  },
  {
    id: 4,
    chapter: 4,
    avgScore: 30,
    que: [
      {
        id: 1,
        title: "que1",
      },
      {
        id: 2,
        title: "que1",
      },
      {
        id: 3,
        title: "que1",
      },
    ],
  },
];
