import React from 'react'
import styles from './index.module.css'
import pptshow from "../../../Assets/pptPageimg.png"
import Carousel from 'react-multi-carousel';
import { responsiveCourses } from '../../../Utils/Utils';

function PptPage() {

  const totalPptCount = pptData.reduce((total, chapter) => {
    return total + chapter.ppt.length;
  }, 0);

  console.log("Total PPT Count:", totalPptCount);
  return (
    <div className={styles.pptContainer}>
      <h3 className={styles.heading}>Powerpoint Presentation ({totalPptCount})</h3>
      {
        pptData.map((info) =>{
          return(
            <>
            <h4 className={styles.chapterheading}>{info?.id}. {info?.title} ({info?.ppt.length})</h4>
            
            {/* <div className={styles.pptsCards}> */}
            <Carousel responsive={responsiveCourses}>
              {
                info?.ppt?.map((v)=>{
                  return(
                    <a href={v?.url} className={styles.pptCard} target='_blank'>
                      <img src={v?.imag} alt={v?.topicName} />
                      <div className={styles.pptCardText}>
                        <h5>{v?.topicName}</h5>
                        <p>{v?.noOfSlide} Slides</p>
                      </div>
                    </a>
                  )
                })
              }
              </Carousel>
            {/* </div> */}
            </>
          )
        })
      }

    </div>
  )
}

export default PptPage

const pptData = [
  {
    id:1,
    title:"Chapter 1",
    ppt:[
      {
        id:1,
        topicName:"Topic 1",
        noOfSlide:5,
        imag:pptshow,
        url:"",
      },
      {
        id:2,
        topicName:"Topic 2",
        noOfSlide:8,
        imag:pptshow,
        url:"",
      },
    ]
  },
  {
    id:2,
    title:"Chapter 2",
    ppt:[
      {
        id:1,
        topicName:"Topic 1",
        noOfSlide:14,
        imag:pptshow,
        url:"",
      },
      {
        id:2,
        topicName:"Topic 2",
        noOfSlide:19,
        imag:pptshow,
        url:"",
      },
      {
        id:3,
        topicName:"Topic 3",
        noOfSlide:21,
        imag:pptshow,
        url:"",
      },
      {
        id:4,
        topicName:"Topic 4",
        noOfSlide:5,
        imag:pptshow,
        url:"",
      },
    ]
  },
]