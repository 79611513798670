import React from "react";
import "./StudentRegistration.css";
import bg from "../Forms/formassets/Asset 1.png";
import SingleButton from "../SingleButton/SingleButton";

function StudentRegistration() {
  return (
    <>
      <div className="class_coaching_main">
        <div className="coach_first cf_teach">
          <div className="coach_container">
            <div className="reg_box ">
              {/* Header*/}
              <div className="head_main_reg">
                <h1 className="reg_head">Student Registration</h1>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">Name</label>
                  <div>
                    <input
                      type="text"
                      className="tr_input"
                      placeholder="First name"
                    ></input>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl"></label>
                  <div>
                    <input
                      type="text"
                      className="tr_input"
                      placeholder="Last name"
                    ></input>
                  </div>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Email ID</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Phone Number</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Gender</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian Info: </label>
                <div>
                  {/* <input type="text" className="tr_input"></input> */}
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian Name</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian phone</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian email</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian Occupation</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Occupation</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian Name</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian phone</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
              <div className="contain_reg">
                <label className="lbl">Guardian email</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Guardian Occupation</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">State</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl">City</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Pincode</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">Grades </label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl">Boards</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Exams</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Languages</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Extra-curricular</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>
            </div>
            <div className="btn_reg_class">
              <SingleButton />
            </div>
          </div>
        </div>

        <div className="coaching_aside">
          <img src={bg} alt="bg1" className="aside_img"></img>
        </div>
      </div>
    </>
  );
}

export default StudentRegistration;
