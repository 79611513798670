import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { login_api } from "../../Utils/Network";
import scope from "../../Assets/Nav_Bar/Assets/1x/Asset 22.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./Popovr.css";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(0),
    width: "950px",
  },
}));

function SimplePopover(props) {

  const { setFilter } =
    useContext(UserCredsContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exam, setExam] = useState([]);
  const [grades, setGrade] = useState([]);
  const [boards, setBoard] = useState([]);
  const [tryfree, setSession] = useState([]);
  const [isActive, setActive] = useState(true);
  const [drawer, setDrawer] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(false);
    setDrawer(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActive(true);
    setDrawer(false);
  };

  const toggleDrawer = (open) => (event) => {
    console.log("hello", open,event);
    setDrawer(open);
  };
  const getExam = () => {
    login_api
      .get(`/base/exam_type_mapping/`)
      .then((response) => {
        setExam(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGrade = () => {
    login_api
      .get(`/base/grade-type-detail/`)
      .then((response) => {
        setGrade(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getBoard = () => {
    login_api
      .get(`/base/board/`)
      .then((response) => {
        setBoard(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getTryFree = () => {
    login_api
      .get(`/base/session/`)
      .then((response) => {
        setSession(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleFilter = (prop, x, y = '') => {
    setActive(true);
    setDrawer(false);
    setFilter({
      type: prop,
      value: x,
      title: y
    })
    setAnchorEl(null);
    props.history.push("/explore")
  }


  useEffect(() => {
    getExam();
    getGrade();
    getBoard();
    getTryFree();
  }, []);

  const display = () => {
    return (
      <div>
        <div className="line-hor"></div>
      <div className="pop_list">
        <div className="pp_lst">
          <div className="pp_head">Grade</div>
          <ul>
            {grades.sort(function (a, b) { return a.id - b.id }).slice(0, 5).map((item, x) => {
              return <><li onClick={() => handleFilter("grade", item.grade, "grade")}>{item.grade_type.title}</li></>;
            })}
          </ul>
        </div>
        <div className="pp_lst">
          <div className="pp_head">Exams</div>
          <ul>
            {exam.slice(0, 5).map((item, x) => {
              return <><li onClick={() => handleFilter("exam", item.exam)}>{item.exam_type.title}</li></>;
            })}
          </ul>
        </div>
        <div className="pp_lst">
          <div className="pp_head">Boards</div>
          <ul>
            {boards.slice(0, 5).map((item, x) => {
              return <><li onClick={() => handleFilter("board", item.id, item.title)}>{item.title}</li></>;
            })}
          </ul>
        </div>
        <div className="pp_lst">
          <div className="pp_head">Try for free</div>
          <ul>
            {tryfree.slice(0, 5).map((item, x) => {
              return <><li onClick={() => handleFilter("session", item.id)}>{item.title}</li></>;
            })}
          </ul>
        </div>
      </div>
      </div>
    )

  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="dropdown">
      <Button
        aria-describedby={id}
        className="dropbtn"
        color='primary'
        variant="contained"
        onClick={handleClick}
        style={isActive === true ? {} : { backgroundColor: "#022E7A" }}
      >
        <img src={scope} className="scope" alt="scope"></img>
        Explore
        <i className="arrow down"></i>
      </Button>
      {window.innerWidth > 769 && <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="ppr"
      >
        <Typography className={classes.typography} style={{ borderRadius: '13px' }}>


          {display()}
        </Typography>
      </Popover>}

      {window.innerWidth < 768 && <SwipeableDrawer
          anchor={"bottom"}
          open={drawer}
          onClose={handleClose}
          onOpen={toggleDrawer(true)}
          classes={{ paper: classes.BackdropProps }}
          style={{ backgroundColor: "transparent" }}
        >
          {display()}
        </SwipeableDrawer>}
    </div>
  );
}
export default withRouter(SimplePopover);