import React from "react";
import styles from "./index.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./arrow.css";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="custom-arrow custom-prev-arrow" onClick={onClick}>
      <ArrowBackIosIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button className="custom-arrow custom-next-arrow" onClick={onClick}>
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}

function NewSlider(values) {
  var settings = {
    className: "",
    // dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    // customPaging: (i) => (
    //   <div
    //     style={{
    //       borderBottom: "4px solid #12888A66 ",
    //       backgroundColor: "#12888A66",
    //       // padding: "0px 80px",
    //     }}
    //     className="update-slick"
    //   ></div>
    // ),
  };
  console.log(values?.values, "newslidercomponent");
  const sliderData = values?.values;
  return (
    <>
      <Slider {...settings}>
        {sliderData?.map((v, i) => {
          console.log();
          return (
            <>
              {/* // <div style={{backgroundColor:"red"}}>{v?.id}</div> */}
              <div className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <h4 className={styles.cardHeading}>{v?.title}</h4>
                <p className={styles.cardPara}>{v?.para}</p>
              </div>
            </>
          );
        })}
      </Slider>
    </>
  );
}

export default NewSlider;
