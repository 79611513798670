import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api_call, base_url } from "../../Utils/Network";
function Terms() {
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api_call
      .get(`/base/terms_policy/?type=1`)
      .then((response) => {
        setDisplayData(response.data.data);
      })
      .catch((error) => {
        console.log(error.response, "Error occurred");
      });
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "130px" }}>
        <p class="tandc_title">Terms and Conditions</p>
      </div>

      <div style={{ width: "80%", margin: "auto" }}>
        {displayData.map((item, idx) => {
          return (
            <div>
              <h3>{item.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: `${item.point}` }}></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Terms;
