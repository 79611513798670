import React, { useState, useEffect } from 'react';
import './privacy.css';
import { api_call, base_url } from "../../Utils/Network";


function Privacy() {

    const [displayData, setDisplayData] = useState([]);
    var values = 2; 
    useEffect(() => {
        getApiData();
    }, [])

    const getApiData = () => {
        api_call
        .get(`/base/terms_policy/?type=${values}`)
            .then(response => {
                console.log(response.data.data, 'data for homepage');
                setDisplayData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    return (
        <div>
        <div style={{ textAlign: 'center', marginTop: '130px' }}>
            <p class="tandc_title">PRIVACY</p>
        </div>

        <div style={{width:'80%',margin:'auto'}}>
        {displayData.map((item,idx) => {
                return (
                    <div>
                    <h3>{item.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: `${item.point}` }}></div>

                    </div>
                )
            })}
        </div>
    </div>
    )
}

export default Privacy
