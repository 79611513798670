import React, { useState, useEffect, useContext } from "react";
import { api_token, login_api } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import Button from "@material-ui/core/Button";
import StudImg from "../../Assets/brainhap/bannerSignup.png";
import studs from "../../Assets/1x/asset/asset10.png";
import logos from "../../Assets/brainhap/logosmall.png";
import cross from "../../Assets/1x/asset/close.png";
import { useHistory } from "react-router-dom";

function Login({ closeModal,open, ...props }) {
  const { updateLoginData, userType } = useContext(UserCredsContext);
  const [errors, setErrors] = useState({});
  const [enable, setEnable] = useState(true);
  const [contactNumber, setContactNumber] = useState({
    code: "+91",
    number: null,
  });
  let history = useHistory();


  useEffect(() => {
    // Define the 'otpless' function
    window.otpless = (otplessUser) => {
     // Retrieve the user's details after successful login
     const waName = otplessUser.waName;
     const waNumber = otplessUser.waNumber;
         setContactNumber({
          ...contactNumber,
          number: waNumber,
         })
     // Handle the signup/signin process
     // ...
    };
  }, []);


  const checkId = (e, type) => {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    if (errors.hasOwnProperty(name) && errors[name].length !== 0) {
      setErrors({ ...errors, [name]: "" });
    }
    if (value.length === 10) {
      setEnable(false);
    } else if (value.length !== 10) {
      setEnable(true);
    }

    if (name == "number" && value.length > 10) {
      return;
    }
    setContactNumber({
      ...contactNumber,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    let returnValue = true;
    temp.code = contactNumber.code ? "" : "Code is required";
    temp.number = /^[0-9][0-9]{9}$/.test(contactNumber.number)
      ? ""
      : "Please enter valid phone number.";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("valid");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  console.log(updateLoginData);

  // const getOtps = () => {
  //   updateLoginData({
  //     code: contactNumber.code,
  //     // phone: '7796594487',
  //     phone: contactNumber.number,
  //     // otp: "",
  //     // type: "usr",
  //   });
  //   history.push("/user-registration");
  //   closeModal();
  // }

  const getOtp = () => {
    if (!validate()) {
      return null;
    }
    login_api
      .post(`/auth/lcarro/unique_phone_check/?phone=${contactNumber.number}`)
      .then((response) => {
        console.log("Response", response.data);
        const { message, status, id } = response.data.data;
        if (!status) {
          setErrors({
            err: message,
          });
        } else if (status) {
          updateLoginData({
            id: response.data.data.id,
            code: contactNumber.code,
            phone: contactNumber.number,
            otp: "",
            type: "auth",
          });
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="signin_content" style={{ position: "relative" }}>
        <img
          src={cross}
          alt="cross"
          className="cross"
          onClick={() => closeModal()}
        />
        <div className="stud_dialogue stud_dialogs">
          <img src={StudImg} alt="StudentIcon" className="studImg"></img>
        </div>
        <div className="sign_cont_box">
          <div className="m_inner_content" style={{ textAlign: "center" }}>
            <img src={logos} style={{ width: "50px", margin: "0 auto" }} />
            <p>
              Enter your mobile number{" "}
              {/* {userType === 8 ? "to start Educating" : "to start learning"} */}
            </p>
          </div>
          <div
            className="signin_input sing_val"
            style={{ width: "auto", display: "flex" }}
          >
            <div className="inp_ten" style={{ width: "auto" }}>
              <input
                type="text"
                name="code"
                list="countryCode"
                id="cityId"
                onChange={(e) => checkId(e, "select")}
                defaultValue={contactNumber.code}
              />
              <datalist id="countryCode">
                {optionList.map((item, key) => {
                  return <option key={key} value={item.number} />;
                })}
              </datalist>
            </div>
            <div className="inp_ten" style={{ width: "150px" }}>
              <input
                name="number"
                type="text"
                id="numberId"
                placeholder="99999 99999"
                onChange={(e) => checkId(e, "input")}
              />
            </div>
          </div>
          {/* <div id="otpless" style={{margin: '0 auto'}}></div> */}
          <p className="error-message-text errs" name="err">
            {errors.err}{" "}
          </p>
          <div className="btn_classModel">
            {            
            <Button
              variant="contained"
              className="btn_model"
              onClick={getOtp}
              color="primary"
              style={{
                borderRadius: "8px",
                width: "82%",
                marginTop: "20px",
                fontSize: "18px",
                fontWeight: "550",
                backgroundColor: "#fff;",
                color: "rgb(3, 44, 107)",
              }}
              disabled={enable}
            >
              Next
            </Button>}
            {/* <Button
              variant="contained"
              className="btn_model"
              // onClick={handelClick}
              color="primary"
              style={{
                borderRadius: "8px",
                width: "82%",
                marginTop: "20px",
                fontSize: "18px",
                fontWeight: "550",
                backgroundColor: "#fff;",
                color: 'rgb(3, 44, 107)',
              }}
              // disabled={enable}
            >
              Already user ? sign in
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

const optionList = [
  {
    number: "+91",
    id: 1,
  },
  {
    number: "+92",
    id: 2,
  },
];
