import React, { useState, useEffect, useContext } from 'react';
import { api_call_token } from '../../Utils/Network';
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import lcarro from '../../Assets/brainhap/logosmall.png';
import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';
import BUTTON3 from '../../Assets/homepage/BUTTON3.png';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function loadScript(src) {
    return new Promise(resolve => {
        const script = document.createElement('script');
        console.log(script, 'script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'
        // script.onload = display.Razorpay;
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false);
        }
        document.body.appendChild(script)
    })
}

function Payment(props) {
    // alert('reached');
    console.log(props, "validitypropsdata");
    const { user } = useContext(UserCredsContext);
    const [courseId, setCourseId] = useState();
    const [paymentId, setPaymentId] = useState();
    const [success, setSuccess] = useState(false);
    const [orderId, setOrderId] = useState('');

    const [open, setOpen] = React.useState(false);



    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const { courseIdP, validityIdP } = props.paymentData

        if (validityIdP !== null && validityIdP !== undefined) {
            getOrderId(validityIdP)
            setCourseId(courseIdP);
        }

    }, [props.paymentData])

    async function displayRazorPay(dataId, amountData, apiCourseidData, validityId) {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        console.log(dataId, 'data id passed')
        console.log(amountData, 'amountData')
        console.log(apiCourseidData, 'apiCourseidData')
        console.log(validityId, 'validityId')
        if (!res) {
            alert('razorpay sdk failed to load. Are you online?')
            return
        }
        
        let  current_url = window.location.href;
        if( current_url.includes('localhost') || current_url.includes('165.22.214.137') ){
            var razorpay_key = 'rzp_test_TW6h2zNAZqVdu1';
        } else {
            var razorpay_key = 'rzp_live_lDScQvs1epmNca';
        }
        var courseprice = amountData;
        console.log(courseprice, 'coursepricecourseprice')
        const options = {
            "key": razorpay_key,
            "amount": courseprice,
            "currency": "INR",
            "name": "Brainhap",
            "description": "Course payment",
            "image": lcarro,
            "order_id": `${dataId}`,
            // "callback_url":history.push("/my-space"),
            // "redirect": true,
            "handler": function (response) {
                // alert(response);
                console.log(response.razorpay_payment_id, 'hello');
                console.log(response.razorpay_order_id, 'hello1');
                console.log(response.razorpay_signature, 'hello2');
                setPaymentId(response.razorpay_payment_id);
                var intValue = parseInt(courseId)
                getpurchase(response.razorpay_order_id, response.razorpay_payment_id, apiCourseidData, validityId);
            },
            "prefill": {
                "name": `${user.first_name}`,
                "email": `${user.email}`,
                "contact": `${user.phone}`
            },
        };

        var paymentObject = new window.Razorpay(options);
        paymentObject.open()
    }

    const getpurchase = (orderId, rpid, apiCourseidData, validityId) => {

        var data = {
            "transaction_id": rpid,
            "course": props.paymentData.courseIdP,
            "validity_id": validityId,
            "order_id": apiCourseidData,
        }
        console.log(data, 'value to pass in purchase')
        api_call_token.post(`/purchase/course/`, data)
            .then(response => {
                console.log("The response for purchase course is ", response.data.data);
                setOpen(true);
                setSuccess(true);
            })

    }

    const getOrderId = (validityId) => {
        var data = {
            "validity_id": validityId,
        }
        api_call_token.post(`/purchase/order/`, data)
            .then(response => {
                console.log("order details from api", response.data.data);
                const { id, order_id, amount } = response.data.data;
                setOrderId(id);
                displayRazorPay(order_id, amount, id, validityId);
            })
            .catch(error => {
                alert('Please login to purchase')
                console.error(error);
            })
    }
    return (
        <React.Fragment>

            {success == true && <div className="success-display">
                <Dialog style={{borderRadius:'13px!important'}}
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    {/* <DialogTitle style={{padding:'10%'}} id="alert-dialog-slide-title">{"Course Purchased Successfully"}</DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="success-div-c">
                                <h3> Payment Successfull</h3>
                                <p>Your payment id is ({paymentId})</p>
                                <Link to="/my-space">
                                <Button
                                    variant="contained"
                                        style={{
                                            marginBottom: '10px',
                                            textTransform:'capitalize',
                                            borderRadius: 4,
                                            backgroundColor: "#133268",
                                            fontWeight: '550',
                                            backgroundImage: `url(${BUTTON3})`, backgroundSize: '100% 100%',
                                            fontSize: '15px',
                                            color: 'white'
                                        }}
                                >
                                Start Learning
                                </Button>
                                </Link>            
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Agree
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </div>}
        </React.Fragment>
    )
}

export default Payment