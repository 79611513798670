import React from "react";
import ostyles from "./services.module.css";
import styles from "./index.module.css";
import bannerpc from "../../Assets/newevopage/evoBanner-desktop.png";
import bannermobile from "../../Assets/newevopage/evoBannermobile.png";
import backArrow from "../../Assets/newevopage/bannerbackarrow.png";
import process from "../../Assets/newevopage/Process.png"
import outcome from "../../Assets/newevopage/outcome.png"
import TextimgCard from "../../Components/Newdesigncomponets/TextimgCard/TextimgCard";
import servicesimg1 from "../../Assets/BrainMapping.png"
import servicesimg2 from "../../Assets/Neuromentoring.png"
import servicesimg3 from "../../Assets/StemLab.png"

function OurServicesDetails() {
  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={`${styles.bannerimg} ${styles.pcShow}`}>
          <img src={bannerpc} />
        </div>
        <div className={`${styles.bannerimg} ${styles.mobileShow}`}>
          <img src={bannermobile} />
        </div>
        <div className={styles.bannerText}>
          <h1>Discover your hidden potential.</h1>
        </div>
        <div className={styles.backarrowClick}>
          <img src={backArrow} />
        </div>
      </section>
      <section className={` ${ostyles.introductionSection}`}>
        <h2 className={`${styles.sectionHeading}`}>Introduction</h2>
        <p className={ostyles.intoPara}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.Lorem Ipsum is simply dummy text of
          the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to make a type specimen
          book. It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged.Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged.Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged.Lorem Ipsum is simply
          dummy text of the printing and typesetting industry.
        </p>
      </section>
      <section className={`${ostyles.processSection}`}>
        <h2 className={`${styles.sectionHeading}`}>Process</h2>

        <div className={` ${ostyles.imgContainer}`}>
          <img src={process} alt="" />
        </div>
      </section>
      <section className={`${ostyles.outcomeSection}`}>
        <h2 className={`${styles.sectionHeading}`}>Outcome</h2>

        <div className={` ${ostyles.imgContainer}`}>
          <img src={outcome} alt="" />
        </div>
      </section>
      <div className={ostyles.boder}></div>

      <section className={`${ostyles.oueservicesSection}`}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter}`}>Our Other Services</h2>

        {/* <div className={` ${ostyles.imgContainer}`}>
          <img src={outcome} alt="" />
        </div> */}
        <div className={ostyles.showListcontainer}>
          {
            servises?.map((v) =>{
              return(
                <TextimgCard values={v}/>
               )
            })
          }
        </div>

      </section>
    </>
  );
}

export default OurServicesDetails;

const servises =[
  {
    id:1,
    img:servicesimg1,
    title:"Lorem Ipsum",
  },
  {
    id:2,
    img:servicesimg2,
    title:"Lorem Ipsum",
  },
  {
    id:3,
    img:servicesimg3,
    title:"Lorem Ipsum",
  },
]