import React, { useEffect, useState, useContext } from "react";
import SingleButton from "../SingleButton/SingleButton";
import bags from "../Forms/formassets/Asset 2.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { login_api } from "../../Utils/Network";

import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function SchoolRegistration(props) {
  const {
    userType,
    loginData,
    updateLoginData,
    setToken,
    setUser,
    partnerType,
  } = useContext(UserCredsContext);

  const [_institute, setInstitute] = useState([]);
  const [inst, setInst] = useState(null);
  const [_data, setData] = useState([]);
  const [_board, setBoard] = useState(null);
  const [errors, setErrors] = useState({});
  const [gradeUpd, setGrade] = useState(null);
  const [name, setName] = useState({ name: null });
  const [stateId, setStateId] = useState([]);
  const [userData, setUserData] = useState({
    f_name: "",
    pass: "",
    renter: "",
  });

  const handle = (event) => {
    const { name, value } = event.target;

    setUserData({ ...userData, [name]: value });
  };

  const handleChange = (event, val) => {
    const op = val !== null ? setBoard(val.id) : "Please select Board";
    setErrors({ ...errors, op });
  };

  const handleChanged = (event, values) => {
    const outpt = values !== null ? setGrade(values.id) : "Please select Grade";
    setErrors({ ...errors, outpt });
  };

  const handleChanges = (event, values) => {
    const out = values !== null ? setInst(values.id) : "Please select Language";
    setErrors({ ...errors, out });
  };

  const getBoard = () => {
    login_api
      .get(`/base/board/`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getGrade = () => {
    login_api
      .get(`/base/grade/`)
      .then((response) => {
        setStateId(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const institute = () => {
    login_api
      .get("/base/language/")
      .then((response) => {
        setInstitute(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getBoard();
    getGrade();
    institute();
  }, []);

  const validate = () => {
    debugger;
    let temp = {};
    let returnValue = true;
    temp.names = /^[a-zA-Z_\- ]{2,30}/.test(name.name)
      ? ""
      : "Please enter valid name.";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("valid");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const SubmitData = () => {
    debugger;
    if (!validate) {
      return null;
    } else {
      var datas = {
        institute_profile: {
          board_id: [_board],
          grade_id: [gradeUpd],
          language_id: [inst],
          name: userData.f_name,
          primary_contact: loginData.phone,
          partner_type: partnerType,
        },
        user_type: userType,
        password: userData.pass,
      };

      login_api
        .post(`auth/lcarro/${loginData.id}/student_register/`, datas)
        .then((response) => {
          const { token, user } = response.data.data;
          setToken(token);
          setUser(user);
          props.history.push("/");
        });
    }
  };
  return (
    <>
      <div className="class_coaching_main">
        <div className="coach_first">
          <div className="coach_container">
            <div className="reg_box ">
              {/* Header*/}
              <div className="head_main_reg">
                <h1 className="reg_head">School Registration</h1>
              </div>

              <div>
                <div>
                  <label className="lbl">Name</label>
                  <div>
                    <input
                      type="text"
                      className="tr_input"
                      placeholder="School name"
                      name="f_name"
                      onChange={handle}
                    ></input>
                  </div>
                </div>
              </div>
              <div>
                <Autocomplete
                  id="combo-box-demo"
                  options={stateId}
                  onChange={handleChanged}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "80%", margin: "auto" }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Grade" />
                  )}
                />
                <p>{errors.outpt}</p>

                <Autocomplete
                  id="combo-box-demo3"
                  options={_data}
                  getOptionSelected={(option, value) => option === value}
                  onChange={handleChange}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "80%", margin: "auto" }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Board" />
                  )}
                />
                <p>{errors.op}</p>

                <Autocomplete
                  id="combo-box-demo3"
                  options={_institute}
                  onChange={handleChanges}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "80%", margin: "auto" }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Language" />
                  )}
                />
                <p>{errors.op}</p>
              </div>
              <div className="">
                <input
                  name="pass"
                  value={userData.pass}
                  onChange={handle}
                  placeholder="Password"
                  className="tr_input"
                  type="password"
                ></input>
                <p className="err_pass">{errors.password} &nbsp;</p>
              </div>

              <div className="">
                <input
                  name="renter"
                  onChange={handle}
                  value={userData.renter}
                  placeholder="Re-Enter Password"
                  className="tr_input"
                  type="password"
                ></input>
                <p className="err_pass">{errors.checkpass} &nbsp;</p>
              </div>
            </div>
            <div className="btn_reg_class">
              <button className="sig_button" onClick={SubmitData}>
                Submit
              </button>
            </div>

            {/* <div className="contain_reg">
                <label className="lbl">Company Email</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Phone Number</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Role in the institute</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <div className="lbl txt_insti">Institution Details</div>
                <label className="lbl">School Name</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Institute email address</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">State</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl">City</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Pincode</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">No. of Instructors</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Pre-recorded Content Available?</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">
                  Do you intend on uploading the Recording of your Live classes?
                </label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="states">
                <div className="st_child">
                  <label className="lbl">Grades taught</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
                <div className="st_child">
                  <label className="lbl">Boards taught</label>
                  <div>
                    <input type="text" className="tr_input"></input>
                  </div>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Exams</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">languages</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">Extra-curricular</label>
                <div>
                  <input type="text" className="tr_input"></input>
                </div>
              </div>

              <div className="contain_reg">
                <label className="lbl">
                  What inspired you to connect with our online platform? (50-150
                  words)
                </label>
                <div>
                  <textarea
                    type="text"
                    className="tr_input"
                    rows="4"
                    cols="15"
                  ></textarea>
                </div> 
              </div>*/}
          </div>
          {/* <div className="btn_reg_class">
              <SingleButton />
            </div> */}
        </div>

        <div className="coaching_aside">
          <img src={bags} alt="bg1" className="aside_img"></img>
        </div>
      </div>
    </>
  );
}

export default SchoolRegistration;
