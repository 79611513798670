import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import pptshow from "../../../Assets/pptPageimg.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Carousel from "react-multi-carousel";
import { responsiveCourses } from "../../../Utils/Utils";
import { api_call_token } from "../../../Utils/Network";
import { useHistory, useParams, useLocation } from "react-router-dom";
import noebook from "../../../Assets/no-Ebook.png";
import backArrowBtn from "../../../Assets/backArrow.png";

function EbookPage({ setSelectCourse }) {
  const [loading, setLoading] = useState(true);
  const [eBook, setEBook] = useState([]);
  const [eBookcount, setEBookCount] = useState(0);
  const [opnePdf, setOpnePdf] = useState(true);
  const [showPdf, setShowPdf] = useState();
  const [emptyState, setEmptystate] = useState(0);
  const [page, setPage] = useState(1);
  const [lastElement, setLastElement] = useState(null);
  const [maxpage, setmaxPage] = useState(1);
  const params = useParams();
  const { tabId, ppt, courseId } = params;
  const [showDotsOnMobile, setShowDotsOnMobile] = useState(false);

  const observer = useRef(
    typeof window != "undefined" &&
      new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting && page <= maxpage) {
          setPage((no) => no + 1);
        }
      })
  );

  useEffect(() => {
    if (page <= maxpage) {
      getEbook();
    }
  }, [page]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentObserver == false) return;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  // const totalPptCount = pptData.reduce((total, chapter) => {
  //   return total + chapter.ppt.length;
  // }, 0);

  // console.log("Total PPT Count:", totalPptCount);

  const getEbook = () => {
    api_call_token
      // .get(`content/notes/content/learnzone/?course_id=${courseId}&page=${page}`)
      .get(`content/notes/content/learnzone/${courseId}/`)
      .then((res) => {
        if (res?.status === 200) {
          // console.log(res?.data?.data, "ebookData");
          // setEBook(res?.data?.data);
          // setEBookCount(res?.data?.total_count);
          // const maxpage = res?.data?.max_pages;
          // setmaxPage(maxpage);

          const data = res?.data?.data;
          console.log(data, "checkitsLength");

          setEBook(data)
          // setEBook((prevState) => {
          //   const updatedState = [...prevState, ...data];
          //   return updatedState;
          // });
          setSelectCourse(data?.title);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred");
      });
  };

  // useEffect(() => {
  //   getEbook();
  // }, []);

  const handelView = (v) => {
    setOpnePdf(!opnePdf);
    setShowPdf(v);
  };

  // console.log("Total PPT Count:", showPdf?.file);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setShowDotsOnMobile(true);
      } else {
        setShowDotsOnMobile(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {/* {eBook?.chapter?.length === 0 && <div> empty </div>} */}
      {/* {eBook?.chapter?.length > 0 && (
        <> */}
      {opnePdf ? (
        <div className={styles.pptContainer}>
          {/* {eBook?.chapter?.map((info, index) => {

            console.log(info, "newinfo");
            return ( */}
              <>
                {eBook?.chapter?.length === 0 && (
                  <div
                    className={styles.nodataAvl}
                    style={{ margin: "0 auto", width: "fit-content" }}
                  >
                    <img src={noebook} alt="nodata" />
                    <p style={{ fontSize: "20px", textAlign: "center" }}>
                      E-Book Coming Soon !
                    </p>
                  </div>
                )}
                {eBook?.chapter?.length > 0 && (
                  <>
                    {/* <div key={`ebook-${index}`} id={`ebook-${index}`}> */}
                      <h2 className={styles.CourseHeading}>{eBook?.title}</h2>

                      {eBook?.chapter?.map((chapterinfo) => {
                        return (
                          <>
                            <h4 className={styles.chapterheading}>
                              {chapterinfo?.title} ({chapterinfo?.total_count})
                            </h4>

                            <Carousel
                              responsive={responsiveCourses}
                              className={styles.slider}
                              showDots={showDotsOnMobile}
                            >
                              {chapterinfo?.notes?.map((v) => {
                                return (
                                  <button
                                    href={v?.file}
                                    className={styles.pptCard}
                                    onClick={() => {
                                      handelView(v);
                                    }}
                                  >
                                    <div
                                      style={{
                                        maxHeight: "225px",
                                        position: "relative",
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                        margin: "10px 0 30px",
                                      }}
                                      ref={setLastElement}
                                    >
                                      <img
                                        src={
                                          v?.thumbnail &&
                                          (v.thumbnail.endsWith(".jpg") ||
                                            v.thumbnail.endsWith(".png"))
                                            ? v?.thumbnail
                                            : pptshow
                                        }
                                        style={{
                                          borderRadius: "10px",
                                          minHeight: "225px",
                                        }}
                                        alt={v?.topicName}
                                      />
                                      <div className={styles.pptCardText}>
                                        <p
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {v?.topic}
                                        </p>
                                      </div>
                                    </div>
                                  </button>
                                );
                              })}
                            </Carousel>
                          </>
                        );
                      })}
                    {/* </div> */}
                  </>
                )}
              </>
          {/* //   );
          // })} */}
        </div>
      ) : (
        <div>
          <div
            onClick={handelView}
            style={{
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {/* <ArrowBackIcon /> */}
            <img src={backArrowBtn} alt="backarrow" />
            <p className={styles.backarrowText}>Back</p>
          </div>
          <div className={styles}>
            <embed
              src={`${showPdf?.file}#toolbar=0`}
              // src="https://d31j8vyj7gdpxq.cloudfront.net/data/files/content_notes/gess1ps_merged.pdf"
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              height="900px"
              width="100%"
              toolbar="0"
              // hidecontrols="1"
            ></embed>
          </div>
        </div>
      )}
      {/* </>
      )} */}
    </div>
  );
}

export default EbookPage;

const pptData = [
  {
    id: 1,
    title: "Chapter 1",
    ppt: [
      {
        id: 1,
        // topicName:"Topic 1",
        noOfSlide: 5,
        imag: pptshow,
        url: "",
      },
    ],
  },
  {
    id: 2,
    title: "Chapter 2",
    ppt: [
      {
        id: 1,
        // topicName:"Topic 1",
        noOfSlide: 14,
        imag: pptshow,
        url: "",
      },
    ],
  },
];
