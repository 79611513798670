import React from 'react'
import './SingleButton.css'
function SingleButton() {
    return (
        <>
          <button className="sig_button">Submit</button>  
        </>
    )
}

export default SingleButton
