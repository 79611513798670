import React from 'react';
import './SubmitButton.css';
import { MainDataContextApi } from '../../ContextApis/MainDataContextApi/MainDataContextApi';
import BUTTON3 from '../../Assets/homepage/BUTTON3.png';


const SubmitButton = ({ label="",style={}}) => {

const {component_styles } = React.useContext(MainDataContextApi);
    return (

        <React.Fragment>

            <input type="submit" style={{...component_styles.SubmitButton,...style, backgroundImage:`url(${BUTTON3})`,backgroundSize:'100% 100%'}} value={label} className="submit-form" />

        </React.Fragment>
    )
}

export default SubmitButton;
