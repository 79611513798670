import React, { useState, useEffect } from 'react';
import { api_call, base_url } from "../../Utils/Network";
import './Refund.css';


function RefundCancelPolicy() {
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {
        getApiData();
    }, [])
    const getApiData = () => {
        api_call
        .get(`/base/refund-cancellation-policy/`)
            .then(response => {
                console.log(response.data.data, 'data for homepage');
                setDisplayData(response.data.data)
            })
            .catch(error => {

            })
    }
    return (
        <div className="refundmain">
            <div style={{ textAlign: 'center' }}>
                <p class="tandc_title">Refund And Cancellation Policy</p>
            </div>

            <div style={{ width: '80%', margin: 'auto' }}>
                {displayData.map((item, idx) => {
                    console.log(item, 'privacy')
                    return (
                        <div>
                            <h3 className="refund-head">{item.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: `${item.description}` }} className="refund_para"></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RefundCancelPolicy

