import React, { useEffect, useState }  from 'react'
import styles from './index.module.css'
import { useHistory, useParams, useLocation } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { api_call_token } from "../../Utils/Network";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Attempted from "../../Assets/attm.png";
import Unattempted from "../../Assets/unattm.png";
import TotalTime from "../../Assets/timetaken.png";
import Bookmark from "../../Assets/bookmark.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import list from "../../Assets/RList.png";
import queRImg from "../../Assets/RQue.png";
import Rtime from "../../Assets/Rtime.png";
import backArrowbtn from '../../Assets/backArrow.png'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function LzTestQue() {
    const [testQuestion, setTestQuestion] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [tQuestion, setTQuestion] = useState();
  const [qCount, setQCount] = useState(0);
  const [totalTimecount, setTotalTimecount] = useState();
  const [qTimer, setQTimer] = useState();
  const [testDuration, setTestDuration] = useState();
  // const [questionTimers, setQuestionTimers] = useState([]);
  const [open, setOpen] = useState(false);
  const [finelOpne, setFinelOpne] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnswersJSON, setSelectedAnswersJSON] = useState({});
  const [testDone, setTestDone] = useState(null);
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [questionTimeSpent, setQuestionTimeSpent] = useState(0);
//   const [ currentRuningQ, setCurrentRuningQ]  = useState(null)
  // Create a state variable to store question-specific timers
  const [questionTimers, setQuestionTimers] = useState({});

  const [showRule, setShowRule] = useState(0);
  const [rulesApi, setRulesApi] = useState();
 

  // const qTimer = "00:00";
  const { id,ppt,courseId  } = useParams();
  const location = useLocation();
  const  extraData = location.state;
  const queryParams = new URLSearchParams(location.search);
  const marks = queryParams.get('marks');
  const total_question = queryParams.get('total_question'); 
  const time = queryParams.get('time'); 
  const title = queryParams.get('title');     
  
  // console.log(marks, "hiPAgename");
  const history = useHistory();
  const handleBackClick = () => {
    history.push(`/my-space/1/${courseId}/mcq`);
    // onTakeTest(v);
  };


  const handleResultView = () => {
    history.push(`/my-space/1/${courseId}/mcq/${id}/result`);
    // console.log("goto Result");
  };

  const getTestData = () => {
    api_call_token
      .get(`content/question/listing/${id}`)
      .then((res) => {
        // console.log(
        //   res?.data?.data,
        //   "Questionapi"
        // );
        setTestQuestion(res?.data?.data?.questions);
        setTQuestion(res?.data?.data?.questions?.length);
        // setTotalTimecount(res?.data?.data?.milliseconds_time);
        setTestDuration(res?.data?.data?.milliseconds_time);
        setQTimer(
          res?.data?.data?.questions[currentQuestionIndex]?.milliseconds_time
        );
        // Start the timer when the test begins
        // startTimer();
        setQuestionStartTime(new Date());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function convertMillisecondsToHours(value) {
    const hours = Math.floor(value / (1000 * 60 * 60));
    const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((value % (1000 * 60)) / 1000);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return {
      hours: formattedHours,
      minutes: formattedMinutes,
      seconds: formattedSeconds,
    };
  }
   // Function to start the timer for a specific question
   const startTimer = (questionId) => {
    const timerInterval = setInterval(() => {
      setQuestionTimers((prevTimers) => ({
        ...prevTimers,
        [questionId]: (prevTimers[questionId] || 0) + 1000, // Increment the timer by 1 second
      }));
    }, 1000);
    
    return timerInterval;
  };

  useEffect(() => {
    if (testDuration > 0) {
      const timerInterval = startTimer(testQuestion[currentQuestionIndex]?.id);

      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [testDuration, currentQuestionIndex]);

  // useEffect(() => {
  //   getTestData();
  // }, []);

  useEffect(() => {
    if (testDuration > 0) {
      const timerInterval = setInterval(() => {
        setTestDuration((prevTimer) => prevTimer - 1000);
      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [testDuration]);

  const formattedTime = convertMillisecondsToHours(testDuration);
  //  convertMillisecondsToHours(testDuration);
  const oneQtime = convertMillisecondsToHours(qTimer);
  // console.log("qTimer", oneQtime);

  // Function to navigate to the next question
  const goToNextQuestion = () => {
    if (currentQuestionIndex < testQuestion.length - 1) {
        setQuestionStartTime(new Date());
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      // setQTimer(testQuestion[currentQuestionIndex + 1]?.milliseconds_time); // Reset qTimer for the next question
    }
  };

  // Function to navigate to the previous question
  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
        setQuestionStartTime(new Date());
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

 

  const handelAnswerselect = (queId, index) => {
    const newSelectedAnswers = { ...selectedAnswersJSON };
    setSelectedAnswer(index);
    newSelectedAnswers[queId] = index;

     // Calculate the time spent on the current question
     const currentTime = new Date();
     const timeSpent = currentTime - questionStartTime;
 
     // Update the time spent on the current question
     setQuestionTimeSpent(timeSpent);


    setSelectedAnswersJSON(newSelectedAnswers);
  };

  // console.log(questionTimeSpent, 'avgTimeSpend');

  useEffect(() => {
    setSelectedAnswer(
      selectedAnswersJSON[testQuestion[currentQuestionIndex]?.id]
    );
  }, [currentQuestionIndex, selectedAnswersJSON, testQuestion]);

  useEffect(() => {
    if (testDuration === 0) {
      submitTestOpne(); // Automatically submit the test
    }
  }, [testDuration]);
  // code for model
  const submitTestOpne = () => {
    if (!testDone) {
      var questionData = [];

      // Loop through each question and gather its data
      testQuestion.forEach((question, index) => {
        const questionId = question.id;
        // const avgTimeSpend = 9;

         // Calculate the average time spent on the question
         const avgTimeSpendInMilliseconds = questionTimers[questionId] || 0;
      
         // Convert milliseconds to seconds
         const avgTimeSpendInSeconds = avgTimeSpendInMilliseconds / 1000;
        

        // Get the selected answer for this question
        const selectedAnswerId = selectedAnswersJSON[questionId];

        if (selectedAnswerId !== undefined) {
          // Create an answer data object
          const answerData = {
            answer_choice: selectedAnswerId,
          };

          // Create a question object and add it to the array
          const questionObject = {
            avg_time_spend: avgTimeSpendInSeconds == 0 ? 1 : avgTimeSpendInSeconds,
            question: questionId,
            answer_data: [answerData], // Store answer data as an array
          };
          questionData.push(questionObject);
          setQuestionTimeSpent(0)
        }
      });

      // Create the final JSON object
      const jsonData = {
        test: id,
        data: questionData,
      };

      // Now, you can use jsonData for further processing or submission to the server.
      console.log("JSON Data:", jsonData);

      api_call_token
        .post(`content/tests_answers/`, jsonData)
        .then((res) => {
          console.log(res?.data, "postapihit");
          setTestDone(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setOpen(true);
  };
  const submitTestClose = () => setOpen(false);
  const viewResultOpne = () => {
    setFinelOpne(true);
    setOpen(false);
  };
  const viewResultClose = () => setFinelOpne(false);

  // console.log(testDone, "DJAF8W");

  const handleQuestionOverviewClick = (questionId) => {
    const questionIndex = testQuestion.findIndex(
      (question) => question.id === questionId
    );
    if (questionIndex !== -1) {
      setCurrentQuestionIndex(questionIndex);
    }
  };

// ///////////////////////////////////////////////////////////////////////////
// for rules page
// ////////////////////////////////////////////////////////////////////////

  const handelRules = () => {
    setShowRule(1);
  };

  const getRuels =() =>{
    api_call_token
    .get(`content/V1/instructions`)
    .then((res)=>{
      setRulesApi(res?.data?.data)
      // console.log(res?.data, "rulesapi");
    })
    .catch((error) =>{
      console.log(error);
    })
  }

  useEffect(() => {
    getRuels();
  }, []);

//   console.log(currentRuningQ, "setCurrentRuningQ");
  return (
    <div className={styles.mainContainer}>
      <div
        onClick={handleBackClick}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        {/* <ArrowBackIcon /> */}
        <img src={backArrowbtn} alt='backarrow' />
        <p className={styles.ArrowBacktext}>Back</p>
      </div>

      {showRule == 0 && (
        <div className={styles.rulesContainer}>
          <h2 className={styles.rulesHeading}> 
          {title}</h2>
          <div className={styles.rulesImagesText}>
            <div className={styles.rImgText}>
              <div className={styles.rimgContainer}>
                <img src={list}></img>
              </div>
              <div className={styles.rTextContainer}>
                <h4>{marks}</h4>
                <p>Max. Marks</p>
              </div>
            </div>
            <div className={styles.rImgText}>
              <div className={styles.rimgContainer}>
                <img src={queRImg}></img>
              </div>
              <div className={styles.rTextContainer}>
                <h4>{total_question}</h4>
                <p>Total Ques.</p>
              </div>
            </div>
            <div className={styles.rImgText}>
              <div className={styles.rimgContainer}>
                <img src={Rtime}></img>
              </div>
              <div className={styles.rTextContainer}>
                <h4>{time}</h4>
                <p>Total Time</p>
              </div>
            </div>
          </div>
          <h3 className={styles.rulesH}>Rules:</h3>
          {
            rulesApi?.map((v) =>{
              return(
                <div key={v?.id} className={styles.noOfRules}>{v?.id}. {v?.text}</div>
              )
            })
          }
          <div className={styles.btnContainer}>
            <button className={styles.gotoText} onClick={() => {handelRules(); getTestData();}} >Take Test</button>
          </div>
        </div>
      )}
      {showRule == 1 && (
        <div className={styles.testContainer}>
          <div className={styles.testDetails}>
            <div className={styles.noofPages}>
              <p>
                Question {currentQuestionIndex + 1} of {tQuestion}
                {/* <span>{oneQtime?.seconds}</span> */}
              </p>
              <button className={styles.submitBtn} onClick={submitTestOpne}>
                Submit
              </button>
            </div>
            <div className={styles.totalTime}>
              <div className={styles.box}>
                <p className={styles.timer}>{formattedTime.hours}</p>
                <p className={styles.hr}>Hrs</p>
              </div>
              <div className={styles.box}>
                <p className={styles.timer}>{formattedTime.minutes}</p>
                <p className={styles.hr}>Min</p>
              </div>
              <div className={styles.box}>
                <p className={styles.timer}>{formattedTime.seconds}</p>
                <p className={styles.hr}>Sec</p>
              </div>
            </div>
          </div>
          <div className={styles.onlyMobileShow}>
          <div className={styles.totalTime}>
              <div className={styles.box}>
                <p className={styles.timer}>{formattedTime.hours}</p>
                <p className={styles.hr}>Hrs</p>
              </div>
              <div className={styles.box}>
                <p className={styles.timer}>{formattedTime.minutes}</p>
                <p className={styles.hr}>Min</p>
              </div>
              <div className={styles.box}>
                <p className={styles.timer}>{formattedTime.seconds}</p>
                <p className={styles.hr}>Sec</p>
              </div>
            </div>
            <button className={styles.submitBtn} style={{marginInlineStart:"auto"}} onClick={submitTestOpne}>
                Submit
              </button>
              <div className={styles.queOverviewMobile}>
              <div className={styles.Numberboxmobile}>
                {testQuestion?.map((info, index) => {
                  const isSelected = selectedAnswersJSON[info.id] !== undefined;
                  // console.log(selectedAnswersJSON, "isSelected");
                //   setCurrentRuningQ(isSelected)
                  return (
                    <>
                    
                      <div
                        className={`${styles.totalQestionBox} ${
                          isSelected ? styles.activequestion : ""
                        }`}
                        key={index}
                        onClick={() => handleQuestionOverviewClick(info.id)}
                      >
                        {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                      </div>
                    </>
                  );
                })}
              </div>
              </div>
          </div>
          <div className={styles.queContainer}>
          
            <div className={styles.queBox}>
            <div className={styles.quesMarks}><p>{testQuestion[currentQuestionIndex]?.positive_marks} M</p></div>
              <p className={styles.que}>
                {testQuestion[currentQuestionIndex]?.title}
              </p>
              {testQuestion[currentQuestionIndex]?.choices?.map(
                (info, index) => {
                  return (
                    <>
                      <button
                        className={`${styles.answer} ${
                          selectedAnswersJSON[
                            testQuestion[currentQuestionIndex]?.id
                          ] === info?.id
                            ? styles.yourAnswer
                            : ""
                        }`}
                        key={info?.id}
                        onClick={() =>
                          handelAnswerselect(
                            testQuestion[currentQuestionIndex]?.id,
                            info?.id
                          )
                        }
                      >
                        {String.fromCharCode(65 + index)}.
                        <div
                          dangerouslySetInnerHTML={{ __html: info?.title }}
                        />
                        {/* {info?.title} */}
                      </button>
                    </>
                  );
                }
              )}
              <div className={styles.navigation}>
                {/* Navigation buttons */}
                {
                  currentQuestionIndex > 0 && (
                    <button
                  className={styles.navBtn}
                  onClick={goToPreviousQuestion}
                  // disabled={currentQuestionIndex === 0}
                >
                  Previous
                </button>
                  )
                }
                
                {tQuestion == currentQuestionIndex + 1 ? (
                  <button className={styles.submitBtn} style={{marginInlineStart:"auto"}} onClick={submitTestOpne}>
                    Submit
                  </button>
                ) : (
                  <button className={styles.navBtn} style={{marginInlineStart:"auto"}} onClick={goToNextQuestion}>
                    Next
                  </button>
                )}
                {/* <button className={styles.navBtn} onClick={goToNextQuestion}>
                Next
              </button> */}
              </div>
            </div>
            <div className={styles.queOverview}>
              <h3>Question Overview</h3>
              <p style={{color:"rgba(0,0,0, 0.25)", fontSize:"16px", fontWeight:"400"}}>{tQuestion} Ques</p>
              <div className={styles.Numberbox}>
                {testQuestion?.map((info, index) => {
                  const isSelected = selectedAnswersJSON[info.id] !== undefined;
                  // console.log(selectedAnswersJSON, "isSelected");
                  return (
                    <>
                      <div
                        className={`${styles.totalQestionBox} ${
                          isSelected ? styles.activequestion : ""
                        }`}
                        key={index}
                        onClick={() => handleQuestionOverviewClick(info.id)}
                      >
                        {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                      </div>
                    </>
                  );
                })}
              </div>
              <div className={styles.boxtoinfo}>
                <span className={styles.inforefBox} style={{background:"#fff"}}></span><p>Not Answered</p>
              </div>
              <div className={styles.boxtoinfo}>
                <span className={styles.inforefBox}></span><p>Answered</p>
              </div>
              {/* <div className={styles.boxtoinfo}>
                <span className={inforefBox}></span><p>Not Answered</p>
              </div> */}
            </div>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={submitTestClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.mobileS}>
          <div className={styles.closebtn}>
            <CloseIcon onClick={submitTestClose} />
          </div>
          <div className={styles.resultModel}>
            <h3>Do you want to end this test?</h3>
            <p>
              If you want to check the answers , press cancel button. If you
              want to end the test and submit your answers you can press submit
              button.
            </p>
            <div className={styles.listTOShow}>
              {/* {modeldata?.map((info) => {
                return ( */}
              <div className={styles.iconlist}>
                <div className={styles.textIcon}>
                  <div className={styles.imgcontainer}>
                    <img src={Attempted} />
                  </div>
                  <p>Attempted</p>
                </div>
                <p className={styles.timeTeken}>
                  {testDone?.questions_attempted > 9
                    ? `${testDone?.questions_attempted}`
                    : ` 0${testDone?.questions_attempted}`}
                </p>
              </div>
              <div className={styles.iconlist}>
                <div className={styles.textIcon}>
                  <div className={styles.imgcontainer}>
                    <img src={Unattempted} />
                  </div>
                  <p>Unattempted</p>
                </div>
                <p className={styles.timeTeken}>
                  {testDone?.questions_not_attempted > 9
                    ? `${testDone?.questions_not_attempted}`
                    : ` 0${testDone?.questions_not_attempted}`}
                </p>
              </div>
              {/* //   );
              // })} */}
            </div>
            <div className={styles.btnContainer}>
              <button className={styles.cancelBtn} onClick={submitTestClose}>
                cancel
              </button>
              <button className={styles.viewBtn} onClick={viewResultOpne}>
                view result
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={finelOpne}
        onClose={viewResultClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.mobileS}>
          <div className={styles.closebtn}>
            <CloseIcon onClick={viewResultClose} />
          </div>
          <div className={styles.resultModel}>
            <h3>Congratulations !</h3>
            <p>Your test is successfully completed.</p>
            {/* <div className={styles.listTOShow}>
                  {
                    modeldata?.map((info) =>{
                      return(
                        <div className={styles.iconlist}>
                          <div className={styles.textIcon}><div className={styles.imgcontainer}><img src={info?.icon} /></div><p>{info?.title}</p></div>
                          <p className={styles.timeTeken}>{info?.totalnumbers}</p>
                        </div>
                      )
                    })
                  }
                </div> */}
            <div className={styles.btnContainer}>
              {/* <button className={styles.cancelBtn} onClick={viewResultClose}>cancel</button> */}
              <Link className={styles.viewBtn} onClick={handleResultView}>
                view result
              </Link>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default LzTestQue