import React from "react";
import styles from "./index.module.css";

function LeftRightcomponent(Data) {
  console.log(Data?.Color,"jhdskuashdajdh");

  const fontcolor= Data?.Color;
  return (
    // <div className={`font-blue`} >LeftRightcomponent</div>
    <ul className={`${styles.mainConatiner}`}>
      {Data?.Data?.map((v) => {
        return (
          <li className={`${styles.Imgtextcontainer}`}>
            <div className={` ${styles.ImageContainer}`}>
              <img src={v?.img} alt="" />
            </div>
            <div className={`${styles.TextContainer}`}>
              <h4 className={` ${styles.textHeading}`} style={{color:fontcolor}}>{v?.heading}</h4>
              <p className={`${styles.descriptionText}`} style={{color:fontcolor}}>{v?.para}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default LeftRightcomponent;
