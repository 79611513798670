import { Button, Paper, TextField } from "@material-ui/core";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import { api_call, setApiToken } from "../../Utils/Network";
import CheckIcon from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
// import { UserCredsContext } from "../../ContextApi/UserCredContext/UserCredsContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StudImg from "../../Assets/1x/asset/girlrev.png";
import studs from "../../Assets/1x/asset/asset10.png";
import { makeStyles } from "@material-ui/core";

// import { withRouter } from 'react-router-dom';

export const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
export const PasswordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const PhoneRegex = /^[0-9][0-9]{9}$/;
export const ZipCodeRegex = /^[0-9]{6}$/;

function falseChecker(value) {
  if (
    value !== "" &&
    value !== undefined &&
    value !== null &&
    value !== false
  ) {
    return true;
  }
  return false;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  highlight: {
    backgroundwidth: "red",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
}));

const ForgetPassword = ({ closeModal, ...props }) => {
  const {
    LoginType,
    updateLoginType,
    setToken,
    setUser,
    userType,
    updateUserType,
  } = useContext(UserCredsContext);
  let history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    phone: "",
    otp: "",
    password: "",
    confirm_password: "",
    password_changed: false,
    getOtp: false,
  });
  const [samePassword, setSamePassword] = useState("");

  const [alerts, setAlerts] = React.useState({
    phone: "",
  });
  setTimeout(() => {
    setIsDisable(false);
  }, 30000);

  // const { toggleLoginCard } = React.useContext(MainDataContextApi)

  const submitPhone = (event) => {
    event.preventDefault();
    const { phone } = state;

    setAlerts((prev_alert) => ({
      ...prev_alert,
      phone: PhoneRegex.test(phone) ? "" : "Invalid phone number.",
    }));

    if (PhoneRegex.test(phone)) {
      api_call
        .post(`/auth/lcarro/forgot_password/?phone=${phone}`)
        .then((response) => {
          if (response.data.data.status === false) {
            if (response.data.data.message === "No such user") {
              alert("No such user");
            }
          } else {
            handleChanges("getOtp", true);
          }
        });
    }
  };
  const handleClick = () => {
    updateLoginType(0);
  };

  const [isdisable, setIsDisable] = useState(true);

  const submitPhone2 = (event) => {
    event.preventDefault();
    const { phone } = state;
    setIsDisable(true);

    setAlerts((prev_alert) => ({
      ...prev_alert,
      phone: PhoneRegex.test(phone) ? "" : "Invalid phone number.",
    }));

    if (PhoneRegex.test(phone)) {
      api_call
        .post(`/auth/lcarro/forgot_password/?phone=${phone}`)
        .then((response) => {
          handleChanges("getOtp", true);
        });
    }

    setTimeout(() => {
      setIsDisable(false);
    }, 3000);
  };

  const handleChanges = (name, value) => {
    setState((prev_props) => ({ ...prev_props, [name]: value }));
  };

  const submitNewPassword = (event) => {
    event.preventDefault();
    const { phone, otp, password, confirm_password } = state;
    setSamePassword("");
    setAlerts((prev_alerts) => ({
      ...prev_alerts,
      otp: falseChecker(otp) ? "" : "Enter OTP.",
      password: PasswordRegex.test(password)
        ? ""
        : "Password length must be 8. Password must contain a Upper case, Lower case , a number and a special character.",
      confirm_password:
        password === confirm_password ? "" : "Password don't match.",
    }));

    if (
      falseChecker(otp) &&
      PasswordRegex.test(password) &&
      password === confirm_password
    ) {
      api_call
        .post(`/auth/lcarro/forgot_password_otp_check/`, {
          phone,
          otp,
          password,
          confirm_password,
        })
        .then((response) => {
          if (response.data.data.status === false) {
            if (response.data.data.message === "Wrong OTP") {
              setAlerts((prev_alerts) => ({
                ...prev_alerts,
                otp: response.data.data.message,
              }));
            } else if (
              response.data.data.message ==
              "New password must not be older password"
            ) {
              setSamePassword(response.data.data.message);
            } else {
              setState({ ...state, showError: true });
            }
          } else {
            setState((prev_state) => ({
              ...prev_state,
              password_changed: true,
            }));

            setTimeout(() => {
              // history.push("/")
              closeModal();
            }, 2000);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setAlerts((prev_alerts) => ({
              ...prev_alerts,
              otp: "Invalid OTP.",
            }));
          }
        });
    }
  };

  if (state.password_changed) {
    return (
      <div>
        <ForgetPasswordCtn className={classes.highlight.backgroundwidth}>
          <CheckIcon
            style={{ fontSize: "60px", color: "green", fill: "green" }}
          />
          <h2 style={{ color: "grey" }}>Password changed successfully.</h2>
        </ForgetPasswordCtn>
      </div>
    );
  }

  const useTFprops = (name, style = {}) => {
    if (typeof style !== "object" && style !== null) {
      throw new TypeError("Invalid type for style.Object required.");
    }
    const bind = {
      label: name,
      name: name.toLowerCase(),
      variant: "outlined",
      style: { width: "100%", margin: "10px", ...style },
    };
    return bind;
  };

  const useBtnProps = {
    color: "secondary",
    variant: "contained",
  };

  const useBtnPropsPrimary = {
    color: "primary",
    variant: "contained",
  };

  const OutlinedTfProp = {
    style: {
      margin: "10px auto",
      width: "90%",
    },
    variant: "outlined",
  };

  return (
    <div className="signin_content">
      <div className="stud_dialogue stud_dialogs">
        <img src={StudImg} alt="StudentIcon" className="studImg"></img>
      </div>
      zxzx
      <div className="sign_cont_box">
        <ForgetPasswordCtn
          className={classes.highlight.backgroundwidth}
          style={{
            margin: 0,
            boxSizing: "border-box",
            width: "100%",
            boxShadow: "none",
          }}
        >
          {state.getOtp ? (
            <div>
              <form onSubmit={submitNewPassword}>
                <TextField
                  label="OTP"
                  style={{ width: "150px", margin: "10px auto" }}
                  variant="outlined"
                  required
                  value={state.otp}
                  onChange={(e) => handleChanges("otp", e.target.value)}
                  error={falseChecker(alerts.otp)}
                  helperText={alerts.otp}
                />

                <TextField
                  type="password"
                  label="Password"
                  {...OutlinedTfProp}
                  required
                  value={state.password}
                  onChange={(e) => handleChanges("password", e.target.value)}
                  error={falseChecker(alerts.password)}
                  helperText={alerts.password}
                />

                <TextField
                  type="password"
                  label="Confirm Password"
                  {...OutlinedTfProp}
                  required
                  value={state.confirm_password}
                  onChange={(e) =>
                    handleChanges("confirm_password", e.target.value)
                  }
                  error={falseChecker(alerts.confirm_password)}
                  helperText={alerts.confirm_password}
                />
                <p style={{ color: "red" }}>{samePassword}</p>
                <Button onClick={submitPhone2} disabled={isdisable}>
                  Resend OTP
                </Button>
                <div style={{ marginTop: "10px" }}>
                  <Button
                    {...useBtnPropsPrimary}
                    type="submit"
                    style={{
                      borderRadius: "8px",
                      width: "82%",
                      fontSize: "18px",
                      fontWeight: "550",
                      backgroundColor: "#fff;",
                      color: "rgb(3, 44, 107)",
                    }}
                  >
                    set new password
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {/* <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleClick()}
            >
              <ArrowBackIcon />
              Back
            </div> */}
              <form onSubmit={submitPhone} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Phone"
                    {...OutlinedTfProp}
                    required
                    value={state.phone}
                    onChange={(e) => handleChanges("phone", e.target.value)}
                    error={falseChecker(alerts.phone)}
                    helperText={alerts.phone}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Button
                    {...useBtnPropsPrimary}
                    type="submit"
                    style={{
                      borderRadius: "8px",
                      width: "82%",
                      fontSize: "18px",
                      fontWeight: "550",
                      backgroundColor: "#fff;",
                      color: "rgb(3, 44, 107)",
                    }}
                  >
                    Get OTP
                  </Button>
                </div>
              </form>
            </div>
          )}
        </ForgetPasswordCtn>
      </div>
    </div>
  );
};

export default ForgetPassword;

const ForgetPasswordCtn = styled(Paper)`
    width: 380px;
    margin: 0 auto;
    margin-top: 10px;
    height:fit-content;
    text-align:center;
    padding:20px 10px;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
}`;
