import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import StudeIcon from "../../Assets/Onboard/STUDENT w.png";
import StudImg from "../../Assets/1x/asset/girl.png";
import woo from "../../Assets/1x/asset/woo.png";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import ProfileDrop from "../ProfileDrop/ProfileDrop";
import styles from "./Drawer.module.css";
import {
  login_api,
  api_token,
  api_call,
  setApiToken,
} from "../../Utils/Network";
import Login from "../DialogBox/Login";
import Authenticate from "../DialogBox/Authenticate";
import FormDetail from "../DialogBox/FormDetail";
import Success from "../DialogBox/Success";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ForgetPassword from "../DialogBox/ForgotPassword";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  // BackdropProps : {
  //   backgroundColor: "transparent",
  //   paddingTop: '50px',
  // }
});

function Drawer() {
  const {
    loginData,
    updateLoginData,
    studentProfile,
    updateUserType,
    updatePartnerType,
    displayType: disType,
    setDisplayType: setDisType,
    openModel,
    setOpenModel,
    token,
    setToken,
    setUser,
    user,
    redirectMyspace,
    setRedirectMyspace,
    logout,
  } = useContext(UserCredsContext);
  const classes = useStyles();

  const [state, setState] = React.useState(false);
  const [displayType, setDisplayType] = useState(null);
  const [usersign, setUsrSign] = useState([]);
  const [errors, setErrors] = useState({});
  const [loginD, setLogin] = useState({
    username: "",
    password: "",
  });
  const temp = {};
  const handelLoginData = (event) => {
    console.log(event.target.value);
    const { name, value } = event.target;

    setLogin({ ...loginD, [name]: value });
  };

  const setuserSign = () => {
    login_api
      .get(`/auth/user_signup_types`)
      .then((response) => {
        var [student, teacher, school, online, institute] = response.data.data;
        setUsrSign([student, teacher, institute, online, school]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*User Redirect towards form*/
  const setType = (_data) => {
    updateUserType(_data.user_type);
    updatePartnerType(_data.partner_type);
    setDisplayType(2);
    if (_data.user_type == 8) {
      // history.push('/teachersregistration')
      window.open("https://coaching.lcarro.com/#/", "_blank");
      handleClose();
    } else if (_data.user_type == 7 && _data.partner_type == 1) {
      window.open("https://coaching.lcarro.com/#/", "_blank");
      handleClose();
    }
  };
  /*End*/
  useEffect(() => {
    setuserSign([]);
    updateLoginData({
      code: "+91",
      phone: "9773526679",
      otp: "",
      type: "login",
    });
  }, []);

  const validate = () => {
    let regEx = /^[0-9a-zA-Z]+$/;
    let returnValue = true;
    temp.username =
      loginD.username === ""
        ? "Please enter username"
        : loginD.username.match(regEx)
        ? ""
        : "Please enter proper username";
    temp.password = loginD.password === "" ? "Please enter password" : "";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const handleClickOpen = () => {
    setDisplayType(2);
    updateUserType(1);
    if (token.access) {
      return;
    }
    // setOpenModel(true);
    tggle(22);
  };

  /* Authentication UI*/
  const enterDetails = () => {
    return (
      <>
        {/* {loginData.type === "login" || loginData.type == "auth" ? (
          <div className="stud_dialogues" style={{ textAlign: "center", marginBottom: '-5px' }}>
            <img src={StudImg} alt="StudentIcon" className="studImg"></img>
          </div>
        ) : (loginData.type === "subm" ? (
          <div className="stud_dialogues" style={{ textAlign: "center", marginBottom: '-5px'  }}>
            <img src={woo} alt="StudentIcon" className="studImg"></img>
          </div>):
          <></>
        )} */}
        <div className="Containers_popup">
          <div className="modal_head">
            <img src={StudeIcon} alt="Student Icon" className="StudIcon"></img>
            <h1>Sign up</h1>
          </div>
          <div className={styles.popup_content}>
            {/* <div className="stud_dialogue">
            <img src={StudImg} alt="StudentIcon" className="studImg"></img>
          </div> */}
            <div className={styles.stud_content}>
              {loginData.type === "login" && <Login />}
              {loginData.type === "auth" && (
                <Authenticate closeModal={handleClose} />
              )}
              {loginData.type === "usr" && <FormDetail />}
              {loginData.type === "subm" && (
                <Success closeModal={handleClose} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  /** Sign Up UI**/
  const getDetails = () => {
    return (
      <div className="Containers_popup">
        <div className="modal_head">
          <h1 className={styles.Signup}>Sign up as</h1>
        </div>
        <div className="body">
          <div className="body_container bdy_ptnr">
            {usersign &&
              usersign !== undefined &&
              usersign !== 0 &&
              usersign.map((itm, indx) => {
                return (
                  <div className="bdy_box" onClick={() => setType(itm)}>
                    <img
                      src={itm.icon}
                      alt="teacher"
                      className={styles.icon_model}
                    ></img>
                    <p>{itm.title}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  /*End of Sign up UI*/

  /*** Login button Submit ***/
  const SubmitLogins = () => {
    if (!validate()) {
      return null;
    }

    var loginDetails = {
      username: loginD.username,
      password: loginD.password,
    };

    api_call
      .get(`auth/user/unique_username_check/?username=${loginD.username}`)
      .then((response) => {
        const { user_type } = response.data.data;
        if (user_type && user_type === 1) {
          api_call
            .post(`/auth/user/login/`, loginDetails)
            .then((response) => {
              if (redirectMyspace.redirect) {
                //Redirect to MySpace Logic
              } else {
                setRedirectMyspace(true, true);
              }
              localStorage.setItem(
                "network_access_token",
                response.data.data.token.access
              );
              setApiToken(response.data.data.token.access);
              setToken(response.data.data.token);
              setUser(response.data.data.user);
              setState(false);
            })
            .catch((error) => {
              if (error) {
                const { message } = error.response.data.error;
                setErrors({ message });
              }
            });
        } else {
          setDisplayType(5);
        }
      })
      .catch((error) => {
        if (error) {
          // console.log(error.response.data.error.message, "ErrorLogin");
          // const {message} = error.response.data.error;
          // setErrors({message})
        }
      });
  };

  const selectLoginType = () => {
    return (
      <div className="Containers_popup">
        <div className="modal_head">
          <h1>Select</h1>
        </div>
        <div className={styles.s_ups_content}>
          <div
            className={styles.signContent}
            onClick={() => {
              tggle(11);
            }}
          >
            Sign in
          </div>
          <div
            className={styles.signContent}
            onClick={() => {
              tggle(22);
            }}
          >
            Sign up
          </div>
        </div>
      </div>
    );
  };

  // Login UI
  const getLogin = () => {
    return (
      <div className="Containers_popup">
        <div className="modal_head">
          <h1>Sign in</h1>
        </div>
        <div className={styles.signin_content}>
          <div className={styles.sign_cont_box}>
            <div className={styles.signin_input_box}>
              <input
                name="username"
                placeholder="mobile number"
                onChange={handelLoginData}
                className="signin_input"
              ></input>
              <p className="error-message-text error-marginbottom">
                {errors.username}
              </p>
            </div>
            <div className={styles.signin_input_box}>
              <input
                name="password"
                type="password"
                placeholder="password"
                onChange={handelLoginData}
                className="signin_input"
              ></input>
              <p className="error-message-text error-marginbottom">
                {errors.password}
              </p>
              <p className="error-message-text error-marginbottom">
                {errors.message}
              </p>
            </div>
            <div className="signin_input_box">
              <p className="forgot_pass" onClick={() => setDisplayType(9)}>
                Forgot password ?
              </p>
            </div>
            <div>
              <Button
                variant="contained"
                className="btn_model"
                onClick={SubmitLogins}
                color="primary"
                style={{
                  borderRadius: "12px",
                  width: "80%",
                  fontSize: "18px",
                  fontWeight: "550",
                }}
                //disabled={enable}
              >
                Login
              </Button>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                variant="contained"
                className="btn_model"
                onClick={handleClickOpen}
                color="primary"
                style={{
                  borderRadius: "8px",
                  width: "82%",
                  fontSize: "18px",
                  fontWeight: "550",
                  backgroundColor: "#fff",
                  color: "rgb(3, 44, 107)",
                  fontWeight: "bold",
                }}
                // disabled={enable}
              >
                New User Sign in?
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**Close**/
  const sorryDetails = () => {
    return (
      <>
        <div className="modal_head">
          <img src={StudeIcon} alt="Student Icon" className="StudIcon"></img>
          <h1>Sign up</h1>
        </div>
        <div className="oops_content">
          <h3 className="sign_err">Only Student can Sign in through browser</h3>
          <h3 className="sign_err">
            Other Users can Sign in through mobile app
          </h3>
        </div>
      </>
    );
  };

  const handleClose = () => {
    setState(false);
  };

  const toggleDrawer = (open) => (event) => {
    setState(open);
    setDisplayType(7);
  };

  const tggle = (x) => {
    console.log(x, "xxxxx");
    if (x === 11) {
      setDisplayType(0);
      if (token.access) {
        return;
      }
    } else if (x === 22) {
      setDisplayType(2);
      if (token.access) {
        return;
      }
    }
  };
  return (
    <div>
      <div className="lgn">
        {token.access ? (
          (user?.first_name !== null &&
            user?.first_name !== undefined &&
            user.first_name) ||
          (user.hasOwnProperty("institute") &&
            user.institute.name !== null &&
            user.institute.name !== undefined &&
            user.institute.name) ? (
            <ProfileDrop datas={user} />
          ) : (
            <>User</>
          )
        ) : (
          <>
            <AccountCircleIcon
              style={{ color: "#032C6BA1", margin: "10px", fontSize: "40px" }}
              onClick={toggleDrawer(true)}
            />
            {/* <button className="navsgn nav-signin" onClick={toggleDrawer(true, 1)}>
              Sign in
            </button>
            <button className="navsgn nav-signup" onClick={toggleDrawer(true, 2)}>
              Sign up
            </button> */}
          </>
        )}
      </div>
      <SwipeableDrawer
        anchor={"bottom"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{ paper: classes.BackdropProps }}
        style={{ backgroundColor: "transparent" }}
        BackdropProps={{ invisible: true }}
      >
        {displayType === 9 && <ForgetPassword closeModal={handleClose} />}
        {displayType === 7 && selectLoginType()}
        {displayType === 0 && getLogin()}
        {/* {displayType === 1 && getDetails()} */}
        {displayType === 2 && enterDetails()}
        {displayType === 5 && sorryDetails()}
      </SwipeableDrawer>
    </div>
  );
}

export default Drawer;
