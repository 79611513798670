import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import './ConceptHead.css';
import { ToggleState } from '../../Utils/CustomHooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SubConceptHead from '../SubConceptHead/SubConceptHead';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

const ConceptHead = ({ concept, ...props }) => {

    const [expand, setExpand, bindExpand] = ToggleState();

    return (
        <ConceptTitle>
            <div>

            </div>
            <div className="concept-lbl-div">
                <FiberManualRecordRoundedIcon style={{ color: "#bbb" ,fontSize:'15px'}} />&nbsp; {concept.title} 
                {/* <div className="exp-icn-con-con" {...bindExpand}>{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div> */}
            </div>

            <div>

            </div>

            {/* <section style={{display : expand ? "unset" : "none"}}>

                <ConceptContent>

                    {Array.isArray(concept.sub_concepts) && concept.sub_concepts.map(sub_concept => <SubConceptHead subconcept={sub_concept} />)}

                </ConceptContent>
            </section> */}

        </ConceptTitle>
    );
};

ConceptHead.propTypes = {

};



const ConceptContent = styled.div`

padding:10px 10px 10px 50px;

`
export default ConceptHead;



const ConceptTitle = styled.div`

margin:10px;

`
