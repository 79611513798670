import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Caro.css";

export default class Caro extends Component {
  render() {
    const { imgs } = this.props;
    console.log(imgs.data, "SQUARD");
    var settings = {
      className: "",
      dots: true,
      // autoplay: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: true,
      appendDots: (dots) => (
        <div>
          <ul> {dots} </ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{ border: "2px solid white", padding: "0px 80px" }}
          className="update-slick"
        ></div>
      ),
    };
    return (
      <div>
        <Slider {...settings}>
          {imgs && imgs.data !== undefined && imgs.data.map((x, i) => (
              <img src={x.image} alt="Img"></img>
          ))}
        </Slider>
      </div>
    );
  }
}


