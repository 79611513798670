import React, { useEffect } from "react";
import logofoot from "../../Assets/lcarrologo.png";
import logo from "../../Assets/Nav_Bar/Assets/1x/Asset 4.png";
import playStoreIcon from "../../Assets/HomeRewamp/Download_Play_Store.png";
import appStoreIcon from "../../Assets/HomeRewamp/Download_App_Store.svg";
import { Link } from "react-router-dom";
import "./Footer.css";
import FacebookIcon from "../../Assets/brainhap/fb.svg";
import InstagramIcon from "../../Assets/brainhap/insta.svg";
import TwitterIcon from "../../Assets/brainhap/twtter.svg";
import LinkedInIcon from "../../Assets/brainhap/in.svg";
import footerLogo from "../../Assets/brainhap/footerLogo.png";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import ExploreDesk from "../Explore_desk/ExploreDesk";
import Popovr from "../Popover/Popovr";

function Footer() {
  return (
    <div className=" ">
      <div className="textLogo">
        <img src={logo} alt="logofooter" className="logofooter"></img>
      </div>
      <div className="footer_container footer_main">
        <div className="footer_details footer_looggo">
          <ul>
            <li className="heads_foot">
              <img src={footerLogo} alt="logoss" />
            </li>
            <div className=" hed_ls footer_looggo">
              <li className="heads-footer">
                <a target="_blank">
                  <img src={FacebookIcon} alt="logoss" />
                </a>
              </li>
              <li className="heads-footer">
                <a target="_blank">
                  <img src={InstagramIcon} alt="logoss" />
                </a>
              </li>
              <li className="heads-footer">
                <img src={TwitterIcon} alt="logoss" />
              </li>
              <li className="heads-footer">
                <a target="_blank">
                  <img src={LinkedInIcon} alt="logoss" />
                </a>
              </li>
            </div>
          </ul>
        </div>

        <div className="footer_details footer_comp">
          <ul>
            <li className="heads_foot">Company</li>
            <div className="head_list">
              <Link to="/about-us" className="heads-footer">
                About us
              </Link>
              {/* <li className="heads-footer">Career</li> */}
              <Link to="/termsandcondition" className="heads-footer">
                Terms and Conditions
              </Link>
              <Link to="/privacy" className="heads-footer">
                Privacy
              </Link>
              <Link to="/contact" className="heads-footer">
                Contact us
              </Link>
              <Link to="/refundandcancel" className="heads-footer">
                Refund & Cancellation Policy
              </Link>
              <Link to="/teachonlcarro" className="heads-footer">
                Teach on Brainhap
              </Link>
            </div>
          </ul>
        </div>
        <div className="footer_details footer_comp">
          <ul>
            <li className="heads_foot">Explore</li>
            <div className=" hed_ls">
              <ExploreDesk />
            </div>
          </ul>
        </div>
        <div
          className="footer_details footer_comp"
          style={{ textAlign: "center", padding: "6px", color: "#fff" }}
        >
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "left",
              marginLeft: "10px",
             fontSize:"21px",
              width: "fit-content",
            }}
          >
            Contact us
          </div>
          <div className="inline-flex-center">
            <DraftsOutlinedIcon className="legend-icon" />
            <h4>psychology@brainhap.com</h4>
          </div>
          <p className="">Download the app for free</p>
          <div
            className=""
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <a target="_blank">
              <img
                className="img-fluid "
                src={playStoreIcon}
                alt={playStoreIcon}
                style={{ width: "110px", height: "auto", margin: "5px" }}
              />
            </a>
            <a target="_blank">
              <img
                className="img-fluid "
                src={appStoreIcon}
                alt={appStoreIcon}
                style={{ width: "110px", height: "auto", margin: "5px" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
