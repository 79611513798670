import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { login_api } from "../../Utils/Network";
import scope from "../../Assets/Nav_Bar/Assets/1x/Asset 22.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(0),
    width: "300px",
  },
}));

function ExploreDesk(props) {
  const { setFilter } = useContext(UserCredsContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEls, setAnchorEls] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [exam, setExam] = useState([]);
  const [grades, setGrade] = useState([]);
  const [boards, setBoard] = useState([]);
  const [tryfree, setSession] = useState([]);
  const [isActive, setActive] = useState(true);
  const [drawer, setDrawer] = React.useState(false);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(false);
    setDrawer(true);
  };
  const handleClick1 = (event) => {
    setAnchorEls(event.currentTarget);
    setActive(false);
    setDrawer(true);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setActive(false);
    setDrawer(true);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
    setActive(false);
    setDrawer(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEls(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setActive(true);
    setDrawer(false);
  };

  const getExam = () => {
    login_api
      .get(`/base/exam_type_mapping/`)
      .then((response) => {
        setExam(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGrade = () => {
    login_api
      .get(`/base/grade-type-detail/`)
      .then((response) => {
        setGrade(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBoard = () => {
    login_api
      .get(`/base/board/`)
      .then((response) => {
        setBoard(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTryFree = () => {
    login_api
      .get(`/base/session/`)
      .then((response) => {
        setSession(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleFilter = (prop, x, y = "") => {
    setActive(true);
    setDrawer(false);
    setFilter({
      type: prop,
      value: x,
      title: y,
    });
    setAnchorEl(null);
    history.push("/explore");
  };

  useEffect(() => {
    getExam();
    getGrade();
    getBoard();
    getTryFree();
  }, []);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEls);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const id = open ? "simple-popover" : undefined;
  const ids = open1 ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover" : undefined;
  const id3 = open3 ? "simple-popover" : undefined;
  return (
    // <div className={styles.exp_main_box}>
    <div className="head_list">
        <p
          aria-describedby={id}
          color="primary"
          variant="contained"
          onClick={handleClick}
          className={"heads-footer"}
        >
          Grade
        </p>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="ppr"
        >
          <Typography
            className={classes.typography}
            style={{ borderRadius: "13px" }}
          >
            <ul>
              {grades
                .sort(function (a, b) {
                  return a.id - b.id;
                })
                .slice(0, 5)
                .map((item, x) => {
                  return (
                    <>
                      <li
                        onClick={() =>
                          handleFilter("grade", item.grade, "grade")
                        }
                      >
                        {item.grade_type.title}
                      </li>
                    </>
                  );
                })}
            </ul>
          </Typography>
        </Popover>

      <p
        aria-describedby={ids}
        className={"heads-footer"}
        color="primary"
        variant="contained"
        onClick={handleClick1}
      >
        Exams
      </p>
      <Popover
        id={ids}
        open={open1}
        anchorEl={anchorEls}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="ppr"
      >
        <Typography
          className={classes.typography}
          style={{ borderRadius: "13px" }}
        >
          <ul>
            {exam.slice(0, 5).map((item, x) => {
              return (
                <>
                  <li onClick={() => handleFilter("exam", item.exam)}>
                    {item.exam_type.title}
                  </li>
                </>
              );
            })}
          </ul>
        </Typography>
      </Popover>

      <p
        aria-describedby={id2}
        className={"heads-footer"}
        color="primary"
        variant="contained"
        onClick={handleClick2}
      >
        Boards
      </p>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="ppr"
      >
        <Typography
          className={classes.typography}
          style={{ borderRadius: "13px" }}
        >
          <ul>
            {boards.slice(0, 5).map((item, x) => {
              return (
                <>
                  <li
                    onClick={() => handleFilter("board", item.id, item.title)}
                  >
                    {item.title}
                  </li>
                </>
              );
            })}
          </ul>
        </Typography>
      </Popover>

      <p
        aria-describedby={id3}
        className={"heads-footer"}
        color="primary"
        variant="contained"
        onClick={handleClick3}
      >
        Try for free
      </p>
      <Popover
        id={id3}
        open={open3}
        anchorEl={anchorEl3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="ppr"
      >
        <Typography
          className={classes.typography}
          style={{ borderRadius: "13px" }}
        >
          <ul>
            {tryfree.slice(0, 5).map((item, x) => {
              return (
                <>
                  <li onClick={() => handleFilter("session", item.id)}>
                    {item.title}
                  </li>
                </>
              );
            })}
          </ul>
        </Typography>
      </Popover>
    </div>
  );
}

export default ExploreDesk;
