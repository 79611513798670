import React , { Fragment, useEffect } from "react";
import "./App.css";
import AppRouter from "./Router/AppRouter.js";
import { HashRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga';

function loadSrc(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    console.log(script, 'script')
    script.src = src;
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false);
    }
    document.body.appendChild(script)
  })
}

function App() {

  useEffect(() => {

    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6253fa6bb0d10b6f3e6cd955/1g0c0kduu';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    getData()
  }, []);

  async function getData() {
    const res = await loadSrc('https://www.googletagmanager.com/gtag/js?id=G-T9C8QNVXZ4')
    console.log(res, 'resres')
    if (res) {
      console.log(window.dataLayer, 'layer')
      var { dataLayer } = window;
      dataLayer = dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-T9C8QNVXZ4');
    }
  };

  return (
    <>
      <Router>
        <AppRouter />
      </Router>
    </>
  );
}

export default App;
