import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import bannerimg from "../../Assets/bannernew1.png";
import StemAccredition from "../../Assets/Stem-Accredition .png";
import explorbanner from "../../Assets/explorBackgound.png";
import iitbombey from "../../Assets/iitbombeylogo.png";
import { Button, TextField } from "@material-ui/core";
import supp1 from "../../Assets/HomeRewamp/sir.jpeg";
import supp2 from "../../Assets/HomeRewamp/supportBy2.png";
import ArrowForward from "@material-ui/icons/ArrowForward";
import vector from "../../Assets/howtodovectorbg.png";
import careermappingtest from "../../Assets/career mapping test.png";
import careercounselling from "../../Assets/career counselling.png";
import brainMappingtest from "../../Assets/brain mapping test.png";
import neuromentoring from "../../Assets/neuromentoring.png";
import stemcourse from "../../Assets/STEM course.png";
import careermapping from "../../Assets/CareerMapping.png";
import brainmapping from "../../Assets/BrainMapping.png";
import neuromen from "../../Assets/Neuromentoring.png";
import stemLab from "../../Assets/StemLab.png";
import Carousel from "react-multi-carousel";
// import { responsiveCourses } from "../../Utils/Utils";
import imgone from "../../Assets/NewHomepageImg/G-img-1.png";
import imgtwo from "../../Assets/NewHomepageImg/G-img-2.png";
import imgthree from "../../Assets/NewHomepageImg/G-img-3.png";
import imgfour from "../../Assets/NewHomepageImg/G-img-4.png";
import imgfive from "../../Assets/NewHomepageImg/G-img-5.png";
import imgsix from "../../Assets/NewHomepageImg/G-img-6.png";
import imgseven from "../../Assets/NewHomepageImg/G-img-7.png";
import imgeight from "../../Assets/NewHomepageImg/G-img-8.png";
import imgnine from "../../Assets/NewHomepageImg/G-img-9.png";
import NewCarousel from "../../Components/Carousels/newCarousel";
import { InputAdornment } from "@mui/material";
import mobilevector from "../../Assets/NewHomepageImg/howtodovectorbg-mobile.png";
import imgmone from "../../Assets/NewHomepageImg/MG-img-1.png";
import imgmtwo from "../../Assets/NewHomepageImg/MG-img-2.png";
import imgmthree from "../../Assets/NewHomepageImg/MG-img-3.png";
import imgmfour from "../../Assets/NewHomepageImg/MG-img-4.png";
import imgmfive from "../../Assets/NewHomepageImg/MG-img-5.png";
import imgmsix from "../../Assets/NewHomepageImg/MG-img-6.png";
import imgmseven from "../../Assets/NewHomepageImg/MG-img-7.png";
import imgmeight from "../../Assets/NewHomepageImg/MG-img-8.png";
import imgmnine from "../../Assets/NewHomepageImg/MG-img-9.png";
import bannermobile from "../../Assets/NewHomepageImg/Banner 1(boy).png";
import exploricon1 from "../../Assets/NewHomepageImg/exploricon1.png";
import exploricon2 from "../../Assets/NewHomepageImg/exploricon2.png";
import ricon1 from "../../Assets/NewHomepageImg/rIcon-1.png";
import ricon2 from "../../Assets/NewHomepageImg/ricon-2.png";
import ricon3 from "../../Assets/NewHomepageImg/ricon-3.png";
import workIcon1 from "../../Assets/NewHomepageImg/workicon-1.png";
import workIcon2 from "../../Assets/NewHomepageImg/workicon-2.png";
import workIcon3 from "../../Assets/NewHomepageImg/workicon-3.png";
import workIcon4 from "../../Assets/NewHomepageImg/workicon-4.png";
import workIcon5 from "../../Assets/NewHomepageImg/workicon-5.png";
import workIcon6 from "../../Assets/NewHomepageImg/workicon-6.png";
import cf1 from "../../Assets/NewHomepageImg/cf-1.png";
import cf2 from "../../Assets/NewHomepageImg/cf-2.png";
import gicon from "../../Assets/NewHomepageImg/galleryicon1.png";
import psicon1 from "../../Assets/NewHomepageImg/ps-1.png";
import psicon2 from "../../Assets/NewHomepageImg/ps-2.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import { api_call } from "../../Utils/Network";
import { Snackbar, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ExplorMore from "../../Components/Newdesigncomponets/ExploreMore/ExplorMore";
import CheckIcon from '@material-ui/icons/Check';
import Grow from '@material-ui/core/Grow';
import BannerSlider from "../../Components/Newdesigncomponets/NewBanneSlider/BannerSlider";
import bannerone from "../../Assets/NewHomepageImg/bannerboy-1.png"
import bannertwo from "../../Assets/NewHomepageImg/bannertwo.png"
import bannerthree from "../../Assets/NewHomepageImg/banerthree.png"
// import img from "../../Assets/NewHomepageImg/G-img-1.png"

// function GrowTransition(props) {
//   return <Grow {...props} />;
// }

function HomePageNew() {
  const grades = [5, 6, 7, 8, 9, 10, 11, 12];
  const [activeIndex, setActiveIndex] = useState(null);
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState({ name: "" });
  const { setDisplayType, setOpenModel, user } = useContext(UserCredsContext);
  const [testType, setTestType] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const history = useHistory();
  const [userData, setUserData] = useState({
    contact_person_name: "",
    // c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
  });
  var temp = {};

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.contact_person_name =
      userData.contact_person_name === "" ? "Please enter Your Name" : "";
    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    temp.c_email = userData.c_email.match(validEmail)
      ? ""
      : "Please Enter valid email";
    temp.c_message = userData.c_message === "" ? "Please choose the Grade" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const responsiveCoursesgallry = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleshowactive = (i, v) => {
    console.log(v, "needtoconform");
    setActiveIndex(i);
    const selectedIndex = selectedGrades.indexOf(v);
    // const massage = {c_message:}
    // setUserData( massage)
    //   if (v != ""){
    //   setUserData({ ...userData, c_message: `Interested in this Grades ${v}th` });
    // }
    // If the grade is not selected, add it to the array.

    if (v != ""){
    if (selectedIndex === -1) {
      selectedGrades.push(v);
      setSelectedGrades([...selectedGrades]);
      setUserData({
        ...userData,
        c_message: `Interested in this Grades ${selectedGrades.join("th, ")}th`,
      });
    } else {
      // If the grade is already selected, remove it from the array.
      const updatedGrades = [...selectedGrades];
      updatedGrades.splice(selectedIndex, 1);
      setSelectedGrades(updatedGrades);
      setUserData({
        ...userData,
        c_message: `Interested in this Grades ${updatedGrades.join("th, ")}th`,
      });
    }
  }
    // const mes = `Interested in this Grades ${selectedGrades.join('th, ')}th`;
  };

  console.log("USERSAAAAA", userData);

  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };
  console.log(userData, "showuserdata");
  const submitForm = () => {
    if (!validate()) return null;
    var payload = {
      first_name: userData.contact_person_name,
      // last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
    };

    console.log(payload, "contactfromdata");

    // if(payload){
    //   setMsg("Thank You! Your submission has been sent.");
    // }
    // setMsg("Thank You! Your submission has been sent.");
    api_call
    .post("contact/general/", payload)
      .then((x) => {
        setMsg("Thank You! Your submission has been sent.");
        setUserData({
          contact_person_name: "",
          c_phone: "",
          c_email: "",
          c_message: "",
        });
        document.getElementById("outlined-name").value = "";
        document.getElementById("outlined-email").value = "";
        document.getElementById("outlined-phone").value = "";
        setSelectedGrades([])
      })
      .catch((error) => console.log(error));
  };

  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  return (
    <>
      {/* <section className={styles.HomeBanner}>
        <div className={`${styles.backgroundBanner} ${styles.hidemobile}`}>
          <img src={bannerimg} alt="banner" />
        </div>
        <div className={`${styles.backgroundBanner} ${styles.showmobile}`}>
          <img src={bannermobile} alt="banner" />
        </div>
        <div className={styles.bannerTexts}>
          <h1 className={styles.bannertagLine}>
            Introducing Revolutionary & India’s Own Brain Mapping Technology
          </h1>
          <p className={styles.bannerpara}>
            Empowering young individuals to achieve excellence with{" "}
            <span>Neurotechnology</span>.
          </p>
          <Button
            variant="contained"
            className={styles.btnGradient}
            onClick={() => {
              redirects();
            }}
          >
            Take the Test
            <ArrowForward />
          </Button>
        </div>
      </section> */}
      <BannerSlider values={bannerdata}/>
      {/* <section className={styles.Explorebtnsection}>
        <div className={styles.ExploreBackground}>
          
        </div>
        <div className={styles.exploreimgTextbtn}>
          <div>
            <img src={StemAccredition} />
          </div>
          <h3>Stem Accredition |</h3>
          <div>
            <img src={iitbombey} />
          </div>
          <h3>IITBOMBAY</h3>
          <div className={styles.explorepc}>
            <Button variant="contained" className={styles.explorbtn}>
              Explore Now
            </Button>
          </div>
        </div>
        <div className={styles.exploremobile}>
          <Button variant="contained" className={styles.explorbtn}>
            Explore Now
          </Button>
        </div>
        <div className={`${styles.postions} ${styles.iconboxone}`}>
          <img src={exploricon1} />
        </div>
        <div className={`${styles.postions} ${styles.iconboxtwo}`}>
          <img src={exploricon2} />
        </div>
      </section> */}
      <ExplorMore exploredataobj={exploreobje} />
      <section className={styles.LJOS}>
        <div className={styles.textContainer}>
          <h4 className={styles.sectionHeading}>
            Research-Based Technology to Reform Learning Journey of Students
          </h4>
          <p>
            Developed over 10 years, BrainHap’s neurotechnology system is
            created in Association with IIT Bombay under the supervision of Dr.
            Azizuddin Khan. The technology is used to combine “reformative
            counselling” with “next-gen skill development”. The program is
            nurtured by our team of experts who have over 25+ years of
            experience in Cognitive Psychology & Psychophysiology.
          </p>
        </div>

        <div className={styles.imagestoshow}>
          <div className={styles.LJOSimgContainer}>
            <img src={supp1} alt="" />
          </div>
          <div className={styles.LJOSimgContainer}>
            <img src={supp2} alt="" />
          </div>
        </div>
        <div className={styles.LJOSbtnContainer}>
          <Button
            variant="contained"
            className={styles.btnGradient}
            onClick={() => {
              redirects();
            }}
          >
            Take the Test
            <ArrowForward />
          </Button>
        </div>
        <div className={`${styles.postions} ${styles.riconone}`}>
          <img src={ricon1} />
        </div>
        <div className={`${styles.postions} ${styles.ricontwo}`}>
          <img src={ricon2} />
        </div>
        <div className={`${styles.postions} ${styles.riconthree}`}>
          <img src={ricon3} />
        </div>
      </section>
      <section className={styles.howitsWork}>
        <h4 className={styles.sectionHeading}>How does it work?</h4>
        <div className={styles.hidemobile}>
          <div
            className={styles.howitsWork_Backgoundline}
            //   style={{ textAlign: "center" }}
          >
            <img style={{ margin: "0 auto" }} src={vector} alt="vector" />
          </div>
        </div>
        <div className={styles.showmobile}>
          <div
            className={styles.howitsWork_Backgoundline}
            //   style={{ textAlign: "center" }}
          >
            <img style={{ margin: "0 auto" }} src={mobilevector} alt="vector" />
          </div>
        </div>
        <ul className={styles.hdiw_maincontainer}>
          {howdoesitwork?.map((v, i) => {
            return (
              <li key={i}>
                <div className={styles.hdiw_imgContainer}>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <div className={styles.InfoBox}>
                  <h5>{v?.title}</h5>
                  <p>{v?.para}</p>
                </div>
              </li>
            );
          })}
        </ul>
        <div className={`${styles.postions} ${styles.howIcon1}`}>
          <img src={workIcon1} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon2}`}>
          <img src={workIcon2} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon3}`}>
          <img src={workIcon3} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon4}`}>
          <img src={workIcon4} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon5}`}>
          <img src={workIcon5} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon6}`}>
          <img src={workIcon6} />
        </div>
      </section>
      <section className={styles.servicesSection}>
        <h4 className={styles.sectionHeading}>Our Services</h4>
        <p className={styles.serviceInfopara}>
          Harness the power of your brain with the help of cutting-edge
          neurotechnology and AI/ML-based suggestions.
        </p>
        <div className={styles.servicessContainer}>
          {ourServices?.map((v, i) => {
            return (
              <div>
                <div>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <h4>{v?.title}</h4>
                <Button variant="contained" className={styles.btnGradient}>
                  Learn more
                </Button>
              </div>
            );
          })}
        </div>
      </section>
      <section className={styles.gallerySection}>
        <h4 className={styles.sectionHeading}>Gallery</h4>
        <Carousel
          responsive={responsiveCoursesgallry}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          arrows={false}
          // className={styles.slider}
        >
          {Gallerydata?.map((v, i) => {
            return (
              <>
                <div
                  key={i}
                  className={`${styles.galleryimgContainer} ${styles.hidemobile}`}
                >
                  <img src={v?.img} alt={v.id} />
                  {/* <h1>{v?.id}</h1> */}
                </div>
                <div
                  key={i}
                  className={`${styles.galleryimgContainer} ${styles.showmobile}`}
                >
                  <img src={v?.mobileimg} alt={v.id} />
                  {/* <h1>{v?.id}</h1> */}
                </div>
              </>
            );
          })}
        </Carousel>
        <div className={`${styles.postions} ${styles.galleryIcon2}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.galleryIcon1}`}>
          <img src={gicon} />
        </div>
      </section>
      <section className={styles.patnerSchoolSection}>
        <div className={` ${styles.mobile_container} ${styles.maxWidth}`}>
          <div className={`${styles.textCenter}`}>
            <h3 className={`${styles.bluiesh} ${styles.featuresdiv}`}>
              Reviews from Our Partner Schools
            </h3>
          </div>
          <NewCarousel sqd={[testimonialData]}></NewCarousel>

          <div className={`${styles.postions} ${styles.psicons1}`}>
            <img src={psicon1} />
          </div>
          <div className={`${styles.postions} ${styles.psicons2}`}>
            <img src={psicon2} />
          </div>
        </div>
      </section>
      <section className={styles.contactformSection}>
        <div className={styles.borderContainer}>
          <div className={styles.bgacolor}>
            <div className={styles.fromContainer}>
              <h4 className={styles.sectionHeading}>Join BrainHap</h4>
              <p className={styles.serviceInfopara}>
                Be the first one to get to know about BrainHap
              </p>

              <div className={styles.textfieldContainer}>
                <div>
                  <TextField
                    id="outlined-name"
                    variant="outlined"
                    label="Enter Your name"
                    placeholder="Enter Your name"
                    name="contact_person_name"
                    type="text"
                    onChange={handle}
                    // required
                  />
                  <p style={{ margin: "0", color: "red" }}>
                    {errors.contact_person_name}
                  </p>
                </div>
                <div>
                  <TextField
                    id="outlined-email"
                    variant="outlined"
                    label="Enter Your email"
                    placeholder="Enter Your email"
                    type="text"
                    name="c_email"
                    onChange={handle}
                    // required
                  />
                  <p style={{ margin: "0", color: "red" }}>{errors.c_email}</p>
                </div>
                <div>
                  <TextField
                    id="outlined-phone"
                    variant="outlined"
                    label="Mobile Number"
                    // placeholder="Mobile Number"
                    type="text"
                    name="c_phone"
                    onChange={handle}
                    // maxRows="10"
                    InputProps={{
                      startAdornment: (
                        // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                        <InputAdornment position="start">+91 |</InputAdornment>
                      ),
                    }}
                    // required
                  />
                </div>
                <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
              </div>
              <p className={styles.serviceInfopara}>Student grade at school</p>
              <div className={styles.gradeContainer}>
                {grades?.map((v, i) => {
                  const isActive = selectedGrades.includes(v);
                  return (
                    <h3
                      className={`${styles.minBox} ${
                        isActive ? styles.active : ""
                      }`}
                      key={i}
                      onClick={() => handleshowactive(i, v)}
                    >
                      {v}
                      <sup>th</sup>
                    </h3>
                  );
                })}
              </div>
              <p style={{ margin: "0", color: "red" }}>{errors.c_message}</p>
              <div className={styles.textbtn}>School Enquiry</div>
              <Button
                variant="contained"
                className={styles.fromsubmitbtn}
                onClick={submitForm}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>

        <div className={`${styles.postions} ${styles.cfone}`}>
          <img src={cf1} />
        </div>
        <div className={`${styles.postions} ${styles.cftwo}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.cfthree}`}>
          <img src={workIcon3} />
        </div>
      </section>
      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CheckIcon  className={styles.formsubmited}/>
          {/* <Alert severity="success"></Alert> */}
        </Snackbar>
      )}
    </>
  );
}

export default HomePageNew;

const howdoesitwork = [
  {
    id: 1,
    img: careermappingtest,
    title: "Career mapping test",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
  },
  {
    id: 2,
    img: careercounselling,
    title: "Career counselling",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
  },
  {
    id: 3,
    img: brainMappingtest,
    title: "Brain Mapping Test",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
  },
  {
    id: 4,
    img: neuromentoring,
    title: "Neuromentoring",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
  },
  {
    id: 5,
    img: stemcourse,
    title: "STEM Course",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
  },
];

const ourServices = [
  {
    id: 1,
    img: careermapping,
    title: "Career Mapping",
  },
  {
    id: 2,
    img: brainmapping,
    title: "Brain Mapping",
  },
  {
    id: 3,
    img: neuromen,
    title: "Neuromentoring",
  },
  {
    id: 4,
    img: stemLab,
    title: "STEM LAB",
  },
];

const Gallerydata = [
  {
    id: 1,
    img: imgone,
    mobileimg: imgmone,
  },
  {
    id: 2,
    img: imgtwo,
    mobileimg: imgmtwo,
  },
  {
    id: 3,
    img: imgthree,
    mobileimg: imgmthree,
  },
  {
    id: 4,
    img: imgfour,
    mobileimg: imgmfour,
  },
  {
    id: 5,
    img: imgfive,
    mobileimg: imgmfive,
  },
  {
    id: 6,
    img: imgsix,
    mobileimg: imgmsix,
  },
  {
    id: 7,
    img: imgseven,
    mobileimg: imgmseven,
  },
  {
    id: 8,
    img: imgeight,
    mobileimg: imgmeight,
  },
  {
    id: 9,
    img: imgnine,
    mobileimg: imgmnine,
  },
];

const testimonialData = [
  {
    id: 1,
    name: "Pragya Singh",
    position: "Principal",
    institute_name: "Composite Vidyalaya Kalyanpur",
    video: "https://prod-api.brainhap.com/files/media/2022/06/03/pragya.mp4",
    icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_1.png",
    text: '"The students gave the test with a lot of enthusiasm"',
    rating: 4,
    order: 1,
  },
  {
    id: 2,
    name: "Archana Dwivedi",
    position: "Principal",
    institute_name: "Ups Kanyavidyalay kanpur",
    video:
      "https://prod-api.brainhap.com/files/media/2022/06/03/WhatsApp_Video_2022-05-18_at_1.31.46_PM.mp4",
    icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_2.png",
    text: '"The test is a great initiative for the students\' benefit"',
    rating: 5,
    order: 1,
  },
  {
    id: 3,
    name: "Sachin sir",
    position: "Principal",
    institute_name: "Chinchavali English Mumbai school",
    video: "https://prod-api.brainhap.com/files/media/2022/06/03/sachin.mp4",
    icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_mhFN1Jx.png",
    text: '"The test is a great opportunity for students today as there are many career options but no guidance from anyone"',
    rating: 4,
    order: 1,
  },
  {
    id: 4,
    name: "Susma Srivastav",
    position: "Principal",
    institute_name: "New World Public School",
    video:
      "https://prod-api.brainhap.com/files/media/2022/07/22/WhatsApp_Video_2022-07-20_at_1.18.05_PM.mp4",
    icon: "",
    text: "The test is a great initiative for the students' benefit",
    rating: 4,
    order: 1,
  },
];

const exploreobje = {
  imgOne: StemAccredition,
  textOne: "Stem Accredition   |",
  imgTwo: iitbombey,
  textTwo: "IITBOMBAY",
};

const demoobj = {
  imgOne: StemAccredition,
  textOne: "Curriculum design you can trust",
};


const bannerdata = [
  {
    id:1,
    img:bannerone,
    imgmobile:bannermobile,
    headingtext:" Introducing Revolutionary & India’s Own Brain Mapping Technology",
    bannerpara:"Empowering young individuals to achieve excellence with",
    highlight:"Neurotechnology",
  },
  {
    id:2,
    img:bannertwo,
    imgmobile:bannermobile,
    headingtext:" Introducing Revolutionary & India’s Own Brain Mapping Technology",
    bannerpara:"Empowering young individuals to achieve excellence with",
    highlight:"Neurotechnology",
  },
  {
    id:3,
    img:bannerthree,
    imgmobile:bannermobile,
    headingtext:" Introducing Revolutionary & India’s Own Brain Mapping Technology",
    bannerpara:"Empowering young individuals to achieve excellence with",
    highlight:"Neurotechnology",
  },
  {
    id:4,
    img:bannerimg,
    imgmobile:bannermobile,
    headingtext:" Introducing Revolutionary & India’s Own Brain Mapping Technology",
    bannerpara:"Empowering young individuals to achieve excellence with",
    highlight:"Neurotechnology",
  },
  {
    id:5,
    img:bannerimg,
    imgmobile:bannermobile,
    headingtext:" Introducing Revolutionary & India’s Own Brain Mapping Technology",
    bannerpara:"Empowering young individuals to achieve excellence with",
    highlight:"Neurotechnology",
  },


]