import React from "react";

import "./Compare.css";

function CompareCourse({ courseList, ...props }) {
    console.log(courseList, "courseList");
    return (
        <div>
            <div className="course-cards-c-c">
                <div className="course-cards-sc course-compare-table">
                    {tableHead.map((item, idx) => {
                        var arrayLength = tableHead.length;
                        return (
                            <React.Fragment>
                                {arrayLength - 1 > idx ? (
                                    <WrapItem>
                                        <p>{item.name}</p>
                                    </WrapItem>
                                ) : (
                                        <div style={{ minHeight: "100px" }}>
                                            <p>{item.name}</p>
                                        </div>
                                    )}
                            </React.Fragment>
                        );
                    })}
                </div>
                {courseList.map((item, idx) => (
                    <div className="course-cards-sc course-compare-table" key={idx}>
                        <WrapItem>
                            <p>{item.institute.name}</p>
                        </WrapItem>
                        <WrapItem>
                            <p>{item.subject.length > 0 ? item.subject[0]?.title : ""}</p>
                        </WrapItem>
                        <WrapItem>
                            <p>{item.board?.title}</p>
                        </WrapItem>
                        <WrapItem>
                            <p>{item.grade?.title}</p>
                        </WrapItem>
                        <WrapItem>
                            <p>{item.session?.title}</p>
                        </WrapItem>
                        <WrapItem>
                            <p>{item.is_certified ? "Yes" : "No"}</p>
                        </WrapItem>
                        <div style={{ minHeight: "100px" }}>
                            <p dangerouslySetInnerHTML={{ __html: item.description || "" }}></p>
                        </div>
                        {/* <div style={{ minHeight: "100px" }}>
                            <p dangerouslySetInnerHTML={{ __html: item.features || "" }}></p>
                        </div> */}
                    </div>
                ))}
                {courseList.length < 4 && (
                    <div className="course-cards-sc course-compare-table" />
                )}
            </div>
        </div>
    );
}

export default CompareCourse;

const WrapItem = (props) => {
    return <div className="wrap-course-compare-item">{props.children}</div>;
};

const tableHead = [
    {
        name: "Course Provider",
    },
    {
        name: "Course Subject",
    },
    {
        name: "Course Board",
    },
    {
        name: "Course Grade",
    },
    {
        name: "Course Session",
    },
    {
        name: "Certification",
    },
    {
        name: "Overview",
    },
    // {
    //     name: "Features",
    // },
];
