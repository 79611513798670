import React, { useState, useEffect } from "react";
import "./CourseCard.css";

import blueWave from "../../Assets/CourseCard/WAVEBUTTON.png";
import yellowWave from "../../Assets/CourseCard/WAVEBUTTON2.png";
import pinkWave from "../../Assets/CourseCard/WAVEBUTTON3.png";
import { MainDataContextApi } from "../../ContextApis/MainDataContextApi/MainDataContextApi";
import midImage from "../../Assets/CourseCard/ccmid.png";
import { logo_colors } from "../../Utils/Utils";
// import CenterContainer from '../CenterContainer/CenterContainer';
import CenterContainer from "../CenterContainer/CenterContainer";
import lLogo from "../../Assets/online.svg";
import lwave from "../../Assets/lwave.svg";
import overview from "../../Assets/CourseCard/overview.svg";
import { makeStyles } from "@material-ui/core/styles";

import star from "../../Assets/homepage/star.svg";
import networksignal from "../../Assets/homepage/network_signal.svg";
import intermidiate from "../../Assets/CourseCard/mid.png";
import certificate from "../../Assets/CourseCard/certificate.svg";
import Tooltip from "@material-ui/core/Tooltip";
import {
  checkImage,
  monthNumberMapper,
  timeConvert,
  getDateOrdinal,
  splitStringDMY,
} from "../../Utils/Utils.js";
import Enrolments from "../../Assets/CourseCard/Enrolments.png";
import onlineimg from "../../Assets/mid.png";
import live from "../../Assets/LIVE.png";
import coach from "../../Assets/coaching.png";

const typesl = [
  { title: "K12" },
  { title: "Competitive" },
  { title: "Competitive" },
];
const subjectl = [{ title: "Maths" }, { title: "Science" }];

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const card_color = {
  blue: {
    color: logo_colors.blue,
    image: blueWave,
    background: "#fff",
  },
  yellow: {
    color: logo_colors.yellow,
    image: yellowWave,
    background: logo_colors.yellow + "22",
  },
  pink: {
    color: logo_colors.pink,
    image: pinkWave,
    background: logo_colors.pink + "22",
  },
};

const CourseCardV2Home = ({
  offline,
  validity,
  type,
  commence,
  conclude,
  duration,
  show_exp,
  provider_details,
  other,
  image = midImage,
  button,
  date = {},
  color = "blue",
  label = "click",
  className = "",
  style = {},
  course = { name: "", grade: "", board: "", timings: "" },
  professor = { name: "", subjects: "", education: "" },
  course_highlights = "",
  onClick = () => {},
  colored,
  ...props
}) => {
  console.log(course, course.show_exp, "validtyvalidty");
  const [minValue, setMinValue] = useState([]);
  const [commenceTime, setCommence] = useState("");
  const [concludeTime, setConclude] = useState("");
  const [dateMonth, setDateMonth] = useState("");
  console.log(course, "minValueminValue");

  useEffect(() => {
    if (course !== undefined) {
      const minimumValueCourse = Math.min(
        ...course.validity.map((item) => item.final_price)
      );
      const filterMinimumCourse = course.validity.filter(
        (item) => item.final_price == minimumValueCourse
      );
      setMinValue(filterMinimumCourse);
    }
    if (course.commence !== undefined && course.conclude !== undefined) {
      var _commence = timeConvert(course.commence);
      var _conclude = timeConvert(course.conclude);
      setCommence(_commence);
      setConclude(_conclude);
    }
    if (course.start !== undefined) {
      const dateSplit = splitStringDMY(course.start, "-");
      const { _year, _month, _date } = dateSplit;
      const dateOrdinal = getDateOrdinal(_date);
      const getMonthName = monthNumberMapper(_month - 1, false);
      setDateMonth(dateOrdinal + " " + getMonthName + " " + _year);
    }
  }, [course]);
  return (
    <div
      className={`course-card-mains ${
        colored ? "cc-colored" : ""
      } ${className}`}
      style={{ ...style }}
    >
      <div
        className="course-c-wavev2s"
        style={{
          backgroundImage: `url(${
            card_color.hasOwnProperty(color)
              ? card_color[color].image
              : card_color.blue.image
          })`,
        }}
      ></div>

      <div style={{ height: "130px", display: "grid" }}>
        {course.type === 1 && (
          <img
            src={onlineimg}
            style={{ width: "45px", margin: "10px 9px 0px" }}
          />
        )}
        {course.type === 3 && (
          <img src={live} style={{ width: "45px", margin: "10px 9px 0px" }} />
        )}
        {course.type === 2 && (
          <img src={coach} style={{ width: "45px", margin: "10px 9px 0px" }} />
        )}

        <div
          className={`${
            course.show_exp ? "price-tag-ccv2s2" : "price-tag-ccv2s"
          }`}
        >
          {course.show_exp ? (
            <div>
              {course.duration <= 10 && <p>{course.duration} Days Remaining</p>}
            </div>
          ) : (
            <React.Fragment>
              {minValue.length > 0 && minValue[0].discount_type !== null && (
                <div className="offer-with-discount-p-c">
                  {minValue.price !== minValue[0].discounted_price &&
                    minValue[0].final_price !== 0 && (
                      <p
                        className="money-c"
                        style={{
                          marginBottom: "10px",
                          color: "#333333",
                          fontSize: "18px",
                        }}
                      >
                        <s>₹{minValue[0].discounted_price}</s>
                      </p>
                    )}
                  <div>
                    {minValue[0].price !== minValue[0].discounted_price &&
                      minValue[0].final_price !== 0 && (
                        <p
                          className="offer-content-c"
                          style={{ padding: "5px" }}
                        >
                          {minValue[0].discount_type == 2 && "₹"}
                          {minValue[0].discount_value}
                          {minValue[0].discount_type == 1 && "%"} OFF
                        </p>
                      )}
                  </div>
                </div>
              )}
              {minValue.length > 0 && minValue[0].discounted_price == null && (
                <p className="discount-c new-discount-v-c">
                  ₹ {minValue[0].final_price}
                </p>
              )}
              {minValue.length > 0 && minValue[0].discounted_price !== null && (
                <p className="discount-c new-discount-v-c">
                  ₹ {minValue[0].discounted_price}
                </p>
              )}
            </React.Fragment>
          )}
        </div>

        {/* <div className="price-tag-ccv2s">
                    {course.validity.length > 0 &&
                        <div className="offer-with-discount-p-c">
                            <p className="money-c" style={{ marginBottom: '10px', color: '#333333', fontSize: '18px' }}>
                                <s>₹ 500</s>
                            </p>

                            <div>
                                <p className="offer-content-c" style={{ padding: '5px' }}>20% OFF</p>
                            </div>
                        </div>
                    }
                    <p className="discount-c new-discount-v-c">
                        ₹ 200
                    </p>
                </div> */}
        {/* <div className="price-tag-ccv2" style={{ backgroundColor: card_color.hasOwnProperty(color) ? card_color[color].color : card_color.blue.color }}>
                    {course.price}
                </div> */}

        <Tooltip title={<span className="tooltip-size-cc">{course.name}</span>}>
          <div className="tc-namev2s">{course.name}</div>
        </Tooltip>
      </div>
      <div className="tc-containerv2">
        <div className="tc-c-1">
          {/* <div className="tc-batch-det">
                        <div>Grade {course.grade}, {course.board}</div>
                        <div>{course.timings}</div>
                        <div style={{ margin: '5px auto', borderTop: '1px solid rgba(0,0,0,0.2)', height: '1px', width: '60%' }}>

                        </div>
                    </div> */}

          <div className="course-c-mids">
            <CenterContainer className="out-cc-mid" justify="flex-start">
              {offline ? (
                <CenterContainer style={{ padding: "5px", fontWeight: "600" }}>
                  {/* <div className="date-lbl-bx">Start date</div> */}
                  <div className="date-bx">{date.start}</div>
                  <div className="date-lbl-bx">To</div>
                  <div className="date-bx">{date.end}</div>
                </CenterContainer>
              ) : other ? (
                ""
              ) : (
                <div className="tc-instructor-detv2">
                  <div
                    direction="row"
                    justify="flex-start"
                    style={{ height: "auto" }}
                  >
                    <div className="ccv2-prov-imgs">
                      <img
                        width="100%"
                        src={provider_details.avatar}
                        alt="course avatar"
                      />
                    </div>
                    {/* <div style={{ fontSize: '18px', fontWeight: '700', marginLeft: '10px' }}>{provider_details.name} </div> */}
                  </div>

                  {/* <div className="course-card-detail-c" style={{ marginTop: '8px' }}>
                                        <span>

                                        </span>
                                        <div>
                                            <p className="course-details-card-i">
                                                {course.board}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="course-card-detail-c">
                                        <span>

                                        </span>
                                        <div>
                                        <p className="course-details-card-i">
                                                {course.grade}
                                            </p>
                                        </div>
                                    </div> */}

                  <div className="course-card-detail-c">
                    <div>
                      <p className="course-details-card-i">{course.board}</p>
                    </div>
                    <div>
                      <p className="course-details-card-i">{course.grade}</p>
                    </div>
                    {Array.isArray(course.type) &&
                      course.type.map((type, idx) => {
                        if (idx == 0)
                          return (
                            <div>
                              <p className="course-details-card-i">
                                {type.title}
                              </p>
                            </div>
                          );
                      })}

                    {Array.isArray(course.subject) &&
                      course.subject.map((type, idx) => {
                        if (idx == 0)
                          return (
                            <div>
                              <p className="course-details-card-i">
                                {type.title}
                              </p>
                            </div>
                          );
                      })}
                  </div>

                  <div className="course-card-detail-c">
                    <span></span>
                    <span>{/* {course.price} */}</span>
                  </div>

                  <div style={{ textAlign: "left" }}>
                    {/* {Array.isArray(course.type)
                                            &&
                                            course.type.map(type => <TagsItems >{type.title}</TagsItems>)
                                        } */}
                  </div>
                  <div style={{ textAlign: "left" }}>
                    {/* {Array.isArray(course.subject)
                                            &&
                                            course.subject.map(type => <TagsItems >{type.title}</TagsItems>)
                                        } */}
                  </div>
                </div>
              )}
              <div className="display-star-card">
                <div>
                  <img src={intermidiate} style={{ width: "70%" }} />
                </div>
                <div>
                  <div className="display-star-icon">
                    <img src={star} style={{ width: "14px" }} />
                    &nbsp;
                    <span style={{ fontSize: "15px" }}>{course.rating}</span>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  {course.certified && (
                    <img src={certificate} style={{ width: "28px" }} />
                  )}
                </div>
              </div>
              {!course.show_exp && (
                <div
                  className="display-star-cards"
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <div
                    style={{
                      width: "33px",
                      background: "#d0d0d0",
                      borderRadius: "50px",
                      padding: "7px",
                      marginRight: "5px",
                    }}
                  >
                    <img src={Enrolments} style={{ width: "17px" }} />
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <span style={{ fontSize: "13px", color: "#133268" }}>
                      {course.enrollment}
                      {course.enrollment !== 0 && "+"} Enrolments
                    </span>
                  </div>
                </div>
              )}
              {/* <div className="enrolment-classs-card">
                                <img src={Enrolments} style={{width:'90%'}}/>
                                <span>567+ Enrolments</span>
                            </div> */}
            </CenterContainer>
            <div className="mid-cc-bans">
              <div className="mid-cc-bans-img">
                <img src={checkImage(null)} width="85%" />
              </div>
              {course.type === "Live" && (
                <p>
                  {commenceTime} - {concludeTime}
                  <br />
                  {dateMonth}
                </p>
              )}
            </div>
            {/* <div>ReactJs developer.</div> */}
          </div>
        </div>
        <div className="tc-c-2">
          <div
            className="tc-c-2-ch"
            style={{ color: "#133268", fontWeight: "600" }}
          >
            Course Highlights
          </div>
          {/* <div className="tc-c-higlights"> */}

          {/* {Array.isArray(course_highlights) && course_highlights.map((text, idx) => <div key={idx} className="tc-c-hg">{text}</div>)} */}
          {/* <div className="tc-c-hg" dangerouslySetInnerHTML={{ __html: course_highlights }}></div>

                    </div> */}
          <div className="tc-c-higlightss">
            {/* {Array.isArray(course_highlights) && course_highlights.map((text, idx) => <div key={idx} className="tc-c-hg">{text}</div>)} */}
            <div
              className="tc-c-hgs"
              dangerouslySetInnerHTML={{ __html: course_highlights }}
            ></div>
          </div>
        </div>
      </div>
      <div>
        {button ? (
          <button
            className="course-c-btn"
            style={{
              backgroundColor: card_color.hasOwnProperty(color)
                ? card_color[color].color
                : card_color.blue.color,
            }}
            onClick={onClick}
          >
            {label}
          </button>
        ) : (
          <div
            className="course-c-btn"
            style={{
              backgroundColor: card_color.hasOwnProperty(color)
                ? card_color[color].color
                : card_color.blue.color,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

const TagsItems = ({ children }) => (
  <div className="tagitems-ccv2">{children}</div>
);

export default CourseCardV2Home;
