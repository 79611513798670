import React from 'react';
import { TextField } from '@material-ui/core';
import './AppTextField.style.css';

const AppTextField = ({ alert, ...props }) => {


    return (
        <React.Fragment>
            <TextField {...props} color="secondary" />
            <div className="app-text-alert-div ">{alert}</div>
        </React.Fragment>
    )

}

export default  React.memo(AppTextField);