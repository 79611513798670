import React, {useState} from 'react';
import './LoginCard.css';
import { Link } from 'react-router-dom'
// import Login from '../../Pages/Login/Login';
import Login from '../../Pages/Login/Login';
import { MainDataContextApi } from '../../ContextApis/MainDataContextApi/MainDataContextApi';

function LoginCard(){
    const [login, setLogin] = useState(false);
    const { login_open, toggleLoginCard } = React.useContext(MainDataContextApi);

    return(
        <div className="login-card-b">
            <p>Account</p>
            <p> Login or create an account to purchase a course</p>
            <div className="login-buttons">
                <p  onClick={e => toggleLoginCard(true)}>Login</p>
                <Link to='/register'><p className="signup-card-su">Sign Up</p></Link>
            </div>
            {/* <Login open={login} onClose={e => setLogin(false)} /> */}
        </div>
    )
}

export default LoginCard;