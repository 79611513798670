import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import LzTestQue from "./LzTestQue";
import LearnZoneAssignment from "./LearnZoneAssignment/LearnZoneAssignment";
import LzViewPage from "./LzViewPage/LzViewPage";



function LearnZoneTest() {

 

  // const qTimer = "00:00";
  const { id,ppt } = useParams();


  return (
    <>
    {
      (ppt == 'mcq') ? <LzTestQue /> :<LearnZoneAssignment />
      // (ppt == 'mcq') ? <LzTestQue /> :(ppt == 'e-book') ? <LzViewPage /> :<LearnZoneAssignment />
    }
    </>
  );
}

export default LearnZoneTest;
