import React from "react";
import styles from "./index.module.css";
import { Button } from "@material-ui/core";
import NewSlider from "../../Components/Newdesigncomponets/NewSlider/NewSlider";
import sliderimg1 from "../../Assets/newevopage/forschoolimg-1.png"
import sliderimg2 from "../../Assets/newevopage/forschoolimg-2.png"
import sliderimg3 from "../../Assets/newevopage/forschoolimg-3.png"
import banner from "../../Assets/newevopage/forschoolbanner.png"

function ForSchool() {
  return (
    <>
      <section className={styles.BannerSection}>
        <div className={styles.bannerImageConatainer}>
          <img src={banner} alt="" />
        </div>
        <h1 className={`${styles.bannertextOne} ${styles.absolute} `}>
          The future of <span>Education</span> involves
          <br /> a <span>Blend</span> of learning approaches.
        </h1>
        <div className={`${styles.signinComponent} ${styles.absolute}`}>
          <h2 className={styles.bannertextTwo}>Lorem Ipsum is Simply Dummy</h2>
          <Button variant="contained" className={styles.signbtn}>Sign In</Button>
        </div>
      </section>

      <section className={styles.showCouterSection}>
        <div className={styles.counterMain}>
          {showcounterdata?.map((v) => {
            return (
              <div className={styles.counterbox}>
                <h4 className={styles.number}>{v?.count} +</h4>
                <h6 className={styles.title}>{v?.title}</h6>
              </div>
            );
          })}
        </div>
      </section>
      <section className={styles.sliderContainer}>

            <div className={styles.sliderdataListing}>
              <NewSlider  values={sliderdata}/>
            </div>

      </section>
    </>
  );
}

export default ForSchool;

const showcounterdata = [
  {
    id: 1,
    count: 500,
    title: "Lorem Ipsum",
  },
  {
    id: 2,
    count: 500,
    title: "Lorem Ipsum",
  },
  {
    id: 3,
    count: 500,
    title: "Lorem Ipsum",
  },
  {
    id: 4,
    count: 500,
    title: "Lorem Ipsum",
  },
];

const sliderdata = [
  {
    id:1,
    img:sliderimg1,
    title:"Lorem Ipsum",
    para:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id:2,
    img:sliderimg2,
    title:"Lorem Ipsum",
    para:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id:3,
    img:sliderimg3,
    title:"Lorem Ipsum",
    para:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id:4,
    img:sliderimg1,
    title:"Lorem Ipsum",
    para:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id:5,
    img:sliderimg2,
    title:"Lorem Ipsum",
    para:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id:6,
    img:sliderimg3,
    title:"Lorem Ipsum",
    para:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
]