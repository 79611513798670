import React, { useState, useEffect } from "react";
import teacher from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 33.svg";
import star from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 32.svg";
import growth from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 30.svg";
import batch from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 31.svg";
import progress from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 29.svg";
import { checkLowestPrice } from "../../Utils/Utils";
import "./Cards.css";
// tooltip added by nikhil
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
function Cards({ name, ...props }) {
  const [validity, setValidity] = useState([]);
  const [lowestPrice, setLowestPrice] = useState({});

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  useEffect(() => {
    if (
      name.validity !== undefined &&
      name.validity.length !== 0 &&
      name.validity
    ) {
      // console.log(lowestValue)
      const { lowestValue } = checkLowestPrice(name.validity, "price");
      setValidity(name.validity);
      setLowestPrice(lowestValue);
    }
  }, [name]);

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  // (props.name.validity !== undefined && props.name.validity !== null) ? console.log("VALId", props.name.validity.map(i => console.log(i.price, "Price")) ) : console.log("empty");
  return (
    <>
      <div className="cards-box">
        <Link
          style={{
            textDecoration: "none",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          to={`/coursedetail/${name.id}/${name.entity_type}`}
        >
          <div className="card-img">
            <img
              src={name?.session?.icon2}
              alt="teacher"
              className="Sub_icon"
            ></img>
            <div className="ratings">
              <div>
                <img src={star} alt="star" className="stars"></img>
              </div>
              <p className="rate">{name.average_rating}</p>
            </div>
          </div>
          <div className="box-title">
            <BootstrapTooltip title={name.title}>
              <p className="bx-title">{name.title}</p>
            </BootstrapTooltip>
            {/* </Link> */}
            {/* hide price in my space page card view */}
            {/* {!props.showPrice && <p className="bx-amount"> */}
            <p className="bx-amount">
              {name.validity !== undefined &&
              name.validity !== null &&
              name.validity.length !== 0 ? (
                <>
                  {lowestPrice.final_price === null ? (
                    <>
                      <p>
                        <span>&#8377;</span>
                        {lowestPrice.price}
                      </p>{" "}
                    </>
                  ) : (
                    <>
                      <p>
                        <span>&#8377;</span>
                        {lowestPrice.final_price}
                      </p>{" "}
                      <p>
                        {" "}
                        <strike>
                          <span>&#8377;</span>
                          {lowestPrice.price}
                        </strike>
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  <p>
                    <span>&#8377;</span>
                    {name.price}
                  </p>
                  <p>
                    <strike>
                      <span>&#8377;</span>
                      {name.selling_price}
                    </strike>
                  </p>
                </>
              )}
            </p>
          </div>
          <div className="enroled">
            <img src={name?.institute?.avatar} className="institute_icon"></img>
            <img src={growth} alt="growth" className="grow"></img>
            <p>{name.enrollments} Enrolments</p>
          </div>
          <div className="prog">
            {name.is_certified ? <img src={batch} alt="batch"></img> : <></>}

            <img src={progress} alt="progress"></img>
          </div>
        </Link>
      </div>
    </>
  );
}

export default Cards;
