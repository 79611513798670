import React, { useState, useEffect, useCallback, useContext } from "react";
import { api_call_token } from "../../Utils/Network";
import styles from "./TestSeries.module.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import Academic from "../../Assets/testpage/academic.png"
import Interest from "../../Assets/testpage/interest.png"
import Aptitude from "../../Assets/testpage/aptitude.png"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { MainDataContextApi } from "../../ContextApis/MainDataContextApi/MainDataContextApi";
import lcarro from "../../Assets/Logo/Artboard 1 copy 3.png";
import Slide from "@material-ui/core/Slide";
import cross from "../../Assets/1x/asset/close.png";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    console.log(script, "script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ViewResult(props) {
  const classes = useStyles();
  const {
    user,
    loginData,
    setStudentProfile,
    setToken,
    updateLoginData,
    setUser,
    guardianExists,
    setGuardianExists,
  } = useContext(UserCredsContext);
  const [viewData, setViewData] = useState(6);
  const [breakDown, setBreakDown] = useState([]);
  const [oflbreakDown, setOflBreakDown] = useState([]);
  const [datas, setDatas] = useState({});
  let history = useHistory();
  const [ofldatas, setOflDatas] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [minimumTime, setMinimumTime] = useState(500);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const [interset, setInterest] = useState([])
  const [achievement, setAchievement] = useState([]);
  const [prof, setProf] = useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [price, setPrice] = useState(0);
  const [price, setPrice] = useState({});
  const [loadings, setLoadings] = useState(true);


  const handleClose = () => {
    setOpen(false);
  };

  let { test_type, ids } = useParams();


  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
      restartTimeout();
    };
  }, []);
  const restartTimeout = useCallback(() => {
    setMinimumTimeElapsed(false);
    setLoading(true);
    //simulate random load time between 0 and 5 seconds
    const randomLoadTime = Math.random() * 5000;
    setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, minimumTime);
    setTimeout(() => {
      setLoading(false);
    }, randomLoadTime);
  }, [setMinimumTimeElapsed, setLoading, minimumTime]);

  const ViewFinalResult = () => {
    setLoading(true);
    api_call_token
      .get(`test/result_view/view_stats/?test_type=${test_type}`)
      .then((res) => {

        const { all_question_breakdown, professions } = res.data.data;
        setDatas(res.data.data);
        setBreakDown(all_question_breakdown);
        setProf(professions["0"])
        // setViewData(6);
        window.localStorage.removeItem("answer");
        window.localStorage.removeItem("minutes");
        window.localStorage.removeItem("number");
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };


  const handleClick = () => {
    history.push("/testseries");
    // props.history.goBack();
  };

  const handleAskCounselor = () => {
    history.push("/ask-to-counselor");
    // props.history.goBack();
  };

  const onPDFViewClick = () => {
    datas.pdf ? window.open(datas.pdf) : alert("please try after some time");
  };



  //////////////////////////////////////


  const handelBooking = (data) => {
    setLoading(true);
    // console.log(data, "solts");

    // if (data == null || undefined) {
    //   console.log(data, "price");
    // } else {
    let price = data * 100;

    api_call_token
      .post(`counseling/purchase/test/initiate_book_slot/`, {
        amount: price,
      })
      .then((res) => {
        console.log(res.data, "solts result");
        const payData = res?.data?.data;
        displayRazorPay(payData);
      })
      .catch((err) => console.log(err));
    // }
    setLoading(false);
  };


  async function displayRazorPay(dataId, slotData) {
    setLoading(true);
    // console.log(dataId, slotData, "data id passed solts");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (current_url.includes("localhost") || current_url.includes("staging")) {
      // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    } else {
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    }
    var courseprice = dataId.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Brainhap",
      description: "Course payment",
      image: lcarro,
      order_id: `${dataId.order_id}`,
      handler: function (response) {
        console.log(response.razorpay_payment_id, "hello");
        console.log(response.razorpay_order_id, "hello1");
        console.log(response.razorpay_signature, "hello2");
        // setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId);
        // console.log(slotData, "getpurchase body");
        getpurchase(response, dataId);
      },
      prefill: {
        name: `${user?.first_name}`,
        email: `${user?.email}`,
        contact: `${user?.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoadings(false);
  }


  const getpurchase = (rpid, ordId, slotData) => {
    setLoading(true);
    // console.log(slotData, "getpurchase body");
    console.log(rpid, ordId, "rpid, ordId, slotData");
    var data = {
      transaction_id: rpid?.razorpay_payment_id,
      order_id: ordId?.id,
      razorpay_signature: rpid?.razorpay_signature,
    };
    console.log(data, "value to pass in purchase");
    api_call_token
      .post(`counseling/purchase/test/order_test_slot/`, data)
      .then((response) => {
        console.log("The response for purchase course is ", response.data.data);
        // alert("Successfully Slot is Booked");
        if (response.data.data.id) {
          setOpen(false);
          // ViewAllResult()
          alert("Payment Successfull")
          history.push('/testseries')

        }
        // setSuccess(true);
      });
    // console.log(rpid, "rpid");
  };




  const handleAdvanceBooking = (data) => {
    setLoading(true);
    // console.log(data, "solts");

    // if (data == null || undefined) {
    //   console.log(data, "price");
    // } else {
    let price = data * 100;

    api_call_token
      .post(`counseling/purchase/test/initiate_book_slot_and_test/`, {
        amount: price,
      })
      .then((res) => {
        console.log(res.data, "solts result");
        const payData = res?.data?.data;
        displayAdvRazorPay(payData);
      })
      .catch((err) => console.log(err));
    // }
    setLoading(false);
  };

  async function displayAdvRazorPay(dataId, slotData) {
    setLoading(true);
    // console.log(dataId, slotData, "data id passed solts");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (current_url.includes("localhost") || current_url.includes("staging")) {
      // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    } else {
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    }
    var courseprice = dataId.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Brainhap",
      description: "Course payment",
      image: lcarro,
      order_id: `${dataId.order_id}`,
      handler: function (response) {
        console.log(response.razorpay_payment_id, "hello");
        console.log(response.razorpay_order_id, "hello1");
        console.log(response.razorpay_signature, "hello2");
        // setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId);
        // console.log(slotData, "getpurchase body");
        getAdvpurchase(response, dataId);
      },
      prefill: {
        name: `${user?.first_name}`,
        email: `${user?.email}`,
        contact: `${user?.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoadings(false);
  }

  const getAdvpurchase = (rpid, ordId, slotData) => {
    setLoading(true);
    // console.log(slotData, "getpurchase body");
    console.log(rpid, ordId, "rpid, ordId, slotData");
    var data = {
      transaction_id: rpid?.razorpay_payment_id,
      order_id: ordId?.id,
      amount: price?.advance?.price,
      // razorpay_signature: rpid?.razorpay_signature,
    };
    console.log(data, "value to pass in purchase");
    api_call_token
      .post(`counseling/purchase/test/order_counseling_with_test/`, data)
      .then((response) => {
        console.log("The response for purchase course is ", response.data.data);
        // alert("Successfully Slot is Booked");
        if (response.data.data.id) {
          setOpen(false);
          // ViewAllResult()
          alert("Payment Successfull")
          history.push('/testseries')
          
        }
        // setSuccess(true);
      });
    // console.log(rpid, "rpid");
  };





  //////////////////////////////////


  // const OfflineResult = () => {
  //   setLoading(true);
  //   api_call_token
  //     .get(`test/result_view/offline_overall/?test_type=${test_type}`)
  //     .then((res) => {
  //       console.log(res, "oflViewClick");
  //       // setOflDatas(res.data.data);
  //       // setOflBreakDown(all_question_breakdown);
  //       setLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    ViewFinalResult();
    // OfflineResult();
    setLoading(false);
    viewDataInterest();
    getAptitudeForPay();
  }, []);


  // const getAptitudeForPay = () => {
  //   api_call_token
  //     .get(`/test/aptitude_test_question/question_progress/?test_type=aptitude`)
  //     .then((res) => {
  //       let price = res.data.price;
  //       setPrice(price);
  //     })
  //     .catch(err => console.log(err))
  // }

  const getAptitudeForPay = () => {
    api_call_token
    .get(`/counseling/purchase/test/payment_status/`)
    .then((res) => {
      console.log(res.data.data, "res")
      let price = res.data.data;
      setPrice(price);
    })
    .catch(err => console.log(err))
  }

  const viewDataInterest = () => {
    api_call_token
      .get(`/test/result_view/overall/?test_type=${test_type}`)
      .then((res) => {
        console.log(res, "responseee")

        if (res.data.data.interest_test_category_and_percentage) {

          setInterest(res.data.data.interest_test_category_and_percentage)
        } else if (res.data.data.all_question_acadmic_breakdown) {
          setInterest(res.data.data.all_question_acadmic_breakdown)
        }
      })
      .catch(err => console.log(err))
  }

  const handlePOP = () => {
    setOpen(true)
  }

  console.log(interset, "interestt")
  console.log(breakDown, "breakDown");
  console.log("ofl datas", ofldatas);
  console.log("ofl breakDown", oflbreakDown);
  console.log(location, "LOCA", prof)
  // let demo = test_type.split("=");
  console.log(test_type, "demo")
  return (
    <div className={styles.Back_position}>
      {/* {viewData === 6 && ( */}
      <div className={styles.finalResults}>
        <div className={styles.resuts}>Result </div>
        {(datas) ? (
          <div className={styles.viewResult_box}>
            <div style={{ paddingTop: "45px" }}>
              <div className={styles.detailsfinal}>Hi {datas.full_name},<br /> Your result is
                here.</div>

              {/* <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>No. of Attempts: { datas?.no_of_attempts}</div> */}
              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Questions: {datas?.total_questions} */}
                {datas.total_questions &&
                  ` Total Questions:  ${datas?.total_questions || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {datas.correct_answers &&
                  `Correct Answers: ${datas?.correct_answers || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Wrong Answers: {datas?.wrong_answers || datas?.no_of_attempts} */}
                {datas.wrong_answers &&
                  ` Wrong Answers: ${datas?.wrong_answers || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>

                {
                  `Unattempted Questions:  ${datas?.unattempted_questions || 0}`}

              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Average Time Spent per Question: {datas?.avg_time} sec */}
                {datas.avg_time &&
                  `Average Time Spent Per Question:  ${datas?.avg_time || 0} seconds`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Time Spent: {datas?.total_time} */}
                {datas.total_time && `Total Time Spent: ${datas?.total_time || 0} minutes`}
              </div>
            </div>

            {/*New Interest test*/}
            {
              (interset.length > 0) ? <div>
                {interset &&
                  interset.map((data, idx) => (
                    <div key={idx} className={styles.res_break}>
                      {data.category === "" ? (
                        <></>
                      ) : (
                        <div className={styles.res_break_sub}>
                          <p>{data?.category} </p>
                        </div>
                      )}
                      <div className={styles.result_bar}>
                        {data.value === null ? (
                          <></>
                        ) : (
                          <div
                            style={{
                              width: `${data?.value}%`,
                              backgroundColor: `${data?.colour}`,
                              height: "13px",
                              borderBottomLeftRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></div>
                        )}
                        <div className={styles.show_percent}>
                          {category_percentage_breakdown.map((val, idx) => (
                            <div
                              style={{
                                width: `${val.percentage}%`,
                                height: "13px",
                                borderRight: "4px solid white",
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div> :
              <div>
                {(test_type !== "interest") &&
                <div>
                  {breakDown &&
                    breakDown.map((data, idx) => (
                      <div key={idx} className={styles.res_break}>
                        {data.category_percent_range === null ? (
                          <></>
                        ) : (
                          <div className={styles.res_break_sub}>
                            <p>{data?.breakdown} </p>
                            {/* <p>
                          {data?.category_percent_range?.title}{" "}
                          {data?.percent + "%"}
                        </p> */}
                          </div>
                        )}
                        <div className={styles.result_bar}>
                          {data.category_percent_range === null ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                width: `${data?.percent}%`,
                                backgroundColor: `${data?.category_percent_range?.color}`,
                                height: "13px",
                                borderBottomLeftRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            ></div>
                          )}
                          <div className={styles.show_percent}>
                            {data?.category_percentage_breakdown.map((val, idx) => (
                              <div
                                style={{
                                  width: `${val.percentage}%`,
                                  height: "13px",
                                  borderRight: "4px solid white",
                                }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>}
              </div>
            }
          </div>
        ) : null}
        {ofldatas?.pdf ? (
          <div className={styles.viewResult_box}>
            <div style={{ paddingTop: "45px" }}>
              <div className={styles.detailsfinal}>Hi {datas.full_name},<br /> Your result is
                here.</div>

              {/* <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>No. of Attempts: { ofldatas?.no_of_attempts}</div> */}
              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Questions: {ofldatas?.total_questions} */}
                {ofldatas.total_questions &&
                  ` Total Questions:  ${ofldatas.total_questions}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {
                  `Correct Answers: ${ofldatas.correct_answers || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Wrong Answers: {ofldatas?.wrong_answers || ofldatas?.no_of_attempts} */}
                {
                  ` Wrong Answers: ${ofldatas.wrong_answers || 0}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Unattempted Questions: {ofldatas?.unattempted_questions} */}
                {ofldatas.unattempted_questions &&
                  `Unattempted Questions:  ${ofldatas.unattempted_questions}`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Average Time Spent per Question: {ofldatas?.avg_time} sec */}
                {ofldatas.avg_time &&
                  `Average Time Spent Per Question:  ${ofldatas?.avg_time || 0} sec`}
              </div>

              <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>
                {/* Total Time Spent: {ofldatas?.total_time} */}
                {ofldatas.total_time &&
                  `Total Time Spent: ${ofldatas.total_time}`}
              </div>
            </div>

            <div>
              {oflbreakDown &&
                oflbreakDown.map((data, idx) => (
                  <div className={styles.res_break}>
                    {data.category_percent_range === null ? (
                      <></>
                    ) : (
                      <div className={styles.res_break_sub}>
                        <p>{data?.oflbreakDown} </p>
                        {/* <p>
                          {data?.category_percent_range?.title} {"  "}
                          {data?.percent + "%"}
                        </p> */}
                      </div>
                    )}
                    <div className={styles.result_bar}>
                      {data.category_percent_range === null ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            width: `${data?.percent}%`,
                            backgroundColor: `${data?.category_percent_range?.color}`,
                            height: "13px",
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                          }}
                        ></div>
                      )}
                      <div className={styles.show_percent}>
                        {data?.category_percentage_breakdown.map((val, idx) => (
                          <div
                            style={{
                              width: `${val.percentage}%`,
                              height: "13px",
                              borderRight: "4px solid white",
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}

        {
          ids == 1 && <div>
            <div>
              {/* {[1, 2, 3].map((v, i) => (<div className={`${styles.sampleMain} ${i > 0 ? styles.blurEffect : ""}`}><h3 className={styles.sampleHead}>1 You are good at attention to details</h3>
                <p className={styles.samplepara}>
                  Attention to detail is the ability to focus on all areas of a project or task, no matter how small. People with excellent attention to detail are thorough in reviewing their work. As a result, the work is generally more accurate and free of errors.

                </p> </div>))} */}

                {
                  breakDown && breakDown.map((v,i) => (
                    <div className={`${styles.sampleMain} ${i > 0 ? styles.blurEffect : ""}`}><h3 className={styles.sampleHead}>{i + 1} You are good at {v?.breakdown}</h3>
                <p className={styles.samplepara}>
                  {v?.detail}
                  {/* Attention to detail is the ability to focus on all areas of a project or task, no matter how small. People with excellent attention to detail are thorough in reviewing their work. As a result, the work is generally more accurate and free of errors. */}

                </p> </div>
                  ))
                } 
            </div>
            <div style={{ marginTop: '50px' }}>
              <h3>Based on your results, here are few career results matches for you</h3>
              <div className={styles.profession}>
                {prof?.profession?.map((v, i) => (
                  <div className={styles.professDetail}>{v}</div>
                ))}
              </div>
            </div>

            <div style={{ marginTop: '20px', lineHeight: 1.5 }}>
              Remember these results are soley based on your initial assessment. Complete all BrainHap assessent tests for accurate and personalized results.
            </div>

            <div className={styles.aptitude}>
              <img src={Aptitude} alt="img" className={styles.imgas} />
              <img src={Interest} alt="img" className={styles.imgas} />
              <img src={Academic} alt="img" className={styles.imgas} />

            </div>
          </div>
        }
        <button className={styles.getNextSet} onClick={() => handleClick()}>
          Get Back to Test
        </button>

        {
          ids == 1 && <button className={styles.getNextSet} onClick={() => handlePOP()}>
            Get Started
          </button>
        }
        {/* <button
          className={styles.getNextSet}
          onClick={() => onPDFViewClick()}
          disabled={datas.pdf ? false : true}
          style={
            datas.pdf
              ? { cursor: "pointer" }
              : { cursor: "not-allowed", backgroundColor: "#92929eb8" }
          }
        >
          Download Report
        </button> */}
        {/* <button
          className={styles.getNextSet}
          onClick={() => handleAskCounselor()}
        >
          Consult Expert
        </button> */}
      </div>
      {/* )} */}



      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* {"Next Question is Passage"} */}
          <img src={cross} alt="cross" className="cross" onClick={handleClose} />

        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> Please attempt all question </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <div className={styles.pdfBoxListing}>

            <div className={`${styles.boxComponent} ${styles.gradianOne}`}>

              <h2 className={styles.boxHeading} style={{ color: "#FFD61D" }}>

                Standard

              </h2>

              <ul className={styles.outerList}>

                <li>

                  Career Mapping Test

                  <div>

                    <h5 className={styles.innerHeading}> Aptitude Test:</h5>

                    <ul className={styles.innerList}>

                      <li>Assessment based on 14 Parameters</li>

                      <li>

                        Identify your aptitude /abilities for potential career

                        options

                      </li>

                    </ul>

                    <h5 className={styles.innerHeading}> Interest Test:</h5>

                    <ul className={styles.innerList}>

                      <li>Assessment based on 9 parameters</li>

                      <li>

                        Identify your skills-set and area of interest for best

                        career options

                      </li>

                    </ul>

                  </div>

                </li>

              </ul>

              <ul className={styles.outerList}>

                <li>

                  Academic Test

                  <div>

                    <ul className={styles.innerList}>

                      <li>

                        Test yourself on different parameters based on Bloom's

                        taxonomy

                      </li>

                      <li>Identify your strong and weak academic subjects</li>

                    </ul>

                  </div>

                </li>

              </ul>

              <ul className={styles.outerList}>

                <li>

                  Assessment Reports

                  <div>

                    <p>

                      Access downloadable reports for your career mapping test and

                      academic test.

                    </p>

                  </div>

                </li>

              </ul>

              <button className={styles.buyNowBTN} disabled={price?.standard?.status} style={(price?.standard?.status) ? {cursor: 'not-allowed'} : {cursor: 'pointer'}} onClick={() => handelBooking(price?.standard?.price)}>Buy now</button>

            </div>

            {/* 2nd box component */}

            <div className={`${styles.boxComponent} ${styles.gradianTwo}`}>

              <h2 className={styles.boxHeading} style={{ color: "#00B9E6" }}>

                Advance

              </h2>

              <ul className={styles.outerList}>

                <li>

                  Career Mapping Test

                  <div>

                    <h5 className={styles.innerHeading}> Aptitude Test:</h5>

                    <ul className={styles.innerList}>

                      <li>Assessment based on 14 Parameters</li>

                      <li>

                        Identify your aptitude /abilities for potential career

                        options

                      </li>

                    </ul>

                    <h5 className={styles.innerHeading}> Interest Test:</h5>

                    <ul className={styles.innerList}>

                      <li>Assessment based on 9 parameters</li>

                      <li>

                        Identify your skills-set and area of interest for best

                        career options

                      </li>

                    </ul>

                  </div>

                </li>

              </ul>

              <ul className={styles.outerList}>

                <li>

                  Academic Test

                  <div>

                    <ul className={styles.innerList}>

                      <li>

                        Test yourself on different parameters based on Bloom's

                        taxonomy

                      </li>

                      <li>Identify your strong and weak academic subjects</li>

                    </ul>

                  </div>

                </li>

              </ul>

              <ul className={styles.outerList}>

                <li>

                  Assessment Reports

                  <div>

                    <p>

                      Access downloadable reports for your career mapping test and

                      academic test.

                    </p>

                  </div>

                </li>

              </ul>

              <ul className={styles.outerList}>

                <li>

                  Counselling Session

                  <div>

                    <p>

                      One on One counselling sessions with industry experts to guide

                      your career development process.

                    </p>

                  </div>

                </li>

              </ul>

              <ul className={styles.outerList}>

                <li>

                  Career Counselling Report

                  <div>

                    <ul className={styles.innerList}>

                      <li>

                        Detailed career counselling report summarizing your test

                        result.

                      </li>

                      <li>

                        Get your top 10 best fit careers to be future ready and

                        achieve success.

                      </li>

                    </ul>

                  </div>

                </li>

              </ul>

              {/* <ul className={styles.slListNote}>

                <li>

                  Combination of Assessment reports + counseling session insights

                </li>

              </ul>

              <ul className={styles.lListNote}>

                <li>

                  WhatsApp Support <br />Available 24 x 7 to solve all your queries.

                </li>

              </ul> */}

              <button className={styles.buyNowBTN} disabled={price?.advance?.status} style={(price?.advance?.status) ? {cursor: 'not-allowed'} : {cursor: 'pointer'}} onClick={() => handleAdvanceBooking(price?.advance?.price)}>Buy now</button>

            </div>

          </div>
          {/* <Button onClick={() => handelBooking(price)}>Pay</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ViewResult;

const category_percentage_breakdown = [
  { percentage: 30, order: 1, title: "Novice", color: "#FF6FB5" },
  { percentage: 30, order: 2, title: "Intermediate", color: "#FF6FB5" },
  { percentage: 20, order: 3, title: "Advance", color: "#FF6FB5" },
  { percentage: 10, order: 4, title: "Scholar", color: "#FF6FB5" },
  { percentage: 10, order: 5, title: "Ace", color: "#FF6FB5" }
]
