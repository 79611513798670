import React, { useEffect, useState } from "react";
import { Snackbar, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styles from "./contact.module.css";
import TextField from "@material-ui/core/TextField";
import backImg from "../../Assets/1x/Asset 3 (1).svg";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green, purple } from "@material-ui/core/colors";
import { api_call, setApiToken } from "../../Utils/Network";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import DraftsIcon from "@material-ui/icons/Drafts";
import RoomSharpIcon from "@material-ui/icons/RoomSharp";
import CallIcon from "@material-ui/icons/Call";
import ContactImg from "../../Assets/Contact/conImg.png";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#eb8413"),
    backgroundColor: "#eb8413",
    width: "200px",
    padding: "16px",
    borderRadius: "19px",
    color: "white",
    fontSize: "20px",
    fontWeight: "900",
    "&:hover": {
      backgroundColor: "#eb8413",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function Contact() {
  const [errors, setErrors] = useState({ name: "" });
  const [msg, setMsg] = useState("");
  const [userData, setUserData] = useState({
    c_fname: "",
    c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
  });
  var temp = {};

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;

    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    temp.c_email = userData.c_email.match(validEmail)
      ? ""
      : "Please Enter valid email";
    temp.c_message =
      userData.c_message === "" ? "Please enter the message" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const SubmitData = () => {
    debugger;
    if (!validate()) return null;
    var payload = {
      first_name: userData.c_fname,
      last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
    };
    api_call
      .post("contact/general/", payload)
      .then((x) => {
        setMsg("Thank You! Your submission has been sent.");
        setUserData({
          c_fname: "",
          c_lname: "",
          c_phone: "",
          c_email: "",
          c_message: "",
        });
        document.getElementById("outlined-search").value = "";
        document.getElementById("outlined-searchs").value = "";
        document.getElementById("outlined-multiline-static").value = "";
      })
      .catch((error) => console.log(error));
  };

  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const classes = useStyles();
  return (
    <>
      <div style={{ overflow: "hidden", position: "relative" }}>
        <div className={styles.main}>
          <div className={styles.contact_main}>
            <div className={styles.contact_container}>
              <h1 className={styles.heading}><span>We'd love to </span><br />hear from you.</h1>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="outlined-search"
                  label="Phone number"
                  name="c_phone"
                  type="text"
                  variant="outlined"
                  style={{ margin: "20px 0" }}
                  onChange={handle}
                />
                <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
                <TextField
                  id="outlined-searchs"
                  label="Email"
                  name="c_email"
                  type="text"
                  variant="outlined"
                  style={{ margin: "20px 0" }}
                  onChange={handle}
                />
                <p style={{ margin: "0", color: "red" }}>{errors.c_email}</p>
                <TextField
                  id="outlined-multiline-static"
                  label="Your message"
                  name="c_message"
                  multiline
                  rows={10}
                  variant="outlined"
                  style={{ margin: "20px 0" }}
                  onChange={handle}
                />
                <p style={{ margin: "0", color: "red" }}>{errors.c_message}</p>
                <ColorButton
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                  onClick={SubmitData}
                  style={{padding:'8px', borderRadius: '12px'}}
                >
                  SEND
                </ColorButton>
              </div>
            </div>
            <div className={styles.cont_image}>
            <img src={ContactImg} alt="contact" style={{width: '100%'}}></img>
          </div>
          </div>

          <div>
            {/* <h2 className={styles.ctc_us}>
              <span>Contact us</span>
            </h2> */}

            {/* <div className={styles.address}>
              {[1, 2, 3].map((x, val) => (
                <div key={val} className={styles.cts_block}>
                  <div style={{ textAlign: "center" }}>
                    <HomeWorkIcon />
                  </div>
                  <h4 style={{ margin: "5px 0", textAlign: "center" }}>City</h4>
                  <p>
                    <CallIcon /> 9958574839
                  </p>
                  <p>
                    <DraftsIcon /> abc@gmail.com
                  </p>
                  <p style={{ display: "flex", alignItems: "flex-start" }}>
                    <RoomSharpIcon /> Lorem ipsum dolar sit admet, consectetuer
                    adipiscing elit, sed
                  </p>
                </div>
              ))}
            </div> */}
          </div>
        </div>
        {/* <img src={backImg} alt="Background" className="bg_image"></img> */}
      </div>
      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={5000}
          message={msg}
        >
          <Alert severity="success">{msg}</Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Contact;
