import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Checkbox,
  Chip,
  CircularProgress,
  Slider,
  TextField,
} from "@material-ui/core";
import { Autocomplete, Rating } from "@material-ui/lab";
import {
  ArrowDropDown,
  ArrowDropUp,
  Compare,
  FilterListOutlined,
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import qs from "qs";

import "./index.css";
import RangeSlider from "../../Components/RangeSlider";
import LIVE from "../../Assets/LIVE.png";
import INPERSON from "../../Assets/coaching.png";
import COURSE from "../../Assets/mid.png";
import { api_call } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import PlacesAutocomplete from "../../Components/PlacesAutocomplete";
import { getCourseLowestPriceObj } from "../../Utils/helperFunctions";
import FilterBottomSheet from "../../Components/FilterBottomSheet";
import { MainDataContextApi } from "../../ContextApis/MainDataContextApi/MainDataContextApi";
import batch from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 31.svg";
import progress from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 29.svg";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as YourSvg } from "../../Assets/Teachlcaro/Assets/1x/clear.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "yellow",
  },
  clearIndicator: {
    backgroundColor: "gray",
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M9.5 15.475L6.025 12l-1.183 1.175L9.5 17.833l10-10-1.175-1.175z')", // your svg icon path here
        },
      },
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        content: "<p>clear</p>",
        // "& path": {
        //   d: "path('M210 150 c0 -53 4 -90 10 -90 6 0 10 37 10 90 0 53 -4 90 -10 90 -6 0 -10 -37 -10 -90z')" // your svg icon path here
        // }
      },
    },
  },
}));

let filters = { class: [], board: [], teacher: [], institute: [], school: [] };

export default function ExplorePage() {
  const { filter, setFilter } = useContext(UserCredsContext);
  const { setCompareCourseItems } = useContext(MainDataContextApi);
  const [loading, setLoading] = useState(true);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [courseCount, setCourseCount] = useState(0);
  const [viewDetails, setViewDetails] = useState(false);
  const [tagFilterObj, setTagFilterObj] = useState({});
  const [dropdownFilterObj, setDropdownFilterObj] = useState({});
  const [classSelected, setClassSelected] = useState([]);
  const [boardSelected, setBoardSelected] = useState({});
  const [teacherSelected, setTeacherSelected] = useState([]);
  const [instituteSelected, setInstituteSelected] = useState([]);
  const [multipleFilterObj, setMultipleFilterObj] = useState({});
  const [locationFilterObj, setLocationFilterObj] = useState({});
  const [priceRangeFilterObj, setPriceRangeFilterObj] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [allFilterList, setAllFilterList] = useState([]);
  const [filterOptions, setFilterOptions] = useState(filters);
  const [hasMore, setHasMore] = useState(true);
  const [nextPage, setNextPage] = useState(1);
  const history = useHistory();
  const observer = useRef();
  const classes = useStyles();
  // useEffect(() => {
  //   if (!filter || !filter.type) getCourseList();
  //   getFilterList();
  // }, []);

  useEffect(() => {
    if (!filter.cleared) {
      clearFilters();
      if (filter?.type) {
        let typ = filter.type;
        let obj = {};
        if (typ === "grade") {
          obj[typ] = filter.value.length && filter.value.map((v) => (v = v.id));
          setDropdownFilterObj(obj);
          setClassSelected(filter.value);
        } else if (typ === "exam") {
          obj[typ] = filter.value.length && filter.value.map((v) => (v = v.id));
          setMultipleFilterObj(obj);
        } else if (typ === "board") {
          obj[typ] = filter.value;
          setDropdownFilterObj(obj);
          setBoardSelected(filter);
        } else {
          obj[typ] = [filter.value];
          setMultipleFilterObj(obj);
        }
        getCourseList(obj);
      } else getCourseList();
    }
  }, [filter]);

  const lastItemRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getCourseList(
            {
              page: nextPage,
              ...dropdownFilterObj,
              ...multipleFilterObj,
              ...tagFilterObj,
              ...locationFilterObj,
              ...priceRangeFilterObj,
            },
            courseList
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getFilterList = () => {
    api_call
      .get("/content/courses_filter")
      .then((res) => {
        if (res.status === 200 && res.data.data && res.data.data.length) {
          let list = [];
          setFilterList(list);
          setAllFilterList(res.data.data);
          res.data.data.forEach((fltr) => {
            if (fltr.type === 2) {
              // setClassList(fltr.data);
              filters.class = fltr.data;
            } else if (fltr.type === 3) {
              // setBoardList(fltr.data);
              filters.board = fltr.data;
            } else if (fltr.type === 9) {
              // setBoardList(fltr.data);
              filters.teacher = fltr.data.map(
                (t) => (t = { ...t, title: `${t.first_name} ${t.last_name}` })
              );
            } else if (fltr.type === 6) {
              filters.institute = [...filters.institute, ...fltr.data];
            } else if (fltr.data.length) {
              list = [fltr, ...list];
            }
          });
          setFilterOptions(filters);
          setFilterList(list);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred");
      });
  };

  const getCourseList = (paramsObj = {}, list = []) => {
    if (list.length === 0) window.scrollTo(0, 0);
    setCourseList(list);
    setLoading(true);
    api_call
      .get("/content/course/", {
        params: paramsObj,
        paramsSerializer: (params) => {
          let str = qs.stringify(params);
          let s = str.replace(/\%5B(.*?)\%5D/g, "");
          return s;
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.data.length) {
          setCourseCount(res.data.total_count);
          list.length > 0
            ? setCourseList([...list, ...res.data.data])
            : setCourseList(res.data.data);
          if (res.data.next_page) {
            setNextPage(res.data.next_page);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        } else {
          setCourseCount(0);
          setHasMore(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "Error occurred");
      });
  };

  const tagFilterHandler = (type = "", value = 0) => {
    let obj = {};
    if (!tagFilterObj[type]) {
      if (type === "session") {
        obj = { session: [1, 2] };
      } else {
        if (type === "discount_type") {
          obj = { discount_value_min: 1 };
        }
        obj[type] = value;
      }
    }
    setTagFilterObj(obj);
    getCourseList({
      ...dropdownFilterObj,
      ...multipleFilterObj,
      ...locationFilterObj,
      ...priceRangeFilterObj,
      ...obj,
    });
  };

  const dropdownFilterHandler = (type = "", value = {}) => {
    if ((!value || !value.length) && filter.title) setFilter({ cleared: true });
    let obj = dropdownFilterObj;
    if (type === "board") {
      if (value?.id) {
        setBoardSelected(value);
        obj.board = value.id;
      } else {
        setBoardSelected({});
        delete obj.board;
      }
    } else if (type === "grade") {
      if (value.length) {
        setClassSelected(value);
        obj.grade = value.map((v) => (v = v.id));
      } else {
        setClassSelected([]);
        delete obj.grade;
      }
    } else if (type === "teacher") {
      if (value.length) {
        setTeacherSelected(value);
        obj.instructors = value.map((v) => (v = v.id));
      } else {
        setTeacherSelected([]);
        delete obj.instructors;
      }
    } else {
      if (value.length) {
        setInstituteSelected(value);
        obj.institute = value.map((v) => (v = v.id));
      } else {
        setInstituteSelected([]);
        delete obj.institute;
      }
    }
    getCourseList({
      ...tagFilterObj,
      ...multipleFilterObj,
      ...locationFilterObj,
      ...priceRangeFilterObj,
      ...obj,
    });
    setDropdownFilterObj(obj);
  };

  const priceRangeFilterHandler = (min = 0, max = 0, fltrObj = {}) => {
    getCourseList({ ...fltrObj, price_min: min, price_max: max });
    setPriceRangeFilterObj({ price_min: min, price_max: max });
  };

  const multipleFilterHandler = (type = "", value = 0) => {
    let obj = multipleFilterObj;
    if (obj.session && type === "type" && filter.value === value) {
      delete obj.session;
      setFilter({ cleared: true });
    } else if (obj.exam && filter.value === value) {
      delete obj.exam;
      setFilter({ cleared: true });
    } else if (type && type === "type") {
      if (obj.session) {
        let typ = obj.session;
        if (typ.length && typ.includes(value)) {
          typ = typ.filter((v) => v !== value);
          if (typ.length) {
            obj.session = typ;
          } else {
            delete obj.session;
          }
        } else {
          typ.push(value);
          obj.session = typ;
        }
      } else {
        obj.session = [value];
      }
    } else if (type) {
      if (obj[type]) {
        let typ = obj[type];
        if (typ.length && typ.includes(value)) {
          typ = typ.filter((v) => v !== value);
          if (typ.length) {
            obj[type] = typ;
          } else {
            delete obj[type];
          }
        } else {
          typ.push(value);
          obj[type] = typ;
        }
      } else {
        obj[type] = [value];
      }
    } else {
      if (obj.institute) {
        let typ = obj.institute;
        if (typ.length && typ.includes(value)) {
          typ = typ.filter((v) => v !== value);
          if (typ.length) {
            obj = typ;
          } else {
            delete obj.institute;
          }
        } else {
          typ.push(value);
          obj.institute = typ;
        }
      } else {
        obj.institute = [value];
      }
    }
    getCourseList({
      ...tagFilterObj,
      ...dropdownFilterObj,
      ...locationFilterObj,
      ...priceRangeFilterObj,
      ...obj,
    });
    setMultipleFilterObj(obj);
  };

  const locationFilterHandler = (lat, long) => {
    const obj = { lat: lat, long: long };
    getCourseList({
      ...dropdownFilterObj,
      ...multipleFilterObj,
      ...tagFilterObj,
      ...priceRangeFilterObj,
      ...obj,
    });
    setLocationFilterObj(obj);
  };

  const clearFilters = () => {
    getFilterList();
    setClassSelected([]);
    setBoardSelected({});
    setTeacherSelected([]);
    setInstituteSelected([]);
    setTagFilterObj({});
    setDropdownFilterObj({});
    setMultipleFilterObj({});
    setLocationFilterObj({});
    setPriceRangeFilterObj({});
  };

  return (
    <>
      <FilterBottomSheet
        visible={filterDrawer}
        allFilterList={allFilterList}
        onApply={(obj) => (getCourseList(obj), setFilterDrawer(false))}
        onClear={() => (
          setFilter({}), setPriceRangeFilterObj(null), setFilterDrawer(false)
        )}
        onClose={() => setFilterDrawer(false)}
      />
      <div className="explore-container">
        <div className="explore-header">Explore Courses</div>
        <div style={{ display: "flex", marginTop: 20 }}>
          <div className="filter-sidebar">
            <div className="clear">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => (setFilter({}), setPriceRangeFilterObj(null))}
              >
                {/* {filter.type ? "Clear All" : ""} */}
                Clear All
              </span>
            </div>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {courseCount} Courses
            </div>
            <PlacesAutocomplete locationHandler={locationFilterHandler} />
            {/* <Autocomplete
            autoComplete
            disablePortal
            blurOnSelect
            selectOnFocus
            forcePopupIcon={locationIcon}
            noOptionsText="No such location"
            options={locations}
            loading={true}
            loadingText="Searching..."
            getOptionLabel={(option) => option.title}
            // sx={{ width: 300 }}
            // closeIcon={null}
            style={{ marginTop: 15 }}
            popupIcon={<LocationOn />}
            classes={{
              root: "autocomplete-input",
              popper: "autocomplete-input",
            }}
            onInputChange={(e, v) => getLocations(v)}
            onClose={() => setLocationIcon(true)}
            onFocus={() => setLocationIcon(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Location"
                variant="outlined"
              />
            )}
          /> */}
            <Autocomplete
              autoComplete
              disablePortal
              blurOnSelect
              selectOnFocus
              options={filterOptions.class}
              getOptionLabel={(option) => option.title || option.text || ""}
              style={{ marginTop: 15 }}
              value={classSelected}
              closeIcon={<YourSvg />}
              multiple
              limitTags={1}
              classes={{
                root: "autocomplete-input",
                popper: "autocomplete-input",
              }}
              onChange={(e, v) => dropdownFilterHandler("grade", v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Grade / Class"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <li>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                    style={{ color: "#eb8413" }}
                    size="small"
                  />
                  {option?.title?.length > 15
                    ? option?.title.slice(0, 18) + "..."
                    : option.title || ""}
                </li>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={
                      option?.title?.length > 10
                        ? option?.title.slice(0, 10) + "..."
                        : option?.title || ""
                    }
                  />
                ))
              }
            />
            <Autocomplete
              autoComplete
              disablePortal
              blurOnSelect
              selectOnFocus
              options={filterOptions.board}
              getOptionLabel={(option) => option.title || ""}
              onChange={(e, v) => dropdownFilterHandler("board", v)}
              style={{ marginTop: 15 }}
              value={boardSelected}
              closeIcon={<YourSvg />}
              classes={{
                root: "autocomplete-input",
                popper: "autocomplete-input",
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Board" variant="outlined" />
              )}
            />
            <Autocomplete
              autoComplete
              disablePortal
              blurOnSelect
              selectOnFocus
              options={filterOptions.teacher}
              getOptionLabel={(option) => option.title || option.text || ""}
              style={{ marginTop: 15 }}
              value={teacherSelected}
              multiple
              limitTags={1}
              // className={classes.clearIndicator}
              closeIcon={<YourSvg />}
              classes={{
                root: "autocomplete-input",
                popper: "autocomplete-input",
              }}
              onChange={(e, v) => dropdownFilterHandler("teacher", v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Teacher / Instructor"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <li>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                    style={{ color: "#eb8413" }}
                    size="small"
                  />
                  {option?.title?.length > 15
                    ? option?.title.slice(0, 18) + "..."
                    : option.title || ""}
                </li>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={
                      option?.title?.length > 10
                        ? option?.title.slice(0, 10) + "..."
                        : option?.title || ""
                    }
                  />
                ))
              }
            />
            <Autocomplete
              autoComplete
              disablePortal
              blurOnSelect
              selectOnFocus
              options={filterOptions.institute}
              getOptionLabel={(option) => option.name || "aa"}
              style={{ marginTop: 15 }}
              value={instituteSelected}
              multiple
              limitTags={1}
              classes={{
                root: "autocomplete-input",
                popper: "autocomplete-input",
              }}
              closeIcon={<YourSvg />}
              onChange={(e, v) => dropdownFilterHandler("institute", v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="School / Institute"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <li>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                    style={{ color: "#eb8413" }}
                    size="small"
                  />
                  {option?.name?.length > 15
                    ? option?.name.slice(0, 18) + "..."
                    : option.name || ""}
                </li>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={
                      option?.name?.length > 8
                        ? option?.name.slice(0, 8) + "..."
                        : option?.name || ""
                    }
                  />
                ))
              }
            />
            {/* <Autocomplete
              autoComplete
              disablePortal
              blurOnSelect
              selectOnFocus
              options={filterOptions.school}
              getOptionLabel={(option) => option.name || option.text || ""}
              style={{ marginTop: 15 }}
              value={teacherSelected}
              multiple
              limitTags={1}
              classes={{
                root: "autocomplete-input",
                popper: "autocomplete-input",
              }}
              onChange={(e, v) => dropdownFilterHandler("school", v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="School/College"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <li>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                    style={{ color: "#eb8413" }}
                    size="small"
                  />
                  {option?.title?.length > 15
                    ? option?.title.slice(0, 18) + "..."
                    : option.title || ""}
                </li>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={
                      option?.title?.length > 10
                        ? option?.title.slice(0, 10) + "..."
                        : option?.title || ""
                    }
                  />
                ))
              }
            /> */}
            {priceRangeFilterObj && (
              <RangeSlider
                filterObj={{
                  ...dropdownFilterObj,
                  ...multipleFilterObj,
                  ...tagFilterObj,
                  ...locationFilterObj,
                }}
                onChange={({ min, max, obj }) =>
                  priceRangeFilterHandler(min, max, obj)
                }
              />
            )}
            <div className="filter-list">
              {filterList.map((filterCategory) => (
                <React.Fragment key={filterCategory.title}>
                  <h5 className="filter-type">{filterCategory.title}</h5>
                  <div className="filter-sublist">
                    {filterCategory.data &&
                      filterCategory.data.map((fltr) => (
                        <div key={fltr.id} className="filters">
                          <Checkbox
                            onChange={() => {
                              fltr.selected =
                                fltr.selected ||
                                (filter.type === filterCategory.key &&
                                  (filter.value.length
                                    ? filter.value.includes({
                                        id: fltr.id,
                                        title: fltr.title,
                                      })
                                    : filter.value === fltr.id))
                                  ? false
                                  : true;
                              multipleFilterHandler(
                                filterCategory.key,
                                fltr.id
                              );
                            }}
                            checked={
                              fltr.selected ||
                              (filter.type === filterCategory.key &&
                                (filter.value.length
                                  ? filter.value.find((e) => e.id === fltr.id)
                                  : filter.value === fltr.id))
                            }
                            style={{ color: "#eb8413" }}
                            size="small"
                          />
                          <p>
                            {fltr.name
                              ? fltr.name
                              : fltr.title
                              ? fltr.title
                              : fltr.first_name + " " + fltr.last_name}
                          </p>
                        </div>
                      ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="filter-container">
            {/* <div className="filter-banner"></div> */}
            <div className="filter-tags">
              <span
                onClick={() => setFilterDrawer(true)}
                className="filter-chip filter-mobile"
              >
                <FilterListOutlined style={{ fontSize: 13, marginRight: 5 }} />
                Filter
              </span>
              <span
                onClick={() => tagFilterHandler("session", 1)}
                className={
                  tagFilterObj.session?.length
                    ? "filter-chip activ"
                    : "filter-chip"
                }
              >
                Live
              </span>
              <span
                onClick={() => tagFilterHandler("featured", true)}
                className={
                  tagFilterObj.featured ? "filter-chip activ" : "filter-chip"
                }
              >
                Featured
              </span>
              <span
                onClick={() => tagFilterHandler("rating", 4)}
                className={
                  tagFilterObj.rating ? "filter-chip activ" : "filter-chip"
                }
              >
                Ratings 4+
              </span>
              <span
                onClick={() => tagFilterHandler("price_max")}
                className={
                  tagFilterObj.price_max === 0
                    ? "filter-chip activ"
                    : "filter-chip"
                }
              >
                Free
              </span>
              <span
                onClick={() => tagFilterHandler("discount_type", 1)}
                className={
                  tagFilterObj.discount_type
                    ? "filter-chip activ"
                    : "filter-chip"
                }
              >
                Offers
              </span>
            </div>
            {!loading && courseList.length < 1 ? (
              <div style={{ textAlign: "center", margin: "30px" }}>
                Currently there are no courses for this type.
              </div>
            ) : null}
            <div className="course-container">
              {courseList.map((course, i) => (
                <div
                  ref={i + 1 < courseList.length ? null : lastItemRef}
                  key={course.id}
                  style={
                    i + 1 < courseList.length
                      ? { borderBottom: "3px solid #ddd" }
                      : {}
                  }
                >
                  <div className="course">
                    <Link
                      target="_blank"
                      to={`/coursedetail/${course.id}/${course.entity_type}`}
                      className="plain-link"
                    >
                      <div className="img-container">
                        {course.session?.icon2 ? (
                          <div>
                            <img
                              src={course.session?.icon2}
                              alt="course img"
                              className="course-img"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "gray",
                              height: 80,
                              width: 80,
                              borderRadius: 8,
                              cursor: "pointer",
                            }}
                          ></div>
                        )}
                        <div className="iconContainer">
                          {course.is_certified && (
                            <img
                              src={batch}
                              alt="batch"
                              className="course-icon"
                            />
                          )}
                          <img
                            src={
                              course.session?.type === 3
                                ? COURSE
                                : course.session?.type === 2
                                ? INPERSON
                                : LIVE
                            }
                            className="session-icon"
                            alt="live icon"
                          />
                          <img
                            src={progress}
                            alt="progress"
                            className="course-icon"
                          />
                        </div>
                      </div>
                    </Link>
                    <div style={{ flex: 6 }}>
                      <Link
                        to={`/coursedetail/${course.id}/${course.entity_type}`}
                        className="plain-link"
                        target="_blank"
                      >
                        <h5 className="course-name">{course.title}</h5>
                      </Link>
                      <Rating
                        name="read-only"
                        value={course.average_rating}
                        precision={0.1}
                        readOnly
                        className="rating-star"
                      />
                      {/* <Link
                        to={`/coursedetail/${course.id}/${course.entity_type}`}
                        className="plain-link"
                      > */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: course.description
                            ? `<div>${course.description}</div>`
                            : `<p></p>`,
                        }}
                        className="course-content"
                      ></div>
                      {/* </Link> */}
                      <div
                        onClick={() => (
                          setCompareCourseItems(course),
                          history.push("/compare")
                        )}
                        // window.open("/#/compare", "_blank")
                        className="compare"
                      >
                        <Compare style={{ fontSize: 12 }} />
                        <span className="add-to"> Add to</span> Compare
                      </div>
                    </div>
                    <div className="discount-container">
                      <Link
                        target="_blank"
                        to={`/coursedetail/${course.id}/${course.entity_type}`}
                        className="plain-link"
                      >
                        <div style={{ textAlign: "right" }}>
                          {course.validity?.length > 0 &&
                            course.validity[0].discount_type &&
                            course.validity[0].discount_value && (
                              <div className="discounted">
                                {
                                  getCourseLowestPriceObj(course.validity)
                                    .discount
                                }{" "}
                                OFF
                              </div>
                            )}
                          <h5 className="price">
                            ₹
                            {
                              getCourseLowestPriceObj(course.validity)
                                .final_price
                            }
                          </h5>
                          {getCourseLowestPriceObj(course.validity).price >
                            0 && (
                            <span className="mrp">
                              ₹{getCourseLowestPriceObj(course.validity).price}
                            </span>
                          )}
                        </div>
                      </Link>
                      <br />
                      <div
                        onClick={() => {
                          if (course.features) {
                            setViewDetails(
                              viewDetails
                                ? null
                                : course.selected
                                ? false
                                : true
                            );
                            course.selected = course.selected ? false : true;
                          } else
                            history.push(
                              `/coursedetail/${course.id}/${course.entity_type}`
                            );
                        }}
                        className="view-details"
                      >
                        <span style={{ marginRight: 4 }} className="add-to">
                          View Course{" "}
                        </span>
                        Details
                        {course.selected ? <ArrowDropUp /> : <ArrowDropDown />}
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                  <Link
                    target="_blank"
                    to={`/coursedetail/${course.id}/${course.entity_type}`}
                    className="plain-link"
                  >
                    <div
                      style={{
                        display: course.selected ? "inline-flex" : "none",
                        padding: "0 20px 20px",
                      }}
                    >
                      {/* video */}
                      <div
                        style={{
                          minWidth: 220,
                          height: 120,
                          backgroundColor: "#ddd",
                          borderRadius: 8,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{ __html: course.features }}
                        className="course-feature"
                      ></div>
                      {/* <div>
                        {[1, 2, 3, 4].map((i) => (
                          <div key={i} className="details-list">
                            <Check
                              style={{ margin: "4px 6px 4px 26px", color: "green" }}
                              fontSize="small"
                            />
                            Lorem Ipsum
                          </div>
                        ))}
                      </div> */}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            {loading && (
              <div style={{ textAlign: "center", margin: "30px" }}>
                <CircularProgress style={{ color: "#eb8413" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
