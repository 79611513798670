import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [
  {
    title: "IIT JEE",
    price: 4000,
    enrollments: 5,
  },
  { title: "IIT JEE", price: 4000, enrollments: 5 },
  { title: "IIT Advance", price: 5000, enrollments: 5 },
  { title: "NEET", price: 6000, enrollments: 5 },
  { title: "AIIEE", price: 5000, enrollments: 5 },
  { title: "BDD", price: 3000, enrollments: 5 },
];

function Test() {
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  // const changePlayerOptions = () => {
  //   // you can update the player through the Video.js player instance
  //   if (!playerRef.current) {
  //     return;
  //   }
  //   // [update player through instance's api]
  //   playerRef.current.src([{src: 'http://ex.com/video.mp4', type: 'video/mp4'}]);
  //   playerRef.current.autoplay(false);
  // };

  return (
    <>
      <div>Rest of app here</div>
      <VideoTest src="https://player.vimeo.com/external/332240746.sd.mp4?s=1f84a896102e6e23b70c997ef42d641db9a080f8&profile_id=139&oauth2_token_id=1400759996" startDuration={30} onReady={handlePlayerReady} />
      <div>Rest of app here</div>
    </>
  );
}

function VideoTest(props) {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { src, startDuration, onReady } = props;

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const options = {
        controls: true,
        responsive: true,
        fluid: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: `${src}#t=${startDuration}`,
            type: "video/mp4"
          }
        ],
      };

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [src]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
}

export default Test;
