import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";

import "./Compare.css";
import { checkImage } from "../../Utils/Utils.js";
import addIcon from "../../Assets/addicon.svg";
import { MainDataContextApi } from "../../ContextApis/MainDataContextApi/MainDataContextApi";
import CompareCourse from "./CompareCourse";
import BUTTON3 from "../../Assets/homepage/BUTTON3.png";

class Compare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseList: [
                {
                    id: 2,
                    institute: {
                        id: 2,
                        name: "Lcarro Institute",
                        avatar: null,
                        user: 1,
                        entity_type: 2,
                        _entity_type: [],
                        partner_type: 3,
                    },
                    category: [],
                    language: null,
                    subject: [
                        {
                            id: 1,
                            provider_id: 2,
                            title: "Maths",
                            custom: "",
                            user: 1,
                        },
                    ],
                    grade: {
                        id: 15,
                        title: "Class IX",
                        provider_id: 19,
                        user: 1,
                    },
                    board: {
                        id: 39,
                        title: "ICSE",
                        provider_id: null,
                        user: 1,
                    },
                    exam: null,
                    session: {
                        id: 2,
                        title: "Live (1-on-1)",
                        type: 1,
                        icon1:
                            "https://prod-api.lcarro.com/files/9654_Converted_ZAq8XIe.pdf",
                        icon2:
                            "https://prod-api.lcarro.com/files/9654_Converted_qA9iM7P.jpg",
                    },
                    is_combo: false,
                    competency_str: null,
                    instructors: [
                        {
                            id: 14,
                            first_name: "Rupa",
                            last_name: "Majmudar",
                            avatar: null,
                            degree: null,
                            company: null,
                            location: null,
                        },
                    ],
                    image: null,
                    enrollments: 0,
                    reviewers: 0,
                    chapters: [],
                    students: [],
                    info: null,
                    is_certified: false,
                    offers: [],
                    combo: [],
                    is_purchased: false,
                    live_url: "https://meet.g10consultancy.com/MathsEntireSyllabus2",
                    validity: [
                        {
                            id: 2,
                            validity_period: null,
                            final_price: null,
                            enrollments: 0,
                            provider_id: null,
                            start: "2021-10-09",
                            end: "2022-10-09",
                            title: "Maths ICSE Entire Syllabus",
                            start_time: "11:30:00",
                            end_time: "13:00:00",
                            price: 32000,
                            discount_type: null,
                            discount_value: 0,
                            colour: "#81D6CE",
                            max_students: 1,
                            is_deleted: false,
                            monday: true,
                            tuesday: false,
                            wednesday: true,
                            thursday: false,
                            friday: true,
                            saturday: false,
                            sunday: false,
                        },
                    ],
                    course_content: null,
                    user_views_count: 0,
                    address: null,
                    course_type: "Non-Edunguru",
                    description: null,
                    start: null,
                    end: null,
                    title: "Maths Entire Syllabus",
                    features: null,
                    featured: false,
                    price: null,
                    selling_price: null,
                    duration: null,
                    duration_type: null,
                    prerequisites: null,
                    views: 45,
                    commence: null,
                    conclude: null,
                    competency: null,
                    entity_type: 3,
                    average_rating: null,
                    is_deleted: false,
                    created_by: null,
                    user_views: [],
                },
            ],
            showStickyCourse: false,
            titleMaxheight: null,
        };
        this.toggleContainer = createRef();
        this.couresList = createRef();
        this.getScrollEvent = this.getScrollEvent.bind(this);
        this.purchaseCourse = this.purchaseCourse.bind(this);
        this.courseList = createRef(null);
        this.domRefs = {};
    }

    static contextType = MainDataContextApi;

    componentDidMount() {
        window.scrollTo(0, 0);
        const { compareCourseItems, setCompareCourseItems } = this.context;
        this.setState({ courseList: compareCourseItems });
        window.addEventListener("scroll", this.getScrollEvent);
    }

    getScrollEvent(event) {
        console.log(window.scrollY, "event from scroll");
        if (this.courseList == null) {
            return null;
        }
        if (window.scrollY < 165) {
            this.setState({ showStickyCourse: false });
        } else {
            this.setState({ showStickyCourse: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps, prevState, "did updated");
        var largest = 0;
        var number = null;

        for (const [key, value] of Object.entries(this.domRefs)) {
            if (value == undefined) {
                return null;
            }
            console.log(value.clientHeight, "value.clientHeight");
            number = value.clientHeight;
            largest = Math.max(largest, number);
        }
        if (this.state.titleMaxheight == null) {
            this.setState({ titleMaxheight: largest });
        }
    }

    purchaseCourse(item) {
        console.log(item, "course item");
        const { addCourseToCart, setPurchaseId } = this.context;
        setPurchaseId(item.id);
        addCourseToCart(item, () => {
            this.props.history.push("/cart");
        });
    }

    render() {
        const { courseList, showStickyCourse, titleMaxheight } = this.state;
        console.log(titleMaxheight, "titleMaxheight");
        return (
            <div ref={this.toggleContainer}>
                <div className="homepage-s-l-c">
                    <div className="page-header-01">
                        <h2 className="home-c-t-01">Compare Courses</h2>
                        <p>Compare upto 4 courses based on your preferred options</p>
                    </div>
                    {showStickyCourse ? (
                        <div
                            className="course-cards-c-c"
                            style={{
                                position: "sticky",
                                top: "80px",
                                paddingTop: 10,
                                background: "white",
                                border: "none",
                            }}
                        >
                            <div className="course-cards-sc"></div>
                            {courseList.map((item, idx) => (
                                <div className="course-cards-sc overflow-course-title-content">
                                    <p>{item.title}</p>
                                </div>
                            ))}
                            {courseList.length < 4 && (
                                <div
                                    className="course-cards-sc"
                                    onClick={() => this.props.history.push("/explore")}
                                    style={{ justifyContent: "center" }}
                                >
                                    <p style={{ cursor: "pointer" }}>Add Course</p>
                                </div>
                            )}
                        </div>
                    ) : (
                            <div
                                className="course-cards-c-c"
                                ref={this.courseList}
                                style={{
                                    border: "none",
                                    borderRadius: "0px",
                                    borderBottom: "1px solid #d3d3d3",
                                }}
                            >
                                <div className="course-cards-sc"></div>
                                {courseList.map((item, idx) => (
                                    <div className="course-cards-sc">
                                        <div className="inner-c-c-d">
                                            <div className="inner-image-c-co">
                                                <img
                                                    src={checkImage(item.session?.icon2)}
                                                    style={{ width: "112px" }}
                                                />
                                            </div>
                                            <p
                                                ref={(ref) => {
                                                    this.domRefs[`titleRef${idx}`] = ref;
                                                }}
                                                style={{ height: `${titleMaxheight}px` }}
                                            >
                                                {item.title}
                                            </p>
                                            <p
                                                onClick={() =>
                                                    this.props.history.push(
                                                        `/coursedetail/${item.id}/${item.entity_type}`
                                                    )
                                                }
                                                className="course-p-compare-b"
                                                style={{
                                                    padding: "10px",
                                                    backgroundImage: `url(${BUTTON3})`,
                                                    backgroundSize: "100% 100%",
                                                }}
                                            >
                                                Enroll
                    </p>
                                        </div>
                                    </div>
                                ))}
                                {courseList.length < 4 && (
                                    <div className="course-cards-sc">
                                        <div
                                            onClick={() => this.props.history.push("/explore")}
                                            className="inner-c-c-d"
                                        >
                                            <div className="inner-image-c-co">
                                                <img
                                                    src={checkImage(addIcon)}
                                                    style={{
                                                        width: "112px",
                                                        opacity: "0.5",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                            <p style={{ cursor: "pointer" }}>Add Course</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    <CompareCourse courseList={courseList} />
                </div>
            </div>
        );
    }
}

export default withRouter(Compare);
