import zIndex from "@material-ui/core/styles/zIndex";
import React, { useState, useEffect, useContext } from "react";
import { api_call, api_call_token, base_url } from "../../Utils/Network";
import background from "../../Assets/Question/Logic and Maths/maths&logic.png";
import { Link } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
// import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./TakeTest.css";
import { Button, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Video from "../../Assets/ViewVideo/aaa.mp4";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row",
    justifyContent: "center",
  },
}));

function TakeTest() {
  const [question, setQuestion] = useState([]);
  const [count, setCount] = useState(1);
  const [test, setTest] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [activeButton, setActiveButton] = useState(0);
  const [visible, setVisible] = useState(false);
  const [result, setResult] = useState({});
  const [displayAns, setDisplayAns] = useState([]);
  const [value, setValue] = useState("0");
  const [videos, setVideo] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    getQuestions();
    // setVideo();
    // selectedAnswer();
    // SendData();
  }, []);

  const getQuestions = () => {
    api_call
      .get("offline/discover_your_self_test")
      .then((response) => {
        const quest = response.data.data;
        quest.map((x) => setQuestion(x.question));
        quest.map((x) => setTest(x.id));

        // setQuestion(quest);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    let val = event.target.value;
    val.toString();
    setValue(val);
    selectedAnswer(event.target.name, event.target.value);
  };

  const selectedAnswer = (quest, ans = 0) => {
    setActiveButton(ans);
    var index = answers.findIndex((x) => x.question === quest);
    console.log(index);
    if (index === -1) {
      setAnswers([...answers, { question: quest, answer_choice: ans }]);
    } else {
      answers[index].question = quest;
      answers[index].answer_choice = ans;
    }
  };

  const SendData = () => {
    let data = {
      test: test,
      data: answers,
    };
    console.log(data);
    api_call_token
      .post(`offline/dys_answers/`, data)
      .then((response) => {
        setVisible(true);
        const finalresult = response.data.data;
        // const [{Bodily_kinesthetic,Interpersonal, Intrapersonal, Lingusitics, Logic_and_Math, Musical, Naturalistic}] = response.data.data;
        setResult(finalresult);
        setDisplayAns([
          ...displayAns,
          finalresult.Bodily_kinesthetic,
          finalresult.Interpersonal,
          finalresult.Intrapersonal,
          finalresult.Lingusitics,
          finalresult.Logic_and_Math,
          finalresult.Musical,
          finalresult.Naturalistic,
          finalresult["Visual-Spatial"],
        ]);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  console.log(question, "Quest");
  // console.log(value)
  console.log(answers, "ANS");
  console.log(videos, "video");
  // console.log(result, "Quests");
  // console.log(displayAns, "Ans")
  // console.log(answers[count]?.answer_choice, "hello")
  return (
    <div>
      <div style={{ margin: "100px 0 30px" }}>
        {!visible ? (
          <div>
            {question.length > 0 &&
              question.map((x) => (
                <>
                  {x.id === count ? (
                    <div
                      key={x.id}
                      style={{ position: "relative", height: "100vh" }}
                    >
                      <img
                        src={x.background_img}
                        alt="image"
                        style={{
                          position: "absolute",
                          zIndex: "-1",
                          width: "100%",
                          height: "100vh",
                        }}
                      ></img>

                      {x.question_type === 4 && videos === true ? (
                        <video
                          controls
                          autostart
                          autoPlay
                          src={Video}
                          type="video/mp4"
                          onEnded={() => setVideo(false)}
                          style={{
                            position: "absolute",
                            zIndex: "2",
                            width: '100%',
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <div className="test_container">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <h1 className="test_question">{x.title}</h1>{" "}
                          <span style={{ fontSize: "20px", fontWeight: "900" }}>
                            {count}/{question.length}
                          </span>
                        </div>
                        <div className="clas_answer">
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={value.toString()}
                            onChange={handleChange}
                            className={classes.root}
                          >
                            {x.choices.map((val, index) => (
                              <>
                                <FormControlLabel
                                  key={index}
                                  value={val.id.toString()}
                                  name={x.id.toString()}
                                  control={<Radio />}
                                  label={val.title}
                                  checked={answers.some(
                                    (e) =>
                                      e.question == x.id &&
                                      e.answer_choice == val.id
                                  )}
                                  style={{ width: "40%" }}
                                />
                              </>
                            ))}
                          </RadioGroup>
                          {/* {<Button} */}
                          {/* //   key={val.id}
                            //   onClick={() => selectedAnswer(x.id, val.id)}
                            //   variant="outlined"
                            //   color="primary"
                            //   style={{ */}
                          {/* //     width: "220px",
                            //     margin: "10px 20px",
                            //     fontSize: "17px",
                            //     backgroundColor: 'aliceblue',
                            //   }}
                            //   className={`${ */}
                          {/* //     activeButton === val.id ? "btn_success" : null
                            //   }`}
                            // >
                            //   {val.title}
                            // </Button> */}
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {count === 1 ? (
                            <></>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "140px", fontSize: "17px" }}
                              onClick={() => (
                                setActiveButton(
                                  answers[count - 2]?.answer_choice
                                ),
                                setCount((prevCount) => prevCount - 1)
                              )}
                            >
                              prev
                            </Button>
                          )}
                          {question.length === count ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "140px", fontSize: "17px" }}
                              onClick={() => SendData()}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "140px", fontSize: "17px" }}
                              onClick={() => (
                                setActiveButton(answers[count]?.answer_choice),
                                setCount((prevCount) => prevCount + 1)
                              )}
                            >
                              next
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </div>
        ) : (
          <div style={{ backgroundColor: "#688ae8", padding: "80px 0" }}>
            <div
              style={{
                width: "900px",
                margin: "0 auto",
                padding: "46px",
                backgroundColor: "white",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    width: "68px",
                    margin: "0 auto",
                    marginBottom: "50px",
                  }}
                >
                  <span className="result">Results</span>
                  <div style={{ border: "2px solid", color: "orange" }}></div>
                </div>
              </div>
              <div>
                {/* <h1>Intrapersonal</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna.
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna.
                </p> */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {[
                  "Bodily kinesthetic",
                  "Interpersonal",
                  "Intrapersonal",
                  "Lingusitics",
                  "Logic and Math",
                  "Musical",
                  "Naturalistic",
                  "Visual-Spatial",
                ].map((x, i) => (
                  <div key={i} style={{ width: "100px" }}>
                    <div className="green_block_container" key={x}>
                      {displayAns[i] > 15 ? (
                        [1, 2, 3, 4].map((box) => (
                          <div key={box} className="green_block"></div>
                        ))
                      ) : displayAns[i] > 10 ? (
                        [1, 2, 3].map((box) => (
                          <div key={box} className="green_block"></div>
                        ))
                      ) : displayAns[i] > 5 ? (
                        [1, 2].map((box) => (
                          <div key={box} className="green_block"></div>
                        ))
                      ) : displayAns[i] > 1 ? (
                        <div className="green_block"></div>
                      ) : (
                        <div className="green_block_grey"></div>
                      )}
                    </div>
                    <p style={{ wordBreak: "break-word" }}>{x}</p>
                  </div>
                ))}
              </div>

              <div style={{ fontSize: "20px", textAlign: "right" }}>
                Thank you for Taking Test <Link to="/">return back</Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TakeTest;
