import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height:'250px',
    width:"250px",
  },
  bottom: {
    position:'absolute',
    left: 0,
    // color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    color:'rgba(235, 132, 19, 0.36)',
  },
  top: {
    color: '#EB8413',
    animationDuration: '',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

function LzCircularProgress(props, style) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
        style={{ width: "250px", height: "250px" }}
      />
      <CircularProgress
        // disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
        style={{ width: "250px", height: "250px" }}
      />
      <Typography className={classes.text} variant="caption" component="div" color="textSecondary" style={{fontSize:"40px", }}>
        {`${Math.round(props.value)}%`}
      </Typography>
    </div>
  );
}

export default LzCircularProgress; 
// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//   },
// });