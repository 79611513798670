import React, { useEffect, useState } from "react";
import { Checkbox, Drawer } from "@material-ui/core";

import styles from "./index.module.css";

export default function FilterBottomSheet({
  visible = false,
  allFilterList = [],
  onApply,
  onClear,
  onClose,
}) {
  const [filterObj, setFilterObj] = useState({});
  const [selected, setSelected] = useState({});

  useEffect(() => setSelected(allFilterList.length ? allFilterList[0] : {}), [
    allFilterList,
  ]);

  const onSelectFilter = (v = false, id = 0) => {
    let obj = filterObj;
    if (v) {
      if (selected.key) {
        console.log(obj[selected.key], "course ");
        if (obj[selected.key]) obj[selected.key] = [...obj[selected.key], id];
        else obj[selected.key] = [id];
      } else {
        if (obj.institute) obj.institute = [...obj.institute, id];
        else obj.institute = [id];
      }
      setFilterObj(obj);
    } else {
      if (selected.key) {
        if (obj[selected.key].length > 1)
          obj[selected.key] = obj[selected.key].filter((o) => o.id === id);
        else delete obj[selected.key];
      } else {
        if (obj.institute.length > 1)
          obj.institute = obj.institute.filter((i) => i.id === id);
        else delete obj.institute;
      }
      setFilterObj(obj);
    }
  };

  return (
    <Drawer open={visible} onClose={onClose} anchor="bottom">
      <div className={styles.header}>Filters</div>
      <div className={styles.content}>
        <div className={styles.filterBy}>
          {allFilterList.map((filterBy) => (
            <div
              onClick={() => (window.scrollTo(0, 0), setSelected(filterBy))}
              key={filterBy.key}
              className={styles.filterContainer}
            >
              <div
                style={{
                  backgroundColor:
                    filterBy.title === selected.title ? "#133268" : "#fff",
                }}
                className={styles.activ}
              ></div>
              <p className={styles.filterType}>{filterBy.title}</p>
            </div>
          ))}
        </div>
        <div className={styles.filterList}>
          {selected.data &&
            selected.data.map((filterOf) => (
              <div
                key={filterOf.id + selected.key}
                className={styles.listContainer}
              >
                <Checkbox
                  className={styles.filterCheck}
                  style={{ color: "#eb8413" }}
                  size="small"
                  onChange={(e, v) => onSelectFilter(v, filterOf.id)}
                  checked={
                    (filterObj[selected.key] || filterObj.institute) &&
                    filterObj[selected.key].includes(filterOf.id)
                  }
                />
                <span
                  onClick={(e, v) => onSelectFilter(v, filterOf.id)}
                  className={styles.filterName}
                >
                  {filterOf.title || filterOf.name ||
                    filterOf.first_name + " " + filterOf.last_name}
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.footer}>
        <span
          onClick={onClear}
          style={{ borderRight: "1px solid #ddd" }}
          className={styles.footerText}
        >
          CLEAR
        </span>
        <span onClick={() => onApply(filterObj)} className={styles.footerText}>
          APPLY
        </span>
      </div>
    </Drawer>
  );
}
