import React, { useState, useRef } from 'react';
import { Icon, Grid, Avatar } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import './CourseInstructorInfo.css';
import GradeSharpIcon from '@material-ui/icons/GradeSharp';
import RateReviewIcon from '@material-ui/icons/RateReview';
import GroupIcon from '@material-ui/icons/Group';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import GradeIcon from '@material-ui/icons/Grade';

import download from '../../Assets/download.jpg';


function CourseInstructorInfo() {

  const tabRef = useRef(null);
  const faqRef = useRef(null);
  const reviewRef = useRef(null);

  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const choose1 = () => {
    if (reviewRef != null) {
      setTab1(true);
      setTab2(false);
    }
  }

  const choose2 = () => {
    if (faqRef != null) {
      setTab1(false);
      setTab2(true);
    }
  }

  return (
    <div style={{ }}>
      <p className="inst-head">Instructor</p>


      <div className="inst-cs-ifo" >
        <div >
          <Avatar src={download} style={{
            width: "7rem",
            height: "7rem"
          }} />
        </div>

        <div>
          <p className="instr-name">Lorem Ipsum </p>
          <p style={{display:'flex',alignItems:'center'}}><GradeIcon style={{color:'#F7BB56'}}/> &nbsp; 4.5 Rating</p>
        </div>



      </div>

      <div className="content" ref={tabRef} style={{ marginTop: "80px" }}>
        <div className="nav-tab-insts">
          <p className={`exp-text ${tab1 ? 'borderinsts' : 'borderinstsn'}`}
            style={{ fontSize: '16px' }}
            onClick={() => choose1()}>
            Reviews</p>

          <p className={`exp-text ${tab2 ? 'borderinsts' : 'borderinstsn'}`}
            style={{ fontSize: '16px' }}
            onClick={() => choose2()}>
            FAQ</p>
        </div>

      </div>

    </div>
  );
}

export default CourseInstructorInfo;