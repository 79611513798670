import React, { useRef, useState, useContext } from "react";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token, login_api, setLcarroApi } from "../../Utils/Network";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import bannerSignup from "../../Assets/brainhap/bannerSignup.png";

function Authenticate({ closeModal, ...props }) {
  const {
    updateLoginData,
    loginData,
    userType,
    partnerType,
    setToken,
    setStudentProfile,
    setUser,
    studentProfile,
    user,
    token,
  } = useContext(UserCredsContext);

  const [otp, setOtp] = useState([]);
  const list = [...Array(4).keys()];
  const inputRef = useRef([]);
  const [errors, setErrors] = useState({});
  const [enable, setEnable] = useState(true);
  const history = useHistory();
  console.log(history);

  const handler = (idx) => () => {
    setErrors({});
    const next = inputRef;
    const { value } = inputRef.current[idx];
    var otp_number = [...otp];
    otp_number[idx] = value;
    setOtp(otp_number);
    if (value.length !== 0 && idx < 3 && next) {
      next.current[idx + 1].focus();
    }
    if (otp_number.length === 4) {
      setEnable(false);
    } else if (otp_number.length !== 4) {
      setEnable(true);
    }
  };

  const sendOtp = () => {
    if (otp.length < 4) {
      setErrors({ otp: "please enter otp" });
      return null;
    }

    var otp_number = otp.join("");
    console.log("OTP", otp_number);
    const datas = {
      mobile: +loginData.phone,
      otp: +otp_number,
    };
    console.log("DD", datas);
    login_api
      .post(`/auth/lcarro/${loginData.id}/verify_otp/`, datas)
      .then((response) => {
        const { status, message } = response.data.data;
        console.log(status);
        console.log(!status);
        //var redirect = status.includes("f");
        if (!status) {
          setErrors({ message });
        } else {
          console.log("UserType", userType);
          if (userType == 8) {
            // history.push('/teachersregistration')
            window.location.href = "https://coaching.lcarro.com/#/";
            closeModal();
          } else if (userType == 7 && partnerType == 1) {
            history.push("/coachinginstituteregistration");
            closeModal();
          } else if (userType == 7 && partnerType == 4) {
            history.push("/onlinepartnersregistration");
            closeModal();
          } else if (userType == 7 && partnerType == 2) {
            history.push("/schoolregistration");
            closeModal();
          } else if (userType == 1) {
            props.history.push("/user-registration");
            closeModal();

            // updateLoginData({
            //   type: "usr",
            // });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data, "OTP error");
          setErrors({ otp: error.response.data.data.status });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const backToLogin = () => {
    updateLoginData({
      otp: "",
      type: "login",
    });
  };

  return (
    <div>
      <div className="signin_content">
        <div className="stud_dialogue stud_dialogs">
          {/* style={{backgroundImage: `url(${studs})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}} */}
          <img src={bannerSignup} alt="StudentIcon" className="studImg" />
        </div>
        <div className="sign_cont_box">
          <div className="m_inner_content">
            <p>Enter the code sent to your phone</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className="phone-num-otp">
              <p>
                {loginData.code} &nbsp;{loginData.phone}
              </p>
              <div className="edit-icon-float" onClick={backToLogin}>
                <EditIcon fontSize="small" />
              </div>
            </div>
          </div>
          <div className="otp-input-field">
            {list.map((x, id) => (
              <div>
                <input
                  key={x}
                  ref={(el) => (inputRef.current[x] = el)}
                  onChange={handler(x)}
                  type="text"
                  className="otp_box"
                  name={`otp_box${id}`}
                  maxLength="1"
                />
              </div>
            ))}
          </div>
          {/* <p className="error-message-text">{errors.otp}</p> */}
          <p className="error-message-text">{errors.message}</p>

          <div className="resendotp">
            <p>
              Didn't receive OTP? <a>Resend</a>
            </p>
          </div>
          <div className="button-login-c">
            <Button
              variant="contained"
              className="button-login-cb"
              onClick={() => sendOtp()}
              color="primary"
              style={{
                borderRadius: "12px",
                width: "33%",
                fontSize: "18px",
                fontWeight: "550",
              }}
              disabled={enable}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Authenticate);
