import React, { useState } from "react";

function useHandleChange(initialValue) {
    const [value, setValue] = useState(initialValue);

    const reset = () => {
        setValue(initialValue);

    }

    const bind = {
        value: value,
        onChange: (event) => {
            let name = event.target.name
            console.log(`The name is ${name} and the value is ${event.target.value}`)
            setValue({ ...value, [name]: event.target.value })
        },
        variant: 'outlined',
    }

    return [value, bind, reset]
}



const useTFprops = (name, style = {}) => {
    if (typeof (style) !== "object" && style !== null) {
        throw new TypeError("Invalid type for style.Object required.")
    }
    const bind = {
        label: name, name: name.toLowerCase(), variant: "outlined", style: { width: '100%', margin: '10px', ...style }
    }
    return bind
}



const useBtnProps =
{
    color: "secondary",
    variant: 'contained'


}


const useBtnPropsPrimary = {
    color: "primary",
    variant: 'contained'


}

const ButtonDivRight = props => <div style={{ textAlign: 'right', width: '100%', padding: '10px' }}>
    {props.children}

</div>


function ToggleState(params = false) {

    const [open, setOpen] = React.useState(params);

    const toggleOpen = () => {
        setOpen(!open)
    }
    const bind = {
        value: open,
        onClick: toggleOpen,
    }

    return [open, toggleOpen, bind]
}


const OutlinedTfProp = {
    style: {
        margin: '10px auto',
        width: '90%',
    },
    variant: "outlined"
}

export {OutlinedTfProp, ToggleState, useHandleChange, useTFprops, useBtnProps, useBtnPropsPrimary, ButtonDivRight };