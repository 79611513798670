import React from 'react';
import PropTypes from 'prop-types';
import ChapterAccordianItem from '../ChapterAccordianItem/ChapterAccordianItem';



const CourseChapterAccordian = ({chapterList=[],...props}) => {
    return (
        <div>
            {chapterList.map(chapter=><ChapterAccordianItem  key={chapter.id} chapter={chapter} />)}
        </div>
    );
};

CourseChapterAccordian.propTypes = {

};








export default CourseChapterAccordian;