import React,{useState} from "react";
import "./Model.css";
import Teach from "../../Assets/Onboard/Teacher.png";
import Stud from "../../Assets/Onboard/STUDENT.png";
import school from "../../Assets/Onboard/School.png";
import onlinCoach from "../../Assets/Onboard/Online Coaching.png";
import Inst from "../../Assets/Onboard/Institute.png";
import { School } from "@material-ui/icons";
import ModelInner from "../ModelPageInner/ModelInner";

function Model({ closeModel }) {
  const [openform, setOpenForm] = useState(false);

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
          <h1>Sign Up As</h1>
        </div>
        <div className="body">
          <div className="body_container">
            <div className="bdy_box" onClick={()=>{setOpenForm(true)}}>
              <img src={Stud} alt="teacher" className="icon_model"></img>
              <p>Student</p>
              {openform && <ModelInner closeStud={setOpenForm}/>}
            </div>
            <div className="bdy_box">
              <img src={Teach} alt="teacher" className="icon_model"></img>
              <p>Teacher</p>
            </div>
            <div className="bdy_box">
              <img src={school} alt="teacher" className="icon_model"></img>
              <p>School</p>
            </div>
            <div className="bdy_box">
              <img src={onlinCoach} alt="teacher" className="icon_model"></img>
              <p>Online partner</p>
            </div>
            <div className="bdy_box">
              <img src={Inst} alt="teacher" className="icon_model"></img>
              <p>Coaching Institute</p>
            </div>
          </div>
        </div>
        <div className="footer">
          <button onClick={() => closeModel(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default Model;
