import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CourseChapterAccordian from '../../Components/CourseChapterAccordian/CourseChapterAccordian';
import { api_call, api_without_token, api_without_token_alternate, base_url } from '../../Utils/Network';
import CourseInfo from '../../Components/CourseInfo/CourseInfo';
import CourseInstructorInfo from '../../Components/CourseInstructorInfo/CourseInstructorInfo';
import './CourseDetailPage.css';
import { falseChecker, responsiveCourses } from '../../Utils/Utils';
import MainDataContextApi from '../../ContextApis/MainDataContextApi/MainDataContextApi';
import Carousel from 'react-multi-carousel';
import CourseCardV2Home from '../../Components/CourseCardHome/CourseCardV2Home';
import RateAndReview from '../../Components/RateAndReview/RateAndReview';
import bgImage from '../../Assets/providerProfile/BG.svg';
import Payment from '../../Components/Payment/Payment';

import { UserCredsContext } from '../../ContextApi/UserCredsContext/UserCredsContext';

class CourseDetail extends Component{
    static contextType = UserCredsContext;
    state = {
        course_data: { chapters: [], description: "<p></p>", validity: [] },
        ratingNreview: {
            user_review: "",
            user_rating: 0
        },
        courseReviews: [],
        datareq: {
            five: '', four: '', three: '', two: '', one: ''
        },
        like: {},
        dislike: {},
        aggregateRating: { one: 0, two: 0, three: 0, four: 0, five: 0 },
        orderId : '',
        validityId1:null
    }
    // static contextType = MainDataContextApi;
    componentDidMount() {
        const { match } = this.props;
        this.getCourse(match.params.course_id);
        window.scrollTo(0, 0);
        console.log(this.props, 'check route path')
    }
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        if (match.params.course_id !== prevProps.match.params.course_id) {
            this.getCourse(match.params.course_id);
        }
    }
    getCourse = id => {
        if (falseChecker(id)) {
            api_without_token.get(`/content/course/${id}/`)
                // api_without_token.get(`/online/courses/3375/`)
                .then(res => {
                    console.log("course res", res);
                    const data = res.data.data;
                    this.topdata(data.id);
                    // this.inclike(data.id);
                    api_without_token.get(`/offline/review/?course=${data.id}`)
                        .then(response => {
                            console.log("Rviews response for course ", response.data.data);
                            // this.setState({
                            //     courseReviews: response.data.data
                            // })
                        })
                        .catch(error => {
                            console.error(error);
                        })
                    this.setState({
                        course_data: {
                            ...data, selected_validity: data.validity.length > 0 ? data.validity[0]["id"] : "",
                            price: data.validity.length > 0 ? data.validity[0]["price"] : data.price
                        },
                    })
                })
        }
    }

    topdata = (id) => {
        api_call.get(`/content/course/${id}/aggregate`)
            .then(response => {
                const { one, two, three, four, five } = response.data.data

                this.setState({
                    datareq: response.data.data,
                    aggregateRating: { one, two, three, four, five }
                })
                console.log(this.state.datareq, 'datareq')
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 403) {
                        let error_message = error.response.data.error.message;
                    }
                }
            })
    }
    handlePrice = event => {
        console.log("The handlePrice is clalld ", event.target);
        const new_seletcted_validity_id = event.target.value;
        const new_seletcted_validity = this.state.course_data.validity.find(item => item.id === new_seletcted_validity_id);
        if (falseChecker(new_seletcted_validity) && new_seletcted_validity !== -1) {
            let _course_data = Object.assign({}, this.state.course_data);
            _course_data.selected_validity = new_seletcted_validity_id;
            _course_data.price = new_seletcted_validity.price;
            this.setState({
                course_data: _course_data
            })
            // const { updateCartItem } = this.context;
            // updateCartItem(_course_data)
        }
    }
    updateCourseCart = value => {
        this.setState({
            course_data: value
        }, () => {
        })
    }
    

    
    
    
    enrollCourse = (validityId) => {
        const {setDisplayType,setOpenModel,token} = this.context;
        var network_access_check = localStorage.getItem("network_access_token");
        var context_access = token.access;
        // alert(context_access);
        if(context_access === undefined || context_access === null || context_access === '' || network_access_check === undefined || network_access_check === ''){
            setDisplayType(0)
            setOpenModel(true)
        } else {
            console.log(validityId,"enroll course validity id");
            console.log(this.state.course_data,"enroll new details");
            this.state.course_data.validity.map((item, index) => {
                if(item.id == validityId){
                    console.log(validityId,"checkvalidityId");
                    this.setState({
                        validityId1: validityId
                    })
                }       
              });
        }
        
        
    }
    handleReviewRatingChange = (name, value) => {
        console.log("handleReviewRatingChange ", name, value);
        const { ratingNreview } = this.state;
        ratingNreview[name] = value;
        this.setState({
            ratingNreview
        })
    }
    handleReviewSubmit = response => {
        console.log(response, 'rrrrr')
        this.setState((prev_state, prev_props) => ({
            courseReviews: [response.data.data, ...prev_state.courseReviews]
        }))
    }
    render(){
        console.log(this.state.aggregateRating, 'aggregateRatingaggregateRating')
        const { course_data, ratingNreview, courseReviews } = this.state;
        // alert(course_data, 'course_data');
        const v1 = this.state.datareq["one"];
        const v2 = this.state.datareq["two"];
        const v3 = this.state.datareq["three"];
        const v4 = this.state.datareq["four"];
        const v5 = this.state.datareq["five"];
        const x1 = this.state.datareq["one"] / (this.state.datareq["one"] + this.state.datareq["two"] + this.state.datareq["three"] + this.state.datareq["four"] + this.state.datareq["five"]) * 100;
        const x2 = this.state.datareq["two"] / (this.state.datareq["two"] + this.state.datareq["two"] + this.state.datareq["three"] + this.state.datareq["four"] + this.state.datareq["five"]) * 100;
        const x3 = this.state.datareq["three"] / (this.state.datareq["three"] + this.state.datareq["two"] + this.state.datareq["three"] + this.state.datareq["four"] + this.state.datareq["five"]) * 100;
        const x4 = this.state.datareq["four"] / (this.state.datareq["four"] + this.state.datareq["two"] + this.state.datareq["three"] + this.state.datareq["four"] + this.state.datareq["five"]) * 100;
        const x5 = this.state.datareq["five"] / (this.state.datareq["five"] + this.state.datareq["two"] + this.state.datareq["three"] + this.state.datareq["four"] + this.state.datareq["five"]) * 100;
        console.log(v1, v2, v3, v4, v5, x1, x2, x3, x4, x5, 'the x req')
        console.log(course_data.chapters,'course_data.chapters')
        return (
            <div style={{ paddingTop: '20vh', minHeight: '80vh', position: 'relative' }}>
                {/* <img src={bgImage} style={{ position: 'absolute', width: '100%', zIndex: '-1' }} /> */}
                <CourseInfo chapterList={course_data.chapters} onChange={this.updateCourseCart} handlePrice={this.handlePrice} value={course_data} enroll={this.enrollCourse} />

                {course_data.is_combo ?

                    <div>
                        {Array.isArray(course_data.combo) &&
                            <div>
                                <div className="course-combo-card-title">
                                    {course_data.combo.length == 1 && <h2 className="crs-info-head">Course Included</h2>}
                                    {course_data.combo.length > 1 && <h2 className="crs-info-head">Courses Included</h2>}
                                </div>
                                <Carousel responsive={responsiveCourses}>
                                    {course_data.combo.map((items, idxs) => {
                                        return (
                                            <div>
                                                <div className="course-card-h-c" style={{ position: 'relative' }}>
                                                    <div onClick={() => this.props.history.push(`/example/course/${items.id}`)}>
                                                        <CourseCardV2Home
                                                            button label="View Details"
                                                            color='red'
                                                            course={{ name: items.title, validity: items.validity, enrollment: items.enrollments, certified: items.is_certified, type: 'Live', rating: items.rating, board: items.board.title, grade: items.grade.title, subject: items.subject, price: '₹ ' + items.price }} provider_details={{ avatar: `${items.institute.avatar}`, name: items.institute.name }} course_highlights={items.description}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </Carousel>
                            </div>
                        }

                    </div>

                    :
                    <div>
                        {/* <div style={{ width: '85%', margin: '100px auto' }}>
                        <p className="wwyl-head"> What will you Learn. </p>
                        <CourseChapterAccordian chapterList={course_data.chapters} />
                    </div> */}

                    </div>
                }
                {/* <CourseInstructorInfo /> */}

                <div style={{ margin: 'auto', }}>
                    <RateAndReview
                        detail={course_data}
                        x1={x1} x2={x2} x3={x3} x4={x4} x5={x5} v1={v1} v2={v2} v3={v3} v4={v4} v5={v5}
                        totalRating={this.state.aggregateRating}
                        onChange={this.handleReviewRatingChange}
                        value={ratingNreview}
                        onSubmit={this.handleReviewSubmit}
                        postData={{ course_id: course_data.id }}
                        url={`${base_url}offline/review/`}
                        reviewList={courseReviews}
                        likeurl={`offline/review/${course_data.id}/like/`}
                        dislikeurl={`/offline/review/${course_data.id}/dislike/`} />
                </div>
                <Payment paymentData={{courseIdP:this.state.course_data.id,validityIdP:this.state.validityId1}}/>
            </div>
        );
    }
}

CourseDetail.propTypes = {

};

export default CourseDetail;