import FILLER from "../Assets/FILLER.png";
import { base_url_slash } from "./Network";
export const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
export const PasswordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const PhoneRegex = /^[0-9][0-9]{9}$/;
export const ZipCodeRegex = /^[0-9]{6}$/;

export const logo_colors = {
  blue: "#22326D",
  pink: "#fd0d59",
  yellow: "#fdc701",
};

export function checkImage(image) {
  console.log(image, "image is here");
  if (image !== null) {
    return image;
  } else {
    return FILLER;
  }
}

export function checkLowestPrice(_array, _keyname) {
  // let min = Math.min(..._array.map(item => item[_keyname]))
  let min = _array.reduce((prev, curr) =>
    prev[_keyname] < curr[_keyname] ? prev : curr
  );
  console.log(min, "minimum value");
  // _array.length !== 0 && _array.reduce(function(prev, curr) {
  //     console.log(prev.price < curr.price ? prev : curr);
  // });
  //console.log(lowest, "LOWEST")
  return { lowestValue: min };
}

export function checkImageSlash(image) {
  if (image == null) {
    return FILLER;
  } else {
    var newImage = `${base_url_slash}${image}`;
    return newImage;
  }
}

export function checkImageBase(image) {
  console.log(image, "null is handeled");
  if (image === null) {
    return FILLER;
  } else {
    var newImage = `${base_url_slash}${image}`;
    return newImage;
  }
}

export function getLeastPrice(lValue) {
  console.log(lValue, "lvaue");
  if (lValue !== undefined) {
    const lowestPrice = lValue.reduce((lowP, newP) =>
      lowP.discounted_price < newP.discounted_price ? lowP : newP
    );
    console.log(lowestPrice, "closest");
    var index = lValue.findIndex(
      (x) => x.discounted_price === lowestPrice.discounted_price
    );
    console.log(index, "indexindex");
  }
}

export function timeConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function getDateOrdinal(d) {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
}

export function splitStringDMY(date, splitString) {
  const split = date.split(splitString);
  var _year = split[0];
  var _month = split[1];
  var _date = split[2];
  return { _year, _month, _date };
}

export function monthNumberMapper(number, full) {
  switch (number) {
    case 0:
      return full ? "January" : "Jan";
    case 1:
      return full ? "February" : "Feb";
    case 2:
      return full ? "March" : "Mar";
    case 3:
      return full ? "April" : "Apr";
    case 4:
      return "May";
    case 5:
      return full ? "June" : "Jun";
    case 6:
      return full ? "July" : "Jul";
    case 7:
      return full ? "August" : "Aug";
    case 8:
      return full ? "September" : "Sept";
    case 9:
      return full ? "October" : "Oct";
    case 10:
      return full ? "November" : "Nov";
    case 11:
      return full ? "December" : "Dec";
    default:
      console.error("Invalid number passed to monthNumberMapper.");
  }
}

function debuglog(boolean, message, vars) {
  // degugger
  if (boolean) {
    console.log(message, vars);
  }
}

function urlParser(base_url, querys) {
  let string_query = Object.keys(querys).map((key) => {
    // map keys to traverse the array

    if (querys[key].length > 0) {
      // check if object keys not required although
      return querys[key].map((item) => `&${key}=${item}`).join("");
    }

    return undefined;
  });

  string_query = string_query.join("");
  return base_url + "?" + string_query.slice(1, string_query.length);
}

function indexToAlphabet(index) {
  switch (index) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    default:
      return undefined;
  }
}

function randomColor() {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

function secondsToHms(seconds) {
  if (falseChecker(seconds)) return null;
  let x = seconds;
  let hr = Math.floor(x / 3600);
  if (hr >= 0 && hr <= 9) hr = `0${hr}`;

  x = x % 3600;

  let min = Math.floor(x / 60);
  if (min >= 0 && min <= 9) min = `0${min}`;
  x = x % 60;
  let sec = Math.floor(x);
  if (sec >= 0 && sec <= 9) sec = `0${sec}`;
  if (hr === "00") return `${min}:${sec}`;
  else return `${hr}:${min}:${sec}`;
}

function convertToSecs(time_string) {
  console.log("The time_string is ", time_string);
  if (time_string !== undefined && time_string !== null) {
    let times = time_string.split(":");
    let time = undefined;
    if (times.length === 3) {
      time =
        parseInt(times[0]) * 3600 +
        parseInt(times[1]) * 60 +
        parseInt(times[2]);
    }
    return time;
  } else {
    return undefined;
  }
}

function date_toString(date) {
  var date_string;

  if (date !== null && date !== undefined && typeof date === "string") {
    var date_array = date.split("-");

    date_string = date_array[2] + "/" + date_array[1] + "/" + date_array[0];
  }

  return date_string;
}

export const GoogleMapsAPI = "AIzaSyAro4RUYU4ws4LWVWvds8eKHARoMzf1CsM";

function falseChecker(value) {
  if (
    value !== "" &&
    value !== undefined &&
    value !== null &&
    value !== false
  ) {
    return true;
  }
  return false;
}

export const responsiveCourses = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const saveLocalData = (type, value) => {
  localStorage.setItem(type, JSON.stringify(value));
};

export const getSavedData = (type) => {
  if (localStorage.getItem(type)) return JSON.parse(localStorage.getItem(type));
  return "";
};

export const appendImageUrl = (url = "") => {
  return base_url_slash.slice(0, base_url_slash.length - 1) + url;
};

export function isMobiledevice() {
  if (window.innerWidth < 770) return true;
  else return false;
}

export {
  falseChecker,
  urlParser,
  debuglog,
  indexToAlphabet,
  randomColor,
  secondsToHms,
  convertToSecs,
  date_toString,
};
