import React, { useState, useEffect } from "react";
import "./TeachOnLcarro.css";
import Carousel from "react-multi-carousel";

// import teacher from "../../Assets/Teachlcaro/Assets/1x/Asset 1 1.png";
// import teacher from "../../Assets/Teachlcaro/Assets/1x/Artboard 4.png";
import teacher from "../../Assets/Teachlcaro/Assets/1x/image1.png";

import forms from "../../Assets/Teachlcaro/Assets/1x/Asset 26.png";
import bulb from "../../Assets/Teachlcaro/Assets/1x/Asset 17.png";
import leaf from "../../Assets/Teachlcaro/Assets/1x/Asset 18.png";
import grp from "../../Assets/Teachlcaro/Assets/1x/Asset 24.png";
import mang from "../../Assets/Teachlcaro/Assets/1x/Asset 25.png";

import bag from "../../Assets/Teachlcaro/Assets/1x/Asset 19.png";
import degree from "../../Assets/Teachlcaro/Assets/1x/Asset 20.png";
import shelf from "../../Assets/Teachlcaro/Assets/1x/Asset 21.png";
import teachs from "../../Assets/Teachlcaro/Assets/1x/Asset 22.png";

import CrdOne from "../../Assets/Teachlcaro/Assets/1x/Asset 27.png";
import CardTwo from "../../Assets/Teachlcaro/Assets/1x/Asset 28.png";
import CardThree from "../../Assets/Teachlcaro/Assets/1x/Asset 29.png";

import chats from "../../Assets/Teachlcaro/Assets/1x/Asseasast 1.png";
import progr from "../../Assets/Teachlcaro/Assets/1x/Asset 2.png";
import pie from "../../Assets/Teachlcaro/Assets/1x/Asset 3.png";
import lock from "../../Assets/Teachlcaro/Assets/1x/Asset 4.png";
import measure from "../../Assets/Teachlcaro/Assets/1x/Asset 5.png";
import groups from "../../Assets/Teachlcaro/Assets/1x/Asset 6.png";
import protect from "../../Assets/Teachlcaro/Assets/1x/Asset 7.png";
import message from "../../Assets/Teachlcaro/Assets/1x/Asset 8.png";
import desk from "../../Assets/Teachlcaro/Assets/1x/Asset 9.png";
import moni from "../../Assets/Teachlcaro/Assets/1x/Asset 10.png";
import action from "../../Assets/Teachlcaro/Assets/1x/Asset 11.png";
import cal from "../../Assets/Teachlcaro/Assets/1x/Asset 12.png";
import less from "../../Assets/Teachlcaro/Assets/1x/Asset 13.png";
import yutb from "../../Assets/Teachlcaro/Assets/1x/Asset 14.png";
import pagefold from "../../Assets/Teachlcaro/Assets/1x/Asset 15.png";
import setting from "../../Assets/Teachlcaro/Assets/1x/Asset 16.png";
import search from "../../Assets/Teachlcaro/Assets/1x/Asset 17 1.png";

import ServicesBackground from "../../Assets/Teachlcaro/Assets/1x/Asset 31.png";
// import ServTeach from "../../Assets/Teachlcaro/Assets/1x/Asset 30.png";
import ServTeach from "../../Assets/Teachlcaro/Assets/1x/Artboard 2.png";

import DesktopMob from "../../Assets/Teachlcaro/Assets/1x/Asset 32.png";
import mobile from "../../Assets/Teachlcaro/Assets/SVG/MOBILEh.svg";
import Desk from "../../Assets/Teachlcaro/Assets/SVG/WEBh.svg";

import Apple from "../../Assets/app_home.png";
import Gplay from "../../Assets/google_home.png";

import Accordion from "@material-ui/core/Accordion";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { api_call, base_url } from "../../Utils/Network";
import { ListItemAvatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Caro from "../../Components/Carousels/Caro";

// import teachBack from '../../Assets/Teachlcaro/Assets/1x/Asset 23.png'
const useStyles = makeStyles({
  quest: {
    color: "#ee9435",
    fontSize: "23px",
    // fontWeight: "600",
  },
  quesDetail: {
    color: "#ee9435",
    fontSize: "16px",
    fontWeight: "500",
  },
  answerDetail: {
    color: "#6E7797",
    fontSize: "16px",
    fontWeight: "500",
  },
  typo: {
    borderLeft: "2px solid #F2AF68",
    backgroundColor: "#F7FAFF",
  },
  margins: {
    marginBottom: "10px",
  },
  answer: {
    borderLeft: "2px solid #6E7797",
  },
  ansTag: {
    color: "#6E7797",
    fontSize: "22px",
    marginRight: "10px",
  },
});
function TeachOnLcarro() {
  const [Teachlcaro, setTeaclcaro] = useState([]);
  const [teachBanner, setTeachBanner] = useState([]);
  const [getRoll, setGetroll] = useState([]);
  const [adapt, setAdapt] = useState([]);
  const [feature, setFeature] = useState([]);
  const [faq, setFAQ] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getDt();
    getDatas();
  }, []);

  const getDatas = () => {
    api_call
      .get("base/faq/")
      .then((response) => {
        setFAQ(response.data.data.splice(0, 3));
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };
  const classes = useStyles();

  const getDt = () => {
    api_call
      .get("/online/teach_on_lcarro/")
      .then((response) => {
        const [TeachBanner, GetRoll, Adapt, Features] = response.data.data;
        setTeaclcaro(response.data.data);
        setTeachBanner(TeachBanner);
        setGetroll(GetRoll);
        setAdapt(Adapt);
        setFeature(Features);
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  return (
    <>
      <div className="educ_emp_Showcase">
        <div className="educ_details">
          {teachBanner &&
            teachBanner.length !== 0 &&
            teachBanner.data.map((item, i) => {
              return (
                <>
                  <div className="heads">
                    <h1 className="educ_title">
                      {item.title.replace(/<[^>]+>/g, "")}
                    </h1>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    className="edu_para"
                  ></div>
                </>
              );
            })}
          <div className="edu_blocks">
            <div className="ebls edu_bl_one">Teachers</div>
            <div className="ebls edu_bl_two">Online Partners</div>
            <div className="ebls edu_bl_three">Educational Institutes</div>
            <div className="ebls edu_bl_four">Coaching Institutes</div>
          </div>
        </div>
        <div className="educ_emp">
          <div className="teacher_showcase">
            <img
              src={teacher}
              alt="teacher_showcase"
              className="teacher_child_showcase"
            ></img>
          </div>

          <div className="teach_des_one">
            <img
              src={teachs}
              alt="teachs"
              className="teach_top t_teacher_top"
            ></img>
            <p className="teach_paras">Teachers</p>
          </div>
          <div className="teach_des_two">
            <img
              src={shelf}
              alt="shelf"
              className="teach_top t_online_top"
            ></img>
            <p className="teach_paras">Online Content Partners</p>
          </div>
          <div className="teach_des_three">
            <img src={bag} alt="bag" className="teach_top"></img>
            <p className="teach_paras">Educational Institutes</p>
          </div>
          <div className="teach_des_four">
            <img src={degree} alt="degree" className="teach_top"></img>
            <p className="teach_paras">Coaching Institutes</p>
          </div>
        </div>
        <div className="teachBack">
          {/* <img src={teachBack} alt="teachBack" className="tb_img"></img> */}
        </div>
      </div>

      <div className="gt_rolling">
        <Caro imgs={getRoll} />
      </div>

      <div className="new_tech">
        <div className="new_tech_title">
          <p>Adapt a New way of Teaching</p>
        </div>
        <div className="new_tech_container">
          {adapt &&
            adapt.length !== 0 &&
            adapt.data.map((ind, kx) => {
              return (
                <div className="ntc ntc_one">
                  <img src={ind.file} className="ntc_icon" alt="leaf"></img>
                  <p>{ind.description.replace(/<[^>]+>/g, "")}</p>
                </div>
              );
            })}
        </div>

        <div className="ntc_partnering">
          <p>Partnering With</p>
        </div>
        <div className="ntc_about">
          <div className="ntc_ab_cont nac_one">
            <img src={teachs} alt="teachs" className="ntc_p_icon"></img>
            <p>Teachers</p>
          </div>
          <div className="ntc_ab_cont">
            <img
              src={shelf}
              alt="shelf"
              className="ntc_p_icon ntc_p_i_one"
            ></img>
            <p>Online Content Partners</p>
          </div>
          <div className="ntc_ab_cont">
            <img src={bag} alt="bag" className="ntc_p_icon"></img>
            <p>Educational Institutes</p>
          </div>
          <div className="ntc_ab_cont nac_four">
            <img src={degree} alt="degree" className="ntc_p_icon"></img>
            <p>Coaching Institutes</p>
          </div>
        </div>
      </div>

      <div className="our_feat">
        <div className="our_feat_heads">
          <h1>Our Features</h1>
        </div>
        <div className="our_feat_card">
          {feature !== undefined &&
            feature.length !== 0 &&
            feature.data.map((it_m, Ids) => {
              return (
                <div
                  className={"of_Card " + (it_m.id === 2 ? "of_Card_Adv" : "")}
                >
                  <div className="Card_title">
                    <div className="crd_head">{it_m.title}</div>
                    <div className="crd_head_img">
                      <img
                        src={it_m.image}
                        alt="CardThree"
                        className="crd_h_img"
                      ></img>
                    </div>
                  </div>
                  <div className="lists">
                    <ul>
                      {it_m !== undefined &&
                        it_m.length !== 0 &&
                        it_m.list.map((indx, ix) => {
                          return (
                            <div className="ourfeatlist">
                              <img
                                src={indx.file}
                                alt="less"
                                className="icon_list"
                              ></img>
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: indx.description,
                                }}
                                className="ourFeaturedetail"
                              ></li>
                            </div>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="faq-container">
        <div className="faq-class">
          <h1>Frequently Asked Questions (FAQ)</h1>
        </div>
        <div className="faq-sub">
          {faq &&
            faq.length !== 0 &&
            faq.map((item, k) => {
              return (
                <Accordion className={classes.margins}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.typo}
                  >
                    <Typography>
                      <span className={classes.quest}>Q.</span>{" "}
                      <span className={classes.quesDetail}>
                        {item.question}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.answer}>
                    <Typography>
                      <span className={classes.ansTag}>A.</span>
                      <span className={classes.answerDetail}>
                        {item.answer}
                      </span>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
        {/* <button className="faq-btn">AND MANY MORE</button> */}
      </div>

      {/* <div className="srr_main">
        <div className="service_main">
          <img
            src={ServicesBackground}
            alt="Services"
            className="serv_img"
          ></img>
          <div className="service_box_one">
            <h1 className="sbo_head">Services with a Personal Touch</h1>
            <p className="sbo_para">
              Take Advantage of our 24/7 Call Support, WhatsApp Support,
              unparalleled Tech Support and Social Media Support
            </p>
            <div>
              <p className="sbo_details">Need more detail before you start?</p>
              <button className="sbo_button">Learn more</button>
            </div>
          </div>
          <img src={ServTeach} alt="ServTeach" className="ServTeach"></img>
          {/* <div className="service_box_two">
          
        </div>  
        </div>
      </div> */}

      <div className="devices">
        <div className="device_child">
          <div className="app_Available_on">
            <div className="desk_top">
              <img src={Desk} alt="Desk" className="small_desk"></img>
              <h3 className="device_heads">Desktop</h3>
              <p className="device_para">All Browsers</p>
            </div>
            <div className="mobile_ios">
              <img src={mobile} alt="mobile" className="small_mobile"></img>
              <h3 className="device_heads">Mobiles</h3>
              <p className="device_para">iOS & Android</p>
            </div>
          </div>
          <div className="store_apps">
            <div className="str">
              <a href="https://apps.apple.com/in/app/lcarro-educators/id1573205884">
                <img src={Apple} alt="apple" className="Ap_store"></img>
              </a>
            </div>
            <div className="str">
              <a href="https://play.google.com/store/apps/details?id=com.lcarro.teachers">
                <img src={Gplay} alt="google" className="Gp_store"></img>
              </a>
            </div>
          </div>
        </div>

        <div className="device_child_second">
          <img src={DesktopMob} alt="Desktop" className="desktop"></img>
        </div>
      </div>
    </>
  );
}

export default TeachOnLcarro;
