import React, { useState, useEffect, useContext, useCallback } from "react";
import { api_call_token } from "../../Utils/Network";
import styles from "./TestSeries.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BookSlots from "../../Assets/HomeRewamp/BookSlots.png";
import { MainDataContextApi } from "../../ContextApis/MainDataContextApi/MainDataContextApi";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
// import lcarro from "../../Assets/lcarrologo.png";
import lcarro from "../../Assets/Logo/Artboard 1 copy 3.png";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// razorpay function
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    console.log(script, "script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function AskToCounselor(props) {
  const [dates, setDates] = useState([]);
  const { context_user } = React.useContext(MainDataContextApi);
  const { setDisplayType, setOpenModel, setFilter, user } =
    useContext(UserCredsContext);
  const [viewData, setViewData] = useState([]);
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [paymentId, setPaymentId] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [value, setValue] = useState(new Date());
  const [value2, setValue2] = useState(new Date());
  const [bookslotData, setBookSlotData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [showBTN, setShowBTN] = React.useState(false);
  let history = useHistory();

  const [minimumTime, setMinimumTime] = useState(500);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState({});
  const [contentState, setContentState] = useState(null);

  useEffect(() => {
    getCurrentResponse();
  }, [])


  const getCurrentResponse = () => {
    api_call_token
      .get(`counseling/purchase/test/payment_status/`)
      .then((res) => {
        console.log(res.data.data, "res")
        let price = res.data.data;
        setPrice(price);
        if (!price.expert_status) {
          if (price?.standard?.status && !price?.advance?.status) {
            setContentState(0);
            setOpen(true);
          } else {
            // alert("Please Complete all the test")
            setContentState(1)
            setOpen(true);
          }
        }
      })
      .catch((err) => console.log(err))
  }

  const restartTimeout = useCallback(() => {
    setMinimumTimeElapsed(false);
    setLoading(true);
    //simulate random load time between 0 and 5 seconds
    const randomLoadTime = Math.random() * 5000;
    setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, minimumTime);
    setTimeout(() => {
      setLoading(false);
    }, randomLoadTime);
  }, [setMinimumTimeElapsed, setLoading, minimumTime]);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  const ViewSlot = () => {
    setLoading(true);
    let startDateNew = moment(value).format("DD-MM-YYYY");
    api_call_token
      .get(
        // `counseling/all_slots/slots/?date=${"20-05-2022"}`
        `counseling/all_slots/slots/?date=${startDateNew}`
      )
      .then((res) => {
        setDates(res.data.data);
        // console.log(res);
        // setBreakDown(x);
        setViewData(6);
        setLoading(false);
      })
      .catch((err) => {
        // Alert(err);
        console.log(err);
        setLoading(false);
      });
  };

  const handelBooking = (data) => {
    if (price?.expert_status) {
      if (price?.slot_expire) {
        // alert("You have already booked the Session")
        setContentState(2)
        setOpen(true);
      } else {


        console.log(data, "DDDDDDDdd")
        setLoading(true);
        if (price?.expert_status) {
          api_call_token
            .patch(`counseling/purchase/test/update_slot/`, {
              slot_id: data?.instructor_slots[0]?.id,
            })
            .then((res) => {
              if (res?.data?.data?.id) {
                setOpen(true);
              }
            })
            .catch((err) => {

            })
        } else {
          api_call_token
            .post(`counseling/purchase/initiate_book_slot/`, {
              amount: price?.advance?.price,
            })
            .then((res) => {
              console.log(res.data, "solts result");
              const payData = res?.data?.data;
              const slotData = data?.instructor_slots[0]?.id;
              displayRazorPay(payData, slotData);
              console.log(slotData, "getpurchase body");
            })
            .catch((err) => console.log(err));
        }


        // }
        setLoading(false);
      }
    } else {
      if (price?.standard?.status && !price?.advance?.status) {
        setContentState(0);
        setOpen(true);
      } else {
        // alert("Please Complete all the test")
        setContentState(1)
        setOpen(true);
      }

    }

  };

  //Razorpay payment integration
  async function displayRazorPay(dataId, slotData) {
    setLoading(true);
    console.log(dataId, slotData, "data id passed solts");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (current_url.includes("localhost") || current_url.includes("staging")) {
      // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";

      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    } else {
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    }
    var courseprice = dataId.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Brainhap",
      description: "Course payment",
      image: lcarro,
      order_id: `${dataId.order_id}`,
      handler: function (response) {
        console.log(response.razorpay_payment_id, "hello");
        console.log(response.razorpay_order_id, "hello1");
        console.log(response.razorpay_signature, "hello2");
        setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId);
        console.log(slotData, "getpurchase body");
        getpurchase(response, dataId, slotData);
      },
      prefill: {
        name: `${context_user?.first_name}`,
        email: `${context_user?.email}`,
        contact: `${context_user?.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoading(false);
  }

  const getpurchase = (rpid, ordId, slotData) => {
    setLoading(true);
    // console.log(slotData, "getpurchase body");
    console.log(rpid, ordId, slotData, "rpid, ordId, slotData");
    var data = {
      transaction_id: rpid?.razorpay_payment_id,
      order_id: ordId?.id,
      slot_id: slotData,
      razorpay_signature: rpid?.razorpay_signature,
    };
    console.log(data, "value to pass in purchase");
    api_call_token
      .post(`counseling/purchase/order_book_slot/`, data)
      .then((response) => {
        console.log("The response for purchase course is ", response.data.data);
        // alert("Successfully Slot is Booked");
        if (response.data.data.id) {
          setContentState(3)
          setOpen(true);

        }
        // setSuccess(true);
      });
    // console.log(rpid, "rpid");
  };

  const [getDate, setGetDate] = useState({
    startDate: moment(value).format("DD-MM-YYYY"),
    endDate: moment(value2).format("DD-MM-YYYY"),
  });

  /////////////////////////



  const handleAdvanceBooking = (data) => {
    setLoading(true);
    // console.log(data, "solts");

    // if (data == null || undefined) {
    //   console.log(data, "price");
    // } else {
    let price = data * 100;

    api_call_token
      .post(`counseling/purchase/test/initiate_book_slot_and_test/`, {
        amount: price,
      })
      .then((res) => {
        console.log(res.data, "solts result");
        const payData = res?.data?.data;
        displayAdvRazorPay(payData);
      })
      .catch((err) => console.log(err));
    // }
    setLoading(false);
  };

  async function displayAdvRazorPay(dataId, slotData) {
    setLoading(true);
    // console.log(dataId, slotData, "data id passed solts");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (current_url.includes("localhost") || current_url.includes("staging")) {
      // var razorpay_key = "rzp_test_TW6h2zNAZqVdu1";
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    } else {
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    }
    var courseprice = dataId.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Brainhap",
      description: "Course payment",
      image: lcarro,
      order_id: `${dataId.order_id}`,
      handler: function (response) {
        console.log(response.razorpay_payment_id, "hello");
        console.log(response.razorpay_order_id, "hello1");
        console.log(response.razorpay_signature, "hello2");
        // setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId);
        // console.log(slotData, "getpurchase body");
        getAdvpurchase(response, dataId);
      },
      prefill: {
        name: `${user?.first_name}`,
        email: `${user?.email}`,
        contact: `${user?.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const getAdvpurchase = (rpid, ordId, slotData) => {
    setLoading(true);
    // console.log(slotData, "getpurchase body");
    console.log(rpid, ordId, "rpid, ordId, slotData");
    var data = {
      transaction_id: rpid?.razorpay_payment_id,
      order_id: ordId?.id,
      amount: price?.advance?.price,
      // razorpay_signature: rpid?.razorpay_signature,
    };
    console.log(data, "value to pass in purchase");
    api_call_token
      .post(`counseling/purchase/test/order_counseling_with_test/`, data)
      .then((response) => {
        console.log("The response for purchase course is ", response.data.data);
        // alert("Successfully Slot is Booked");

        if (response.data.data.id) {
          setOpen(false);
          getCurrentResponse()
          
        }
        // setSuccess(true);
      });
    // console.log(rpid, "rpid");
  };




  ////////////////

  useEffect(() => {
    restartTimeout();
    ViewSlot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setLoading(false);
  }, [value, open]);

  // console.log(user, "user");
  // console.log(bookslotData, "bookslotData");
  return (
    <div className={styles.CounselorContainer}>
      <div className={`${styles.displayMain} ${styles.displayFlex_Center}`} style={{ width: '70%', margin: '0 auto' }}>
        <Button
          onClick={() => {
            history.push("/");
          }}
          color="primary"
        >
          Go To Home
        </Button>
        <Button
          onClick={() => {
            history.push("/my-space");
          }}
          color="primary"
        >
          Check Booking
        </Button>
      </div>

      <div className={`${styles.finalResults} ${styles.displayFlex_Center}`}>

        {!minimumTimeElapsed || loading ? (
          <Skeleton style={{ height: "400px", width: "400px" }} />
        ) : (
          <img
            className={styles.BookSlotsImg}
            alt="Book Slots"
            src={BookSlots}
          />
        )}
        <div
          style={{
            margin: "0 auto",
            textAlignLast: "center",
            maxWidth: "60%",
            width: "inherit",
          }}
        >
          {!minimumTimeElapsed || loading ? (
            <div>
              <h1 style={{ textTransform: "capitalize" }}>
                <Skeleton />
              </h1>
              <p>
                <Skeleton />
              </p>
              <p>
                <Skeleton />
              </p>
              <p>
                <Skeleton />
              </p>
            </div>
          ) : (
            <>
              <div>
                <h1 style={{ textTransform: "capitalize" }}>
                  Amazing {user?.first_name}!
                </h1>
                <p>
                  you are all set to achieve excellence.
                  <br />
                  please select a time slot as per your preferences
                </p>
              </div>
              <div style={{ margin: "0 auto 22px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  style={{
                    background: "#49af49 !important",
                    border: "0px !important",
                    borderRadius: "50px !important",
                    overflow: "hidden !important",
                  }}
                  className={styles.datePickerContain}
                >
                  <DatePicker
                    style={{
                      background: "#49af49",
                      border: "0px",
                      borderRadius: "50px",
                      overflow: "hidden",
                    }}
                    allowKeyboardControl={false}
                    // disableMaskedInput={true}
                    // readOnly="true"
                    disablePast
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className={styles.SlotsBtnContainer}>
                {dates &&
                  dates.map((data, idx) => (
                    <Typography key={idx}>
                      <button
                        className={`${data.instructor_slots[0]?.is_available
                          ? styles.SlotsGreen
                          : styles.SlotsGray
                          } ${styles.res_break_sub}`}
                        style={{
                          backgroundColor:
                            data === bookslotData ? "#2f327d" : "",
                        }}
                        disabled={
                          data.instructor_slots[0]?.is_available ? false : true
                        }
                        onClick={() => setBookSlotData(data)}
                      // onClick={() => handelBooking(data)}
                      >
                        {moment(data.commence, "HH:mm:ss ").format("hh:mm A")}
                      </button>
                    </Typography>
                  ))}
              </div>
              {/* <div>
            <div className={styles.SlotsGree}></div>
            <div className={styles.SlotsGray}></div>
            <div className={styles.SlotsGree}></div>
          </div> */}
              <button
                className={`${styles.btn_starts_test} ${styles.btn_book_slot}`}
                disabled={bookslotData?.id ? false : true}
                // onClick={() => console.log("Slot booked")}
                style={{
                  backgroundColor: bookslotData.id ? "#172e92" : "#00000042",
                  width: "40% !important",
                }}
                onClick={() => handelBooking(bookslotData)}
              >
                Book Slot
              </button>
            </>
          )}
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          onClick: (event) => {
            event.stopPropagation();
          }
        }}
        // BackdropProps={{ invisible: true }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {contentState == 0 && <>
          <div className={styles.advancePay}>
            <div><h1>Make Payment To Book Your Career Counselling Session </h1></div>
            <div>
              <Button variant="contained" color="primary" onClick={() => handleAdvanceBooking(price?.advance?.price)}>
                Pay Now
              </Button>
            </div>
          </div>
        </>}
        {contentState == 1 && <>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <h1>Please Complete All The Tests First To Book Your Counselling Session.</h1>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                history.push("/testSeries");
              }}
              color="primary"
            >
              ok
            </Button>
          </DialogActions>
        </>}
        {
          contentState == 2 && <>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <h1>You Have Already Booked Your Counselling Session.</h1>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  history.push("/my-space");
                }}
                color="primary"
              >
                ok
              </Button>
            </DialogActions>
          </>
        }
        {
          contentState == 3 &&
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <h1>Congratulations! Your Slot Has Been Booked.</h1>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  history.push("/");
                }}
                color="primary"
              >
                Go To Home
              </Button>
              <Button
                onClick={() => {
                  history.push("/my-space");
                }}
                color="primary"
              >
                Check Booking
              </Button>
            </DialogActions>
          </>
        }

      </Dialog>
    </div>
  );
}
export default AskToCounselor;
