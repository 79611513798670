import React, { useState, useEffect } from "react";
import { api_call_token } from "../../Utils/Network";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import styles from "./TestSeries.module.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "@material-ui/lab/Skeleton";
function OverAllResult(props) {
  let { test_type } = useParams();
  const [viewData, setViewData] = useState(6);
  const [breakDown, setBreakDown] = useState([]);
  const [datas, setDatas] = useState({});
  const [loader, setLoader] = useState(true);
  const [ofldatas, setOflDatas] = useState({});
  const [interset, setInterest] = useState([])
  let history = useHistory();

  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
  }, []);

  const ViewFinalResult = () => {
    setLoader(false);
    api_call_token
      .get(`test/result_view/overall/?test_type=${test_type}`)
      .then((res) => {
        if (res.data.data.interest_test_category_and_percentage) {

          setInterest(res.data.data.interest_test_category_and_percentage)
        }else if(res.data.data.all_question_acadmic_breakdown){
          setInterest(res.data.data.all_question_acadmic_breakdown)
        }
        const { all_question_breakdown } = res.data.data;
        setDatas(res.data.data);
        setBreakDown(all_question_breakdown);
        setViewData(6);
        window.localStorage.removeItem("answer");
        window.localStorage.removeItem("minutes");
        window.localStorage.removeItem("number");
        setLoader(true);
      })
      .catch((err) => console.log(err));
    setLoader(false);
  };

  const ViewOflFinalResult = () => {
    setLoader(false);
    api_call_token
      .get(`test/result_view/offline_overall/?test_type=${test_type}`)
      .then((res) => {
        console.log(res, "oflViewClick");

        setOflDatas(res.data.data);
      })
      .catch((err) => console.log(err));
    setLoader(false);
  };
  // const newPdf = "http://www.africau.edu/images/default/sample.pdf";

  const onPDFViewClick = () => {
    datas.pdf ? window.open(datas.pdf) : alert("please try after some time");
  };

  console.log(datas?.pdf, "pdffff")
  const handleClick = () => {
    history.push("/testseries");
    // props.history.goBack();
  };

  const handleAskCounselor = () => {
    history.push("/ask-to-counselor");
    // props.history.goBack();
  };

  useEffect(() => {
    ViewFinalResult();
    ViewOflFinalResult();
  }, []);

  // console.log(datas.pdf, "pdf");
  return (
    <div className={styles.Back_position}>
      {viewData === 6 && (
        <div className={styles.finalResults}>
          {!loader ? (
            <Box
              component="div"
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
              container
            >
              <box component="div" style={{ width: "40%" }}>
                <Skeleton height={20} margin={10} variant="text" />
                <Skeleton height={20} margin={10} variant="text" />
                <Skeleton height={50} margin={10} variant="text" />
                <Skeleton height={50} margin={10} variant="text" />
              </box>
              <box component="div" style={{ width: "40%", height: "100%" }}>
                <Skeleton height={20} margin={10} variant="text" />
                <Skeleton height={20} margin={10} variant="text" />
                <Skeleton height={50} margin={10} variant="text" />
                <Skeleton height={50} margin={10} variant="text" />
              </box>
            </Box>
          ) : (
            <>
              <div className={styles.resuts}>Result</div>
              <div className={styles.viewResult_box}>
              
                <div style={{ paddingTop: "45px" }}>
                <div className={styles.detailsfinalTitle}>
                Hi {datas.full_name},<br /> Your result is
                here.
              </div>

                  {/* <div className={`${styles.detailsfinal} ${styles.small_fonts}`}>No. of Attempts: { datas?.no_of_attempts}</div> */}
                  <div
                    className={`${styles.detailsfinal} ${styles.small_fonts}`}
                  >
                    {/* Total Questions: {datas?.total_questions} */}
                    {datas.total_questions &&
                      ` Total Questions:  ${datas.total_questions}`}
                  </div>

                  <div
                    className={`${styles.detailsfinal} ${styles.small_fonts}`}
                  >
                    { `Correct Answers: ${datas?.correct_answers || 0}`}
                  </div>

                  <div
                    className={`${styles.detailsfinal} ${styles.small_fonts}`}
                  >
                    {/* Wrong Answers: {datas?.wrong_answers || datas?.no_of_attempts} */}
                    {
                      ` Wrong Answers: ${datas?.wrong_answers || 0}`}
                  </div>

                  <div
                    className={`${styles.detailsfinal} ${styles.small_fonts}`}
                  >
                    {/* Unattempted Questions: {datas?.unattempted_questions} */}
                    {`Unattempted Questions:  ${datas?.unattempted_questions || 0}`}
                  </div>

                  <div
                    className={`${styles.detailsfinal} ${styles.small_fonts}`}
                  >
                    {/* Average Time Spent per Question: {datas?.avg_time} sec */}
                    {datas.avg_time &&
                      `Average Time Spent Per Question:  ${datas.avg_time} seconds`}
                  </div>

                  <div
                    className={`${styles.detailsfinal} ${styles.small_fonts}`}
                  >
                    {/* Total Time Spent: {datas?.total_time} */}
                    {datas.total_time &&
                      `Total Time Spent: ${datas.total_time} minutes`}
                  </div>
                </div>

                {/* <div>
                  {breakDown &&
                    breakDown.map((data, idx) => (
                      <div key={idx} className={styles.res_break}>
                        {data.category_percent_range === null ? (
                          <></>
                        ) : (
                          <div className={styles.res_break_sub}>
                            <p>{data?.breakdown} </p>
                          </div>
                        )}
                        <div className={styles.result_bar}>
                          {data.category_percent_range === null ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                width: `${data?.percent}%`,
                                backgroundColor: `${data?.category_percent_range?.color}`,
                                height: "13px",
                                borderBottomLeftRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            ></div>
                          )}
                          <div className={styles.show_percent}>
                            {data?.category_percentage_breakdown.map(
                              (val, idx) => (
                                <div
                                  key={idx}
                                  style={{
                                    width: `${val.percentage}%`,
                                    height: "13px",
                                    borderRight: "4px solid white",
                                  }}
                                ></div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div> */}

{
              (interset.length > 0) ? <div>
                {interset &&
                  interset.map((data, idx) => (
                    <div key={idx} className={styles.res_break}>
                      {data.category === "" ? (
                        <></>
                      ) : (
                        <div className={styles.res_break_sub}>
                          <p>{data?.category} </p>
                        </div>
                      )}
                      <div className={styles.result_bar}>
                        {data.value === null ? (
                          <></>
                        ) : (
                          <div
                            style={{
                              width: `${data?.value}%`,
                              backgroundColor: `${data?.colour}`,
                              height: "13px",
                              borderBottomLeftRadius: "12px",
                              borderTopLeftRadius: "12px",
                            }}
                          ></div>
                        )}
                        <div className={styles.show_percent}>
                          {category_percentage_breakdown.map((val, idx) => (
                            <div
                              style={{
                                width: `${val.percentage}%`,
                                height: "13px",
                                borderRight: "4px solid white",
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div> :
                <div>
                  {breakDown &&
                    breakDown.map((data, idx) => (
                      <div key={idx} className={styles.res_break}>
                        {data.category_percent_range === null ? (
                          <></>
                        ) : (
                          <div className={styles.res_break_sub}>
                            <p>{data?.breakdown} </p>
                            {/* <p>
                          {data?.category_percent_range?.title}{" "}
                          {data?.percent + "%"}
                        </p> */}
                          </div>
                        )}
                        <div className={styles.result_bar}>
                          {data.category_percent_range === null ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                width: `${data?.percent}%`,
                                backgroundColor: `${data?.category_percent_range?.color}`,
                                height: "13px",
                                borderBottomLeftRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            ></div>
                          )}
                          <div className={styles.show_percent}>
                            {data?.category_percentage_breakdown.map((val, idx) => (
                              <div
                                style={{
                                  width: `${val.percentage}%`,
                                  height: "13px",
                                  borderRight: "4px solid white",
                                }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
            }
              </div>
              <button
                className={styles.getNextSet}
                onClick={() => handleClick()}
              >
                Get Back to Test
              </button>
              <button
                className={styles.getNextSet}
                onClick={() => onPDFViewClick()}
                disabled={datas.pdf ? false : true}
                style={
                  datas.pdf
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed", backgroundColor: "#92929eb8" }
                }
              >
                Download Report
              </button>
              {/* <button
                className={styles.getNextSet}
                onClick={() => handleAskCounselor()}
              >
                Consult Expert
              </button> */}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default OverAllResult;

const category_percentage_breakdown = [
  {percentage: 30, order: 1, title: "Novice", color: "#FF6FB5"},
  {percentage: 30, order: 2, title: "Intermediate", color: "#FF6FB5"},
  {percentage: 20, order: 3, title: "Advance", color: "#FF6FB5"},
  {percentage: 10, order: 4, title: "Scholar", color: "#FF6FB5"},
  {percentage: 10, order: 5, title: "Ace", color: "#FF6FB5"}
  ]
