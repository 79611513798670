import React from 'react';
import styled from 'styled-components';
import './ChapterAccordianItem.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { IconButton } from '@material-ui/core';
import { ToggleState } from '../../Utils/CustomHooks';
import ConceptHead from '../ConceptHead/ConceptHead';



const ChapterAccordianItem = ({chapter,...props}) => {
    const [expanded, setExpanded, bindExpanded] = ToggleState();
    return <AccordianContainer>
        <AccordianHeading {...bindExpanded} style={{ borderBottom: expanded ? "solid thin #dcdacb" : "" }}>

           
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ChapterName >
                    {chapter.title}
                </ChapterName>
            </div>
            <div {...bindExpanded} style={{ display: 'flex', alignItems: 'center', cursor: "pointer", justifyContent: 'center' }}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>


        </AccordianHeading>
        <section style={{ display: expanded ? "unset" : "none" }}>
            <ChapterContentBox>
                {/* <ConceptHead>
                    <span>"Topic"</span>
                </ConceptHead> */}

                {Array.isArray(chapter.concepts) && 
                <>

                    {chapter.concepts.map(concept =><ConceptHead key={concept.id} concept={concept} />)}

                </>
                }

            </ChapterContentBox>


        </section>
    </AccordianContainer>
}


const AccordianContainer = styled.div`

    border: solid thin #dcdacb;
    border-radius: 2px;
    box-sizing: border-box;
    color:#3c3b37;
    user-select:none;
`

const AccordianHeading = styled.div`
    background-color: #fbfbfb;
    font-size: 19px;
    font-weight: 600;
    height: 55px;
    cursor: pointer;
    display:grid;
    grid-template-columns:  calc(100% - 50px) 50px;
`

const ChapterName = styled.p`
    width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    padding: 0px 20px 0px 10px ;
`


const ChapterContentBox = styled.div`
    padding:10px;
`

export default ChapterAccordianItem;