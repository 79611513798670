import React, { useState, useEffect } from 'react';
import './CourseInfo.css';
import GradeIcon from '@material-ui/icons/Grade';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import LanguageIcon from '@material-ui/icons/Language';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import VideocamIcon from '@material-ui/icons/Videocam';
import download from '../../Assets/download.jpg';
import { Paper, Avatar, MenuItem, TextField, } from '@material-ui/core';
import styled from 'styled-components';
import { MainDataContextApi } from '../../ContextApis/MainDataContextApi/MainDataContextApi';
import { falseChecker } from '../../Utils/Utils';

import Rating from '@material-ui/lab/Rating';
import { handleRateChange } from 'video-react/lib/actions/video';
import { ToggleState } from '../../Utils/CustomHooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import { ReactComponent as IntermediateIcon } from '../../Assets/intermidiate.svg';
import { checkImage } from '../../Utils/Utils.js';
import star from '../../Assets/homepage/star.svg';
import batch from "../../Assets/hOME pAGE/ASSETS/1x/Study CArd/SVG/Asset 31.svg";
import intermidiate from '../../Assets/CourseCard/mid.png';
import Enrolments from '../../Assets/CourseCard/Enrolments.png';
import CourseChapterAccordian from '../../Components/CourseChapterAccordian/CourseChapterAccordian';
import certificate from '../../Assets/CourseCard/certificate.svg';
import profile from '../../Assets/courseDetails/Profile.svg';
import bgImage from '../../Assets/providerProfile/BG.svg';
import BUTTON3 from '../../Assets/homepage/BUTTON3.png';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from 'react-router';
import { monthNumberMapper } from '../../Utils/Utils';
import { timeConvert } from '../../Utils/Utils';

// import download from '..//Assets/download.jpg';

function CourseInfo({ value = {}, handlePrice = () => { }, enroll = () => { }, ...props }) {

    const [showFullDesc, toggleShowFullDesc, bindToggleDesc] = ToggleState(false);
    const [lowestPriceArray, setLowestPriceArray] = useState();
    const [lowestPriceIndex, setLowestPriceIndex] = useState();
    const [selectPrice, setSelectPrice] = useState([]);
    const [loading, setLoading] = useState(true);
    const { course_id } = useParams();
    const [validityId, setValidityId] = useState(value.selected_validity);
    const [currentValidity, setCurrentValidity] = useState(0);
    const [currentValidityPrice, setCurrentValidityPrice] = useState({});
    const { cartItems, context_color } = React.useContext(MainDataContextApi);
    const [activeId, setActiveId] = useState(0);
    const courseAddedToCart = () => {
        let course_present = cartItems.find(item => item.id === value.id);
        console.log("courseAddedToCart ", course_present);
        if (falseChecker(course_present) && course_present !== -1) return true
        return false
    }

    const saveValidityDetails = (e) => {
        setCurrentValidity(e.target.value)

    }


    const changeValidityPrice = (item, _id) => {
        console.log(item, 'item')
        setCurrentValidity(item.id)
        setCurrentValidityPrice(item)
        setActiveId(_id)
    }

    useEffect(() => {
        console.log(value, 'value sandesh')
        if (value.hasOwnProperty('validity') && value.validity !== undefined && value.validity.length !== 0) {
            setCurrentValidityPrice(value.validity[0]);
        }

    }, [value])

    console.log(currentValidityPrice.price, "currentValidity");
    // console.log(setCurrentValidityPrice,"setCurrentValidityPrice");


    useEffect(() => {
        console.log(value, 'value sandesh')
        if (value.validity !== undefined && value.validity.length !== 0) {
            getLeastPrice(value.validity);
            setSelectPrice(value.validity);
        }

    }, [props])

    useEffect(() => {
        if (value.hasOwnProperty('validity') && value.validity.length !== 0) {
            setCurrentValidity(value.validity[0].id)
        }


    }, [value])

    useEffect(() => {
        if (value.hasOwnProperty('title')) {
            setLoading(false);
        }
    }, [value])
    console.log("value", value);

    // useEffect(()=>{
    //     console.log(value.hasOwnProperty('price'),value,'value sandesh inside')

    //     if(value.validity.length === 0 && selectPrice.length === 0 && value.hasOwnProperty('price')){
    //         console.log(value.hasOwnProperty('price'),'value sandesh inside')
    //             const customValidity = [{
    //                 discount_type:null,
    //                 discount_value:null,
    //                 discounted_price:null,
    //                 id:1,
    //                 price:value.price,
    //                 title:value.title
    //             }]
    //             setSelectPrice(customValidity);
    //         }
    // },[props])
    console.log(selectPrice, 'selectPrice')
    function getLeastPrice(lValue) {
        console.log(lValue, 'lvaue')
        if (lValue !== undefined) {
            const lowestPrice = lValue.reduce(
                (lowP, newP) =>
                    lowP.discounted_price < newP.discounted_price
                        ? lowP
                        : newP
            )
            console.log(lowestPrice, 'closest');
            var index = lValue.findIndex(x => x.discounted_price === lowestPrice.discounted_price);
            console.log(index, 'indexindex')
            // setLowestPriceArray(lowestPrice);
            setLowestPriceArray(lValue[0]);

            setLowestPriceIndex(index);
            // return {index, lowestPrice}
        }
    }
    console.log(lowestPriceArray, 'lowestprice array')
    console.log(currentValidityPrice, 'details array heres ');
    console.log(currentValidity, 'currentValidity value');

    console.log(value, "VALUS")
    return (
        <div>
            <div style={{ position: 'relative', overflow: 'hidden' }}>
                {loading ? (<div style={{ background: 'white' }}>
                    <Skeleton variant='rect' />
                </div>) : (<div style={{ position: 'absolute', width: '100%', zIndex: '-1', height: '60vh' }}></div>)}


                <div className="main-banr-crs-info" >


                    <div className="crs-ban-det">
                        <div className="crs-ban-text-c-g">
                            <div>
                                <div className="image-c-t-cd">
                                    {loading ? (<div style={{ background: 'white' }}>
                                        <Skeleton />
                                        <Skeleton animation={false} />
                                        <Skeleton animation="wave" /></div>) : (
                                        value.img ? (<img src={value.image} style={{ width: '90%', margin: 'auto' }} />) :
                                            (
                                                <React.Fragment>
                                                    {value.session !== undefined && value.session !== null && <img src={value.session.icon2} style={{ width: '90%', margin: 'auto' }} />}
                                                </React.Fragment>
                                            )
                                    )}
                                </div>
                            </div>
                            {loading ? (<div style={{ background: 'white' }}>
                                <Skeleton />
                                <Skeleton animation={false} />
                                <Skeleton animation="wave" />
                            </div>) :
                                (<div className="course-details-banner-data">

                                    <h1 style={{ margin: '0px 7px 7px 15px', paddingLeft: '15px', borderRadius: '13px' }} className="title" style={{ background: 'transparent', marginTop: '0px', marginBottom: '0px' }}>{value.title}</h1>
                                    <div style={{ paddingTop: '5px' }}>{value.board.title}  &nbsp;|&nbsp; {value.grade.title}  &nbsp;|&nbsp;{value.subject.map((subject_item, index) => {
                                        return (
                                            <span>{subject_item.title}</span>
                                        )
                                    })}
                                    </div>

                                    <div className="crs-ban-text-c">
                                        <div>
                                            <div className="display-star-icon" style={{ padding: '5px', display: 'flex' }}>
                                                {loading ? (<div style={{ background: 'white' }}>
                                                    <Skeleton />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation="wave" />
                                                </div>) : (<div>
                                                    <img className="course-detail-star" src={batch} style={{ width: '50%', textAlign: 'center' }} />
                                                </div>)}

                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ backgroundColor: 'transparent', borderRadius: '5px' }}>
                                                {loading ? (<div style={{ background: 'white' }}>
                                                    <Skeleton />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation="wave" />
                                                </div>) : (<img className="course-detail-tower" src={intermidiate} style={{ width: '25px', padding: '2px' }} />)}

                                            </div>
                                        </div>
                                        <div>
                                            {value.institute && <div style={{ display: 'inline-flex' }}>
                                                <div style={{ background: 'transparent', padding: '3px', borderRadius: '5px' }}>
                                                    {loading ? (<div style={{ background: 'white' }}>
                                                        <Skeleton />
                                                        <Skeleton animation={false} />
                                                        <Skeleton animation="wave" />
                                                    </div>) : (
                                                        <img className="course-detail-edavatar" src={value.institute.avatar} style={{ width: '30px', height: '30px', borderRadius: '50px' }} />
                                                    )}

                                                </div>
                                                <span style={{ padding: '5px', color: '#022E7A', width: '200px' }}>{value.institute.name}</span>


                                            </div>}
                                        </div>

                                    </div>

                                </div>)}

                        </div>


                        {/* <div className="crs-ban-text-c-ng">
                            <div className="course-detail-enimg" style={{ width: '27px', background: '#d0d0d0', borderRadius: '50px', padding: '7px 3px', textAlign: 'center' }}>
                                {loading ? (<div style={{background:'white'}}>
                                    <Skeleton variant='circle'/>
                                    
                                </div>):(<img src={Enrolments} style={{ width: '70%' }} />)}
                            </div>
                            <div>
                                {loading ? (<div style={{background:'white'}}>
                                    <Skeleton variant='text'/>
                                    
                                </div>):(<span className="course-detail-entxt" style={{ fontSize: '20px', color: 'white' }}>{value.enrollments}{value.enrollments !== 0 && '+'} Enrollments</span>)}
                                
                            </div>
                        </div> */}
                        {/* <div style={{ padding: '6px 0px ' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Rating value={value.course_rating} /> */}
                        {/* <StarHalfIcon style={{ fontSize: "1rem" }} className="icon" /> */}
                        {/* <span className="rating">{value.course_rating}</span>
                        </div> */}

                        {/* <p className="rating1"
                                    style={{ marginLeft: "115px" }} >(100 ratings)</p> */}
                        {/* <div className="rating2" style={{ padding: '5px 0px' }}>{value.enrollments} 100 students Enrolled</div>

                        <div className="lang"> <LanguageIcon className="icon3" /> &nbsp;{value.language && value.language.title}</div>
                    </div> */}

                        {/* {value.institute && <div style={{ display: 'grid', gridTemplateColumns: '60px auto', gridGap: '10px' }}>
                        <div>
                            <img src={value.institute.avatar} style={{ width: '100%', borderRadius: '50px' }} />
                        </div>

                        <div className="inst-name-crs-pg">
                            {value.institute.name}
                        </div>
                    </div>} */}

                    </div>

                    <div className="crs-banr-crd-c">

                    </div>

                </div>
            </div>
            {/* <div>
                <p className="ename">Educator : Educator Name</p>
            </div> */}

            <div className="course-align-info-div" style={{
                display: "grid",
                gridTemplateColumns: "calc(100% - 30%) 30%",
                width: '91%',
                gridGap: '20px',
                margin: 'auto'
            }}>

                <div>
                    <CourseInfoCards style={{ boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px' }}>
                        {loading ? (<div style={{ background: 'white' }}>
                            <Skeleton />
                            <Skeleton animation={false} />
                            <Skeleton animation="wave" />
                        </div>) : (<h2 className="crs-info-head" style={{ marginBottom: '0px', marginTop: '0px' }}>Overview</h2>)}
                        {/* {(value.description !== null && value.description !== undefined)? <React.Fragment>

                            <div style={{ padding: '11px 10px 10px 22px' }} dangerouslySetInnerHTML={{ __html: value.description }}></div>


                        </React.Fragment> : <p>Course overview is not provided by the course creator.</p>} */}

                        <section className="crs-info-det">
                                    {loading ? (<div style={{ background: 'white' }}>
                                        <Skeleton />
                                        <Skeleton animation={false} />
                                        <Skeleton animation="wave" />
                                    </div>) : ((value.description !== null && value.description)? (<p style={{ color: '#333333' }} dangerouslySetInnerHTML={{ __html: value.description }}  >
                                    </p>): (<p>Course overview is not provided by the course creator.</p>))}

                                </section>
                    </CourseInfoCards>

                    
                        <CourseInfoCards style={{ boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px' }}>
                            <React.Fragment>
                                {loading ? (<div style={{ background: 'white' }}>
                                    <Skeleton />
                                    <Skeleton animation={false} />
                                    <Skeleton animation="wave" />
                                </div>) : (<h2 className="crs-info-head">Pre-Requisites</h2>)}

                                {/* <section className="crs-info-det crd-prereq">
                            {value.prerequisites}
                        </section> */}
                                <section className="crs-info-det">
                                    {loading ? (<div style={{ background: 'white' }}>
                                        <Skeleton />
                                        <Skeleton animation={false} />
                                        <Skeleton animation="wave" />
                                    </div>) : ((value.prerequisites !== null && value.prerequisites)? (<p style={{ color: '#333333' }} dangerouslySetInnerHTML={{ __html: value.prerequisites }}  >
                                    </p>): (<p>No Pre-requisite required for this course.</p>))}

                                </section>
                            </React.Fragment>
                        </CourseInfoCards>

                    {props.chapterList !== undefined && props.chapterList.length !== 0 &&
                        <CourseInfoCards style={{ boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px' }}>
                            <div style={{}}>
                                {/* <p className="wwyl-head"> What will you Learn. </p> */}
                                {loading ? (<div style={{ background: 'white' }}>
                                    <Skeleton variant />
                                    <Skeleton animation={false} />
                                    <Skeleton animation="wave" />
                                </div>) : (<h2 className="crs-info-head">Curriculum</h2>)}

                                <div style={{ padding: '0px 20px', paddingBottom: '20px' }}>
                                    {loading ? (<div style={{ background: 'white' }}>
                                        <Skeleton variant />
                                        <Skeleton animation={false} />
                                        <Skeleton animation="wave" />
                                    </div>) : (<CourseChapterAccordian chapterList={props.chapterList} />)}

                                </div>
                            </div>
                        </CourseInfoCards>
                    }

                </div>

                <div style={{ height: '100%' }}>


                    <div style={{ position: 'sticky', top: '75px', zIndex: '3', borderRadius: '5px', marginBottom: '10px' }}>
                        {loading ? (<div style={{ background: 'white' }}>
                            <Skeleton variant='rect' width={210} height={118} />
                        </div>) : (<CourseInfoCards style={{ boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px' }}>
                            <div className="crs-price-crd">

                                <div >
                                    {currentValidityPrice.final_price !== undefined &&
                                        currentValidityPrice.final_price !== null ? (
                                        <div style={{ padding: '5px 25px' }}>
                                            <p className="new-discount-price">₹
                                                {currentValidityPrice.final_price} 
                                            </p>
                                            <p style={{margin: '0px 0px'}}>
                                                <span className="discount new-discount-v">{currentValidityPrice.discount_value == 0 ? (' '): (currentValidityPrice.discount_value)} {currentValidityPrice.discount_type == 1 ? ('% ') : (' ')}</span>
                                                <span>
                                                    <s><b>₹{currentValidityPrice.price} </b></s></span>
                                            </p>


                                        </div>) : (<div style={{ padding: '5px 25px' }} ><p className="new-discount-price">₹
                                            {currentValidityPrice.final_price === null || currentValidityPrice.final_price === undefined ? (currentValidityPrice.price) : (lowestPriceArray.discounted_price)} 
                                        </p></div>)
                                    }

                                    <CourseInfoCards style={{ boxShadow: 'none', borderRadius: '13px' }}>
                                        {loading ? (<div style={{ background: 'white' }}>
                                            <Skeleton />
                                            <Skeleton animation={false} />
                                            <Skeleton animation="wave" />
                                        </div>) : (<h2 className="crs-info-head" style={{ marginBottom: '0px', marginTop: '0px', fontSize: '18px' }}>Please Select Variant</h2>)}
                                        {value.session !== null && value.session !== undefined && <React.Fragment>
                                            {value.session.type !== undefined &&
                                                value.session.type == 1 &&
                                                <div>
                                                    {value.validity.length && value.validity.map((item, index) => {

                                                        if (item.start !== null && item.start !== null) {
                                                            var startDate = item.start
                                                            const myArr = startDate.split("-");
                                                            var number = myArr[1];
                                                            var monthstring = monthNumberMapper(number - 1, false);

                                                            var endDate = item.end
                                                            const myArr1 = endDate.split("-");
                                                            var number1 = myArr1[1];
                                                            var monthstring1 = monthNumberMapper(number1 - 1, false);

                                                            var start_time = timeConvert(item.start_time);
                                                            var end_time = timeConvert(item.end_time);
                                                        }

                                                        return (
                                                            <div className={`internal-card-validity ${activeId === index ? 'active-internal-card' : ''}`} onClick={() => changeValidityPrice(item, index)}>
                                                                <div className="validity-head">{item.title}</div>
                                                                {/* {item.start !== null && item.end !== null && <div className="validity-date">{myArr[2]} {monthstring}, {myArr[0]}  - {myArr1[2]} {monthstring1}, {myArr1[0]}</div>} */}
                                                                <div className="validity-date">{start_time} - {end_time}</div>
                                                                <div style={{display:"flex"}}>
                                                                    {[
                                                                        "sunday",
                                                                        "monday",
                                                                        "tuesday",
                                                                        "wednesday",
                                                                        "thursday",
                                                                        "friday",
                                                                        "saturday",
                                                                    ].map((d) => <div
                                                                        key={d}
                                                                        style={{
                                                                            backgroundColor: item[d]
                                                                                ? item.colour
                                                                                : "#ccc",
                                                                        }}
                                                                        className="dayCircle"
                                                                    >
                                                                        {d}
                                                                        {/* {d?.slice(0, 1)} */}
                                                                    </div>)}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {value.session.type !== undefined &&
                                                value.session.type == 2 &&
                                                <div>
                                                    {value.validity.length && value.validity.map((item, index) => {
                                                        var startDate = item.start
                                                        const myArr = startDate.split("-");
                                                        var number = myArr.length > 1 && myArr[1];
                                                        var monthstring = monthNumberMapper(number - 1, false);

                                                        var endDate = item.end
                                                        const myArr1 = endDate.split("-");
                                                        var number1 = myArr.length > 1 && myArr1[1];
                                                        var monthstring1 = monthNumberMapper(number1 - 1, false);

                                                        var start_time = timeConvert(item.start_time);
                                                        var end_time = timeConvert(item.end_time);
                                                        
                                                        return (

                                                            <div className={`internal-card-validity ${activeId === index ? 'active-internal-card' : ''}`} onClick={() => changeValidityPrice(item, index)}>
                                                                <div className="validity-head">{item.title}</div>
                                                                {myArr.length > 2 && <div className="validity-date">{myArr[2]} {monthstring}, {myArr[0]}  - {myArr1[2]} {monthstring1}, {myArr1[0]}</div>}
                                                                <div className="validity-date">{start_time} - {end_time}</div>
                                                                <div style={{display:"flex"}}>
                                                                    {[
                                                                        "sunday",
                                                                        "monday",
                                                                        "tuesday",
                                                                        "wednesday",
                                                                        "thursday",
                                                                        "friday",
                                                                        "saturday",
                                                                    ].map((d) => <div
                                                                        key={d}
                                                                        style={{
                                                                            backgroundColor: item[d]
                                                                                ? item.colour
                                                                                : "#ccc",
                                                                        }}
                                                                        className="dayCircle"
                                                                    >
                                                                        {d?.slice(0, 1)}
                                                                    </div>)}</div>
                                                                {/* <div className="validity-addresss">{value.address}</div> */}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {value.session.type !== undefined &&
                                                value.session.type == 3 &&
                                                <div>
                                                    {value.validity.length && value.validity.map((item, index) => {

                                                        return (

                                                            <div className={`internal-card-validity ${activeId === index ? 'active-internal-card' : ''}`} onClick={() => changeValidityPrice(item, index)}>
                                                                <div className="validity-head">{item.title}</div>
                                                                <div className="validity-date">{item.validity_period.title}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {value.session !== undefined ? (<section className="crs-info-det">
                                                {loading ? (<div style={{ background: 'white' }}>
                                                    <Skeleton />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation="wave" />
                                                </div>) : (<p style={{ color: '#333333' }} dangerouslySetInnerHTML={{ __html: value.description }}  >
                                                </p>)}

                                            </section>) : (<section className="crs-info-det">
                                                <p style={{ color: context_color.secondary }}>
                                                    no data to display
                                                </p>
                                            </section>)}</React.Fragment>}
                                        {value.session === undefined || value.session === null && <section className="crs-info-det">
                                            <p style={{ color: context_color.secondary }}>
                                                no data to display
                                            </p>
                                        </section>}
                                    </CourseInfoCards>

                                    {falseChecker(value.id) &&
                                        <div style={{ textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    // width: '90%',
                                                    marginBottom: '20px',
                                                    borderRadius: 4,
                                                    backgroundColor: "#133268",
                                                    fontWeight: '550',
                                                    backgroundImage: `url(${BUTTON3})`, backgroundSize: '100% 100%',
                                                    // minHeight: '60px',
                                                    fontSize: '18px',
                                                    // height: '60px',
                                                    color: 'white'
                                                }}
                                                // disabled={courseAddedToCart() || value.is_purchased}
                                                onClick={() => enroll(currentValidity)}>
                                                {/* {courseAddedToCart() ? "Added To Cart" : "Enroll"} */}
                                                Enroll
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </CourseInfoCards>)}
                    </div>

                    {/* {value.is_certified && <div style={{ position: 'sticky', top: '284px', marginBottom: '10px' }}>
                    {loading ? (<div style={{background:'white'}}>
                        <Skeleton variant='rect' width={232.06} height={321.4}/>
                    </div>) : (<CourseInfoCards> */}

                    {/* <h2 className="crs-info-head" style={{ padding: '15px', marginBottom: '5px' }}>Certificate</h2>
                                <div className="certi-info-det">
                                    <p className="time2" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                                    <img src={certificate} style={{ width: '100%' }} />
                                </div> */}

                    {/* </CourseInfoCards>)} */}
                    {/* </div>} */}

                    {value.instructor !== undefined && value.instructor.length !== 0 && value.instructor.map((item, id) =>
                        <CourseInfoCards style={{ boxShadow: '1px 2px 4px 0px rgb(0 0 0 / 30%)', borderRadius: '13px' }}>

                            <h2 className="crs-info-head" style={{ padding: '15px', marginBottom: '5px' }}></h2>
                            <div className="certi-info-det-i">
                                <img src={value.instructor.avatar} style={{ width: '100%' }} />
                                <p>{value.instructor.first_name} {value.instructor.last_name}</p>
                                <div className="display-star-icon" style={{ backgroundColor: 'white', padding: '5px', display: 'flex', textAlign: 'center', boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)' }}>
                                    <img src={star} style={{ width: '20px' }} />&nbsp;<p style={{ fontSize: '16px', margin: '3px 0px' }}>{value.rating}</p>
                                </div>
                            </div>

                        </CourseInfoCards>
                    )}

                </div>
            </div>

            {/* <section className="desc-sectin">
                <h2 style={{ color: '#424242' }}>Description</h2>
                <div className="titledesc" style={{ height: showFullDesc ? "auto" : "150px", overflow: showFullDesc ? "visible" : "hidden", }}>
                    <div dangerouslySetInnerHTML={{ __html: value.description }}  >
                    </div>
                    {!showFullDesc && <div className="desc-gradiant">
                    </div>}
                </div>

                <div style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} {...bindToggleDesc}>
                    Show {showFullDesc ? "Less" : "More"} &nbsp; {showFullDesc ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
            </section> */}
        </div >

    );
}



const CourseInfoGrid = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 31px;
    padding: 40px;

    @media  (max-width: 768px) {
        grid-template-columns: 1fr;

      }
`

const CourseInfoCards = styled(Paper)`

    min-height:200px;
    margin:10px;
    @media  (max-width: 768px) {
      }
`


export default CourseInfo;