import React from 'react';
import './CenterContainer.css';


const CenterContainer = ({ direction = "column",justify="center",align="center", children, style, className, ...props }) =>
    (
        <div {...props} className={`ccn-center-d16 ${className ? className : ""}`} style={style !== null && typeof (style) === "object" ? {flexDirection:direction,justifyContent:justify,alignItems:align, ...style } : {flexDirection:direction,justifyContent:justify,alignItems:align}} >
             {children}
        </div>
    )



    export default CenterContainer;