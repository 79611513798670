import axios from "axios";
// export const base_url = 'http://165.22.214.137:8006';

// export const base_url = "http://dev-api.lcarro.com";
// export const base_url_slash = "http://dev-api.lcarro.com";

// Production
// export const base_url = "https://prod-api.lcarro.com/";
// export const base_url_slash = "https://prod-api.lcarro.com/";

//staging
// export const base_url_slash = "https://demo-api.lcarro.com";
// export const base_url = "https://demo-api.lcarro.com";

// changing base_url from window location

export let base_url_slash;
export let base_url;

var url = window.location.href;

// if (url.includes("localhost") || url.includes("staging")) {
//   base_url_slash = "https://demo-api.lcarro.com";
//   base_url = "https://demo-api.lcarro.com";
// } else {
//   // working build prod 22-09-22
//   base_url_slash = "https://prod-api.lcarro.com";
//   base_url = "https://prod-api.lcarro.com";
// }

  base_url_slash = "https://prod-api.brainhap.com/";
  base_url = "https://prod-api.brainhap.com/";

  console.log("base_url_slash", base_url_slash, base_url);
// https://prod-api.lcarro.com/online/homescreen/
// export const base_url = 'production';

var token_a = localStorage.getItem("network_access_token");
console.log(token_a,"token_a");
var _headers =
  token_a === null || token_a === undefined
    ? {
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token_a}`,
      };

const fetchClient = () => {
  let instance = axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: _headers,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("network_access_token");
    config.headers.Authorization = localStorage.getItem("network_access_token")
      ? `Bearer ${localStorage.getItem("network_access_token")}`
      : "";
    return config;
  });
  return instance;
};

export const api_call_token = fetchClient();

export const api_call = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});
export var login_api = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: _headers,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});
export var api_token = axios.create({
  baseURL: base_url,
  timeout: 300000,
  _headers,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});
export function setLcarroApi(access_token) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem("lcarro_access_token", access_token);
      api_token = axios.create({
        baseURL: base_url,
        timeout: 300000000,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        transformRequest: [
          function (data, headers) {
            return JSON.stringify(data);
          },
        ],
      });
      resolve("Successfully set Lcarro_api.");
    } catch {
      reject("Error to ser lcarro_api");
    }
  });
}
// api call without token
export var api_without_token = axios.create({
  baseURL: base_url,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});
export function setApiToken(acces_token) {
  token_a = acces_token;
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem("network_access_token", acces_token);
      api_token = axios.create({
        baseURL: base_url,
        timeout: 30000,
        headers: {
          Authorization: `Bearer ${acces_token}`,
          "Content-Type": "application/json",
        },
        transformRequest: [
          function (data, headers) {
            return JSON.stringify(data);
          },
        ],
      });
      resolve("Successfully set api_call.");
    } catch {
      reject("Error to ser api_call");
    }
  });
}


export async function formDataApi(
  apiName = "",
  formData,
  patch = false,
  apiUrl = "container"
) {
  let data = {};
  // const token = JSON.parse(localStorage.getItem("network_access_token"));
  if (patch)
    await axios
      .patch(base_url + apiUrl + apiName, formData, {
        // headers: { "content-type": "multipart/form-data" },
        headers: {
          Authorization: token_a ? `Bearer ${token_a}` : "",
          "content-type": "multipart/form-data",
        },
        validateStatus: (status) => status < 500,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return (data = response.data);
        return (data = response.data);
      })
      .catch((e) => console.log(e));
  else
    await axios
      .post(base_url + apiUrl + apiName, formData, {
        // headers: { "content-type": "multipart/form-data" },
        headers: {
          Authorization: token_a ? `Bearer ${token_a}` : "",
          "content-type": "multipart/form-data",
        },
        validateStatus: (status) => status < 500,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return (data = response);
        return (data = response);
      })
      .catch((e) => console.log(e));
  return data;
}